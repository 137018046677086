import React from 'react';
import styled from 'styled-components/macro';
import { white } from '../styling/colours';
import { loadingIndicationDelay, regularTransitionDuration } from '../styling/transitions';
import { CentredLoadingSpinner, loadingSpinnerAnimation } from './LoadingSpinner';

type Props = {
  loading: boolean;
  children: React.ReactNode;
  className?: string;
};

export const LoadingOverlay = (props: Props) => (
  <LoadingOverlayContainer className={props.className} $loading={props.loading}>
    <OverlaidLoadingSpinner loading={props.loading} />
    {props.children}
  </LoadingOverlayContainer>
);

type LoadingOverlayContainerProps = {
  $loading: boolean; // renamed to prevent name clash with native div attribute 'loading': https://styled-components.com/docs/api#transient-props
  className?: string;
};

const LoadingOverlayContainer = styled.div<LoadingOverlayContainerProps>`
  position: relative;

  :after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: ${props => (props.$loading ? 'auto' : 'none')};
    cursor: ${props => (props.$loading ? 'progress' : 'auto')};
    background-color: ${white};

    opacity: ${props => (props.$loading ? 0.6 : 0)};
    transition: opacity ${regularTransitionDuration} ease;
    transition-delay: ${loadingIndicationDelay};
  }
`;

// eslint-disable-next-line no-unexpected-multiline
const OverlaidLoadingSpinner = styled(({ loading, ...props }) => (
  <CentredLoadingSpinner {...props} />
))<{
  loading: boolean;
}>`
  animation: ${props => (props.loading ? loadingSpinnerAnimation : 'none')};

  // Both visibility and opacity needed to prevent abrupt reset of animation
  // just before the spinner disappears.
  visibility: ${props => (props.loading ? 'visible' : 'hidden')};
  opacity: ${props => (props.loading ? 1 : 0)};
  transition: opacity ${regularTransitionDuration} ease;
  transition-delay: ${loadingIndicationDelay};
`;
