import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useProjectsApi } from '../../hooks/api/useProjectsApi';
import { Project } from './project';

type ProjectsContextValue = {
  projects: Array<Project>;
  activeProjects: Array<Project>;
  refreshProjects: () => Promise<void>;
};

export const ProjectsContext = React.createContext<ProjectsContextValue>({
  projects: [],
  activeProjects: [],
  refreshProjects: () => {
    throw new Error('Cannot refresh projects outside of its context provider');
  },
});

export const ProjectsContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [projects, setProjects] = useState<Array<Project>>([]);
  const [activeProjects, setActiveProjects] = useState<Array<Project>>([]);

  const { getProjects } = useProjectsApi();

  useEffect(() => {
    refreshProjects();
  }, []);

  useEffect(() => {
    setActiveProjects(projects.filter((project) => project.isActive));
  }, [projects]);

  const refreshProjects = async () => {
    setProjects(await getProjects());
  };

  return (
    <ProjectsContext.Provider value={{ projects, activeProjects, refreshProjects }}>
      {children}
    </ProjectsContext.Provider>
  );
};
