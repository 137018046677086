import { Form } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { DateField } from '../../shared/form/input-fields/DateField';
import { InputField } from '../../shared/form/input-fields/InputField';
import { TextAreaField } from '../../shared/form/input-fields/TextAreaField';
import { MultiSelectField } from '../../shared/form/select-fields/MultiSelectField';
import { SelectField } from '../../shared/form/select-fields/SelectField';
import { StyledToggleField } from '../../shared/form/ToggleField';
import { ModalActionButtonsSection } from '../../shared/Modal';
import { lightGrey } from '../../styling/colours';
import { medium, wide } from '../../styling/spacing';
import {
  mapNumberToWeekdaySelectOption,
  WeekdayOption,
  weekdayOptions,
} from '../../utils/dateStamp';
import {
  mapAssigneesToSelectOptions,
  mapUsersToAssigneeSelectOptions,
} from '../goal-assignments/assigneeSelectUtils';
import { Assignee } from '../goal-assignments/goalAssignment';
import {
  GoalState,
  mapGoalStatesToSelectOptions,
  mapGoalStateToSelectOption,
} from '../goal-states/goalState';
import { Project } from '../projects/project';
import {
  mapProjectsToSelectOptions,
  mapProjectToSelectOption,
  projectSelectStyles,
} from '../projects/projectSelectUtils';
import { User } from '../users/user';
import { userSelectStyles } from '../users/userSelectUtils';

type Props = {
  recurringGoalId?: number;
  runsOnLastWeekdayOfMonth: boolean;
  runOnWeekends: boolean;
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  children?: React.ReactNode;
};

export const CreateUpdateRecurringGoalForm = ({
  recurringGoalId,
  runsOnLastWeekdayOfMonth,
  runOnWeekends,
  activeUsers,
  activeProjects,
  goalStates,
  children,
}: Props) => {
  const assigneeOptions = useMemo(
    () => mapUsersToAssigneeSelectOptions(activeUsers, recurringGoalId || null),
    [activeUsers, recurringGoalId],
  );

  const projectOptions = useMemo(() => mapProjectsToSelectOptions(activeProjects), [
    activeProjects,
  ]);

  const goalStateOptions = useMemo(() => mapGoalStatesToSelectOptions(goalStates), [goalStates]);

  return (
    <Form>
      <InputSection>
        <LeftGroup>
          <TextAreaField
            name="description"
            label="Description"
            placeholder="Add a description..."
            fontWeight="bold"
            maxLength={128}
          />
          <SelectField<Project>
            name="project"
            label="Project"
            placeholder="Select a project..."
            options={projectOptions}
            mapValueToSelectOption={mapProjectToSelectOption}
            mapOptionToKey={option => option.value.projectId.toString()}
            styles={projectSelectStyles}
          />
          <MultiSelectField<Assignee>
            name="assignees"
            label="Assignees"
            optional={true}
            placeholder="Select assignees..."
            options={assigneeOptions}
            mapValueToSelectOptions={mapAssigneesToSelectOptions}
            mapOptionToKey={option => option.value.userId.toString()}
            styles={userSelectStyles}
          />
          <TextAreaField
            name="notes"
            label="Notes"
            placeholder="Add some notes..."
            optional={true}
            maxLength={1024}
          />
          <InputField
            name="goalGroupName"
            label="Group"
            placeholder="Add a group name..."
            optional={true}
            maxLength={32}
          />
          <SelectField<GoalState>
            name="goalState"
            label="State"
            placeholder="Select a state..."
            options={goalStateOptions}
            mapValueToSelectOption={mapGoalStateToSelectOption}
            mapOptionToKey={option => option.value.stateCode}
          />
        </LeftGroup>
        <RightGroup>
          <DateField name="startDate" label="Start Date" optional={false} />
          <StyledToggleField
            name="runsOnLastWeekdayOfMonth"
            label="Run on last weekday of every month?"
          />
          <StyledToggleField name="runOnWeekends" label="Run on weekends?" />
          <Separator />
          <InputField
            name="daysFrequency"
            label="Frequency - Days"
            optional={false}
            maxLength={3}
            type={'number'}
            disabled={runsOnLastWeekdayOfMonth}
          />
          <InputField
            name="monthsFrequency"
            label="Frequency - Months"
            optional={false}
            maxLength={3}
            type={'number'}
            disabled={runsOnLastWeekdayOfMonth}
          />
          <Separator />
          <SelectField<WeekdayOption>
            name="dayOfWeek"
            label="Day Of Week to wait until"
            optional={true}
            placeholder="Select day..."
            options={weekdayOptions}
            mapValueToSelectOption={mapNumberToWeekdaySelectOption}
            mapOptionToKey={option => option.label}
          />
          <Separator />
          <DateField name="nextOccurrence" label="Override Next Occurrence" optional={true} />
        </RightGroup>
      </InputSection>
      {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
    </Form>
  );
};

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftGroup = styled.div`
  width: 450px;
  margin-right: ${wide};

  display: flex;
  flex-direction: column;
`;

const RightGroup = styled.div`
  width: 240px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${lightGrey};
  width: 100%;
  margin-bottom: ${medium};
`;
