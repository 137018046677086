import React from 'react';
import styled from 'styled-components/macro';
import logoUrl from '../images/tapas-logo.svg';
import { Link } from '../shared/Link';
import { Modal } from '../shared/Modal';
import { ShortcutList } from '../shared/ShortcutList';
import { helpUrl } from '../urls';

type HelpModalProps = {
  hideHelp: () => void;
};

export const HelpModal = ({ hideHelp }: HelpModalProps) => {
  const closeModalIfOnHelpPage = () => {
    if (window.location.pathname === helpUrl()) {
      hideHelp();
    }
  };

  return (
    <Modal isOpen={true} onClose={hideHelp} hideCloseButton={false}>
      <Container>
        <Logo src={logoUrl} />
        <h2>Keyboard shortcuts</h2>
        <ShortcutList />
        <p>
          The tickets also support Markdown, check out the full{' '}
          <Link to={helpUrl()} onClick={closeModalIfOnHelpPage}>
            cheat sheet
          </Link>
        </p>
      </Container>
    </Modal>
  );
};

const Container = styled.div`
  padding: 0 20px;
  max-height: 80vh;
  width: 400px;
  overflow-y: auto;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #bbb;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #999;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #666;
  }
`;

const Logo = styled.img`
  height: 180px;
  padding: 36px;
  width: 100%;
`;
