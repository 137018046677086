import { Validator } from 'fluentvalidation-ts';
import { colourHexRegEx, getContrastedColour } from '../../../styling/colours';
import {
  ProjectFramework,
  toCreateProjectFrameworkDto,
} from '../../project-frameworks/projectFrameworks';
import {
  StatusEmailReceiver,
  toCreateProjectStatusEmailReceiverDto,
} from '../../project-status-email-receivers/projectStatusEmailReceiver';
import { ProjectTeam, toCreateProjectTeamDto } from '../../project-teams/projectTeam';
import { CreateProjectDto } from '../project';

export type CreateProjectFormModel = {
  name: string;
  colour: string;
  externalTicketUrlTemplate: string;
  jiraKeys: string;
  isActive: boolean;
  teamMailingListEmail: string;
  additionalStatusEmailReceivers: Array<StatusEmailReceiver>;
  spreadsheetRetainerGuid: string;
  teams: Array<ProjectTeam>;
  frameworks: Array<ProjectFramework>;
};

export class CreateProjectFormValidator extends Validator<CreateProjectFormModel> {
  constructor() {
    super();

    this.ruleFor('name').notEmpty().maxLength(32);

    this.ruleFor('colour')
      .notEmpty()
      .matches(colourHexRegEx(true))
      .withMessage('Must be a 3 or 6 digit hex value');

    this.ruleFor('externalTicketUrlTemplate').maxLength(256);

    this.ruleFor('jiraKeys').maxLength(32);

    this.ruleFor('teamMailingListEmail').maxLength(128);

    this.ruleFor('spreadsheetRetainerGuid').maxLength(128);

    this.ruleFor('teams')
      .must((projectTeams) => projectTeams.length > 0)
      .withMessage('Project must be assigned to at least one team');
  }
}

export const toCreateProjectDto = (formModel: CreateProjectFormModel): CreateProjectDto => ({
  name: formModel.name,
  colour: formModel.colour.toUpperCase(),
  contrastColour: getContrastedColour(formModel.colour),
  externalTicketUrlTemplate: formModel.externalTicketUrlTemplate,
  jiraKeys: formModel.jiraKeys,
  isActive: formModel.isActive,
  teamMailingListEmail: formModel.teamMailingListEmail || null,
  additionalStatusEmailReceivers: toCreateProjectStatusEmailReceiverDto(
    formModel.additionalStatusEmailReceivers,
  ),
  spreadsheetRetainerGuid: formModel.spreadsheetRetainerGuid,
  teams: toCreateProjectTeamDto(formModel.teams),
  frameworks: toCreateProjectFrameworkDto(formModel.frameworks),
});
