import { flowRight } from 'lodash';
import React from 'react';
import { ReactComponent as Plus } from '../../../images/icons/plus-solid.svg';
import { withModal, WithModalProps } from '../../../shared/higher-order-components/withModal';
import { lightPurple, translucentWhite } from '../../../styling/colours';
import { CreateTeamModal } from '../../teams/create-team/CreateTeamModal';
import { CreateTeamDto, Team, UpdateTeamDto } from '../../teams/team';
import { UpdateTeamModal } from '../../teams/update-team/UpdateTeamModal';
import { KanbanViewUrlParams } from '../kanban';
import { OptionsColumn, OptionsColumnHeader, OptionsColumnHeaderButton } from './KanbanSettings';
import { NoViewOption } from './NoViewOption';

type CreateTeamModalParams = {
  onSubmit: (createTeamDto: CreateTeamDto) => void;
};

type CreateTeamModalProps = WithModalProps<CreateTeamModalParams, 'createTeamModal'>;

type UpdateTeamModalParams = {
  team: Team;
  onSubmit: (updateTeamDto: UpdateTeamDto) => void;
};

type UpdateTeamModalProps = WithModalProps<UpdateTeamModalParams, 'updateTeamModal'>;

type OwnProps = {
  urlParams?: KanbanViewUrlParams;
  teams: Array<Team>;
  hideSettings: () => void;
  onCreateTeam: (createTeamDto: CreateTeamDto) => void;
  onUpdateTeam: (updateTeamDto: UpdateTeamDto) => void;
};

type TeamOptionsProps = CreateTeamModalProps & UpdateTeamModalProps & OwnProps;

const TeamNoViewOptionsComponent = ({
  createTeamModal,
  updateTeamModal,
  teams,
  onCreateTeam,
  onUpdateTeam,
}: TeamOptionsProps) => (
  <OptionsColumn>
    <OptionsColumnHeader>
      Teams
      <OptionsColumnHeaderButton
        onClick={() => createTeamModal.open({ onSubmit: onCreateTeam })}
        colour={lightPurple}
        hoverColour={translucentWhite}
      >
        <Plus />
      </OptionsColumnHeaderButton>
    </OptionsColumnHeader>
    {teams.map(team => (
      <NoViewOption
        key={team.teamId}
        text={team.name}
        disabled={!team.isActive}
        onEditClick={() =>
          updateTeamModal.open({
            team,
            onSubmit: onUpdateTeam,
          })
        }
      />
    ))}
  </OptionsColumn>
);

const withCreateTeamModal = withModal<OwnProps, CreateTeamModalParams, 'createTeamModal'>(
  'createTeamModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <CreateTeamModal
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const withUpdateTeamModal = withModal<
  OwnProps & CreateTeamModalProps,
  UpdateTeamModalParams,
  'updateTeamModal'
>(
  'updateTeamModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <UpdateTeamModal
      team={params.team}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const enhance = flowRight(
  withCreateTeamModal,
  withUpdateTeamModal,
);

export const TeamNoViewOptions = enhance(TeamNoViewOptionsComponent);
