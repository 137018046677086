import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { User } from '../users/user';

export type GoalStateCode = 'ACTIVE' | 'BACKLOG' | 'BLOCKED' | 'COMPLETE' | 'NEW' | 'STRETCH';

export type GoalState = {
  stateCode: GoalStateCode;
  name: string;
  ordinal: number;
  isComplete: boolean;
};

export const mapGoalStatesToSelectOptionsWithNull = (
  goalStates: Array<GoalState>,
  nullLabel: string = 'All',
): Array<SelectOption<GoalState | null>> => {
  const nullOption: SelectOption<GoalState | null> = { value: null, label: nullLabel };
  const goalStateOptions = mapGoalStatesToSelectOptions(goalStates);

  return [nullOption, ...goalStateOptions];
};

export const mapGoalStatesToSelectOptions = (
  goalStates: Array<GoalState>,
): Array<SelectOption<GoalState>> => goalStates.map(mapGoalStateToSelectOption);

export const mapGoalStateToSelectOption = (goalState: GoalState): SelectOption<GoalState> => ({
  value: goalState,
  label: goalState.name,
});
