import React from 'react';
import styled from 'styled-components/macro';
import { InlineToggle } from '../../../shared/form/ToggleField';
import { translucentWhite, white } from '../../../styling/colours';
import { narrow, navMenuHeight, navMenuHeightPixels, wider } from '../../../styling/spacing';

type Props = {
  onShowInactiveChanged: (showInactive: boolean) => void;
  showInactive: boolean;
};

export const ShowInactive = ({ onShowInactiveChanged, showInactive }: Props) => {
  return (
    <Container>
      <Spacer />
      <ShowInactiveToggle
        htmlFor="showInactive"
        label="Show inactive?"
        labelColour={white}
        onChange={onShowInactiveChanged}
        checked={showInactive}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  position: relative;
  left: ${wider};
  align-items: center;
  height: ${navMenuHeight};
`;

const Spacer = styled.div`
  height: ${navMenuHeightPixels / 2}px;
  width: 2px;
  margin: 0 ${narrow}
  background-color: ${translucentWhite};
`;

const ShowInactiveToggle = styled(InlineToggle)`
  margin-left: ${narrow};
`;
