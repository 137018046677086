import styled from 'styled-components/macro';
import {
  navMenuHeightPixels,
  pageHorizontalPadding,
  pageVerticalPadding,
  pageVerticalPaddingPixels,
} from '../styling/spacing';

export const PageContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: ${navMenuHeightPixels + pageVerticalPaddingPixels}px ${pageHorizontalPadding}
    ${pageVerticalPadding} ${pageHorizontalPadding};
  width: 100%;
  min-height: 100%;
`;
