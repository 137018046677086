import { useCallback } from 'react';
import {
  CreateRecurringGoalDto,
  RecurringGoal,
  RecurringGoalData,
  UpdateRecurringGoalDto,
} from '../../features/recurring-goals/recurringGoal';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useRecurringGoalsApi = () => {
  const fetchRecurringGoalDataAuthorisedFetch = useAuthorisedFetch<RecurringGoalData>('get');
  const fetchRecurringGoalData = useCallback(
    () => fetchRecurringGoalDataAuthorisedFetch('recurring-goals'),
    [fetchRecurringGoalDataAuthorisedFetch],
  );

  const createRecurringGoalAuthorisedFetch = useAuthorisedFetch<
    RecurringGoal,
    CreateRecurringGoalDto
  >('post');
  const createRecurringGoal = useCallback(
    (recurringGoal) => createRecurringGoalAuthorisedFetch('recurring-goals', recurringGoal),
    [createRecurringGoalAuthorisedFetch],
  );

  const updateRecurringGoalAuthorisedFetch = useAuthorisedFetch<
    RecurringGoal,
    UpdateRecurringGoalDto
  >('put');
  const updateRecurringGoal = useCallback(
    (recurringGoal) => updateRecurringGoalAuthorisedFetch('recurring-goals', recurringGoal),
    [updateRecurringGoalAuthorisedFetch],
  );

  const deleteRecurringGoalAuthorisedFetch = useAuthorisedFetch<null>('delete');
  const deleteRecurringGoal = useCallback(
    (recurringGoalId) => deleteRecurringGoalAuthorisedFetch(`recurring-goals/${recurringGoalId}`),
    [deleteRecurringGoalAuthorisedFetch],
  );

  return { fetchRecurringGoalData, createRecurringGoal, updateRecurringGoal, deleteRecurringGoal };
};
