import styled from 'styled-components/macro';
import {
  alertAccent,
  alertText,
  lightGrey,
  successAccent,
  successText,
  warningAccent,
  warningText,
  white,
} from '../styling/colours';
import { defaultBorderRadius, wide } from '../styling/spacing';

export const Callout = styled.div`
  background-color: ${white};
  border: solid 1px ${lightGrey};
  border-radius: ${defaultBorderRadius};
  padding: ${wide};
`;

export const SuccessCallout = styled(Callout)`
  color: ${successText};
  background-color: ${successAccent};
`;

export const WarningCallout = styled(Callout)`
  color: ${warningText};
  background-color: ${warningAccent};
`;

export const AlertCallout = styled(Callout)`
  color: ${alertText};
  background-color: ${alertAccent};
`;

export const CalloutHeader = styled.h3`
  padding: 0;
  margin-top: 0;
  margin-bottom: ${wide};
`;
