import { FieldProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import { getOptionValue } from 'react-select/lib/builtins';
import { Styles } from 'react-select/lib/styles';
import { ValueType } from 'react-select/lib/types';
import { FormField } from '../FormField';
import { SelectGroup, SelectOption } from './SelectField';
import { defaultSelectStyles } from './selectStyles';

type ValueToMultiSelectOptionsMapper<TValue> = (
  value: Array<TValue>,
) => Array<SelectOption<TValue>>;

const getValueFromOptions = <TValue extends unknown>(
  options: Array<SelectOption<TValue>>,
): Array<TValue> => (options ? options.map(option => option.value) : []);

type MultiSelectFieldProps<TValue> = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  options: Array<SelectOption<TValue>> | Array<SelectGroup<TValue>>;
  mapValueToSelectOptions: ValueToMultiSelectOptionsMapper<TValue>;
  mapOptionToKey: getOptionValue<SelectOption<TValue>>;
  styles?: Styles;
  innerRef?: React.RefObject<Select<SelectOption<TValue>>>;
};

export class MultiSelectField<TValue> extends React.Component<MultiSelectFieldProps<TValue>> {
  onChange = ({ form, field }: FieldProps) => (newValue: ValueType<SelectOption<TValue>>) => {
    const options = newValue as Array<SelectOption<TValue>>;
    form.setFieldValue(field.name, getValueFromOptions(options));
  };

  render() {
    const {
      name,
      label,
      optional,
      className,
      disabled,
      placeholder,
      options,
      mapValueToSelectOptions,
      mapOptionToKey: getOptionKey,
      styles,
      innerRef,
    } = this.props;

    return (
      <FormField name={name} label={label} optional={optional} className={className}>
        {({ field, form }) => (
          <Select<SelectOption<TValue>>
            isMulti={true}
            name={name}
            onChange={this.onChange({ form, field })}
            onBlur={field.onBlur} // TODO: This doesn't appear to set the field as touched
            value={mapValueToSelectOptions(field.value)}
            getOptionValue={getOptionKey}
            isDisabled={disabled || form.isSubmitting}
            placeholder={placeholder}
            options={options}
            styles={styles || defaultSelectStyles}
            ref={innerRef}
            closeMenuOnSelect={false}
          />
        )}
      </FormField>
    );
  }
}
