import React from 'react';
import styled from 'styled-components/macro';
import { modalPortalRootElementId } from './shared/Modal';

export const PortalRoots = () => (
  <>
    <ModalPortalRoot id={modalPortalRootElementId} />
  </>
);

const ModalPortalRoot = styled.div`
  position: absolute;
  z-index: 40;
`;
