import { keyBy } from 'lodash';
import React, { useMemo } from 'react';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { SortDirection } from '../../shared/Table';
import { yellow } from '../../styling/colours';
import { formatDateStamp } from '../../utils/dateStamp';
import { getAssigneeFullName } from '../goal-assignments/goalAssignment';
import { GoalState } from '../goal-states/goalState';
import { Goal } from '../goals/goal';
import {
  goalAttributeToSearchGoalsPageSortByColumn,
  SearchGoalsPageSortByColumn,
  searchGoalsPageSortByColumnToGoalAttribute,
} from './searchGoals';

type SearchGoalsTableProps = {
  goals: Array<Goal>;
  goalStates: Array<GoalState>;
  sortByColumn: SearchGoalsPageSortByColumn;
  sortDirection: SortDirection;
  onSortedChange: (sortByColumn: SearchGoalsPageSortByColumn, sortDirection: SortDirection) => void;
};

type ColumnAccessor = {
  Header: string;
  id?: keyof Goal | undefined;
  accessor: ((goal: Goal) => string) | keyof Goal;
  Cell?: ((cell: CellInfo) => string) | undefined;
  style?: { whiteSpace: string } | undefined;
  width: number;
};

export const SearchGoalsTable = ({
  goals,
  goalStates,
  sortByColumn,
  sortDirection,
  onSortedChange,
}: SearchGoalsTableProps) => {
  const stateCodesToGoalStates = keyBy(goalStates, (goalState) => goalState.stateCode);

  const headerProps = () => ({
    style: { background: yellow, fontWeight: 'bold' },
  });

  const rowProps = (state: {}, rowInfo: RowInfo | undefined) =>
    typeof rowInfo === 'undefined'
      ? {}
      : {
          style: { background: rowInfo.viewIndex % 2 ? 'whitesmoke' : 'white' },
        };

  const columns = useMemo<Array<ColumnAccessor>>(
    () => [
      {
        Header: 'Description',
        accessor: 'description',
        style: { whiteSpace: 'normal' },
        width: 250,
      },
      {
        Header: 'Project',
        id: 'project',
        accessor: (goal: Goal) => goal.project.name,
        width: 150,
      },
      {
        Header: 'Goal Group',
        accessor: 'goalGroupName',
        width: 250,
      },
      {
        Header: 'State',
        id: 'stateCode',
        accessor: (goal: Goal) => stateCodesToGoalStates[goal.stateCode].name,
        width: 115,
      },
      {
        Header: 'Assignees',
        id: 'assignees',
        accessor: (goal: Goal) =>
          goal.assignees.map((assignee) => getAssigneeFullName(assignee)).join(', '),
        style: { whiteSpace: 'normal' },
        width: 200,
      },
      {
        Header: 'Created Date',
        accessor: 'creationDate',
        Cell: (cell: CellInfo) => formatDateStamp(cell.value),
        width: 150,
      },
      {
        Header: 'Completion Date',
        accessor: 'completionDate',
        Cell: (cell: CellInfo) => formatDateStamp(cell.value),
        width: 150,
      },
    ],
    [],
  );

  return (
    <ReactTable
      data={goals}
      NoDataComponent={() => null}
      columns={columns}
      showPagination={false}
      defaultPageSize={200}
      minRows={0}
      getTheadProps={headerProps}
      getTrProps={rowProps}
      manual={true}
      sorted={[
        {
          id: searchGoalsPageSortByColumnToGoalAttribute(sortByColumn),
          desc: sortDirection === 'Desc',
        },
      ]}
      onSortedChange={(newSort) =>
        onSortedChange(
          goalAttributeToSearchGoalsPageSortByColumn(newSort[0].id as keyof Goal),
          newSort[0].desc ? 'Desc' : 'Asc',
        )
      }
    />
  );
};
