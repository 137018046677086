import { assertResponseIsSuccessful, createRequestInit, parseJson } from '../../utils/api';

export type Config = {
  clientId: string;
};

export const fetchConfig = async (): Promise<Config> => {
  const configResponse = await fetch('api/config', createRequestInit('get'));
  await assertResponseIsSuccessful(configResponse);

  return await parseJson<Config>(configResponse);
};
