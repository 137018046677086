import React from 'react';
import styled from 'styled-components/macro';
import { red } from '../styling/colours';
import { narrow } from '../styling/spacing';
import { getUserVisibleMessage } from '../utils/api';
import { AlertCallout } from './Callout';

type ErrorBoxProps = {
  className?: string;
  error: Error | string;
  genericErrorMessage?: string;
  inline?: boolean;
};

export const defaultErrorMessage = 'Oops! An error has occurred. Please try again';

export const ErrorBox = ({ error, className, genericErrorMessage, inline }: ErrorBoxProps) => {
  const errorMessage =
    typeof error === 'string'
      ? error
      : getUserVisibleMessage(error) || genericErrorMessage || defaultErrorMessage;

  return inline ? (
    <InlineError className={className}>{errorMessage}</InlineError>
  ) : (
    <AlertCallout className={className}>{errorMessage}</AlertCallout>
  );
};

const InlineError = styled.div`
  color: ${red};
  margin: ${narrow} 0;
`;
