import { FieldProps } from 'formik';
import React from 'react';
import Switch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components/macro';
import { green } from '../../styling/colours';
import { medium, narrow } from '../../styling/spacing';
import { FormField } from './FormField';

type ToggleFieldProps = {
  name: string;
  label?: string;
  className?: string;
  disabled?: boolean;
};

export class ToggleField extends React.Component<ToggleFieldProps> {
  onChange = ({ form, field }: FieldProps) => (checked: boolean) => {
    form.setFieldValue(field.name, checked);
  };

  render() {
    const { name, label, className, disabled } = this.props;

    return (
      <FormField name={name} label={label} optional={false} className={className}>
        {({ field, form }) => (
          <OffsetToggle
            {...field}
            id={name}
            onChange={this.onChange({ form, field })}
            checked={field.value}
            disabled={disabled}
          />
        )}
      </FormField>
    );
  }
}

export const Toggle = (props: ReactSwitchProps) => <Switch {...props} onColor={green} />;

const OffsetToggle = styled(Toggle)`
  margin-left: ${narrow};
`;

type InlineToggleProps = ReactSwitchProps & {
  className?: string;
  htmlFor?: string;
  label: React.ReactNode;
  labelColour?: string;
};

export const InlineToggle = ({
  className,
  htmlFor,
  label,
  labelColour,
  ...rest
}: InlineToggleProps) => (
  <InlineToggleContainer className={className}>
    <InlineToggleLabel htmlFor={htmlFor} color={labelColour}>
      <LabelText>{label}</LabelText>
      <Toggle {...rest} id={htmlFor} />
    </InlineToggleLabel>
  </InlineToggleContainer>
);

const InlineToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const InlineToggleLabel = styled.label`
  color: ${props => props.color};
  white-space: nowrap;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: ${narrow};
`;

const LabelText = styled.span`
  margin-right: ${medium};
`;

export const StyledToggleField = styled(ToggleField)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
