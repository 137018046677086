import { useCallback } from 'react';
import { Assignee, UpdateGoalAssignmentDto } from '../../features/goal-assignments/goalAssignment';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useGoalAssignmentsApi = () => {
  const updateAssigneeDoneAuthorisedFetch = useAuthorisedFetch<Assignee, UpdateGoalAssignmentDto>(
    'put',
  );
  const updateAssigneeDone = useCallback(
    (update) =>
      updateAssigneeDoneAuthorisedFetch(
        `goal-assignments/goal/${update.goalId}/user/${update.userId}`,
        update,
      ),
    [updateAssigneeDoneAuthorisedFetch],
  );

  return { updateAssigneeDone };
};
