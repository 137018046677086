import { concat } from 'lodash';
import { Framework } from '../frameworks/framework';
import { GoalState } from '../goal-states/goalState';
import { Goal } from '../goals/goal';
import { Project } from '../projects/project';
import { Team } from '../teams/team';
import { User } from '../users/user';
import {
  toUpdateKanbanViewOrdinalDto,
  UpdateKanbanViewOrdinalDto,
  UpdateKanbanViewOrdinalsDto,
} from './kanban-view-ordinal/kanbanViewOrdinal';

export type KanbanData = {
  goals: Array<Goal>;
};

export type KanbanViewUrlParams = {
  projectId?: string;
  userId?: string;
  teamId?: string;
};

export type KanbanViewOptions = {
  users: Array<User>;
  projects: Array<Project>;
  teams: Array<Team>;
};

export type KanbanViewOptionsParams = {
  showInactive: boolean;
};

export type Column = {
  goalStateCode: string;
  isCompleteState: boolean;
  title: string;
  goalIds: Array<number>;
};

export const createOrdinalUpdatesDto = (
  sourceColumn: Column,
  destinationColumn: Column,
  movementOnSameColumn: boolean,
  params: KanbanViewUrlParams,
): UpdateKanbanViewOrdinalsDto => {
  const ordinalUpdates: UpdateKanbanViewOrdinalsDto = { updates: [] };

  const sourceColumnOrdinalUpdates: Array<UpdateKanbanViewOrdinalDto> = sourceColumn.goalIds.map(
    (id, index) => toUpdateKanbanViewOrdinalDto(params, id, index),
  );

  if (movementOnSameColumn) {
    ordinalUpdates.updates = sourceColumnOrdinalUpdates;
  } else {
    const destinationColumnOrdinalUpdates: Array<UpdateKanbanViewOrdinalDto> =
      destinationColumn.goalIds.map((id, index) => toUpdateKanbanViewOrdinalDto(params, id, index));

    ordinalUpdates.updates = concat(sourceColumnOrdinalUpdates, destinationColumnOrdinalUpdates);
  }

  return ordinalUpdates;
};
