import { ClientAuthError, InteractionRequiredAuthError } from '@azure/msal-common';
import { useCallback, useContext } from 'react';
import { AuthContext, ConfigContext } from '../../shared/auth/AuthenticationContext';
import { createRequestInit, HttpMethod, parseJson } from '../../utils/api';
import { assertResponseIsSuccessful } from '../../utils/api';
// import { ConfigurationContext } from '../../infrastructure/configuration/configurationContext';

// This constructs a type which is either a single, never, optional argument if
// type of TRequest is never, or a single required argument of type TRequest.
// This enables the requestBody argument in the callback to be made optional based
// on whether a type for TRequest is provided while remaining typesafe (i.e.
// TypeScript will complain if the argument is provided or not provided when it should
// be).
// There is no way to do this except where rest parameters https://www.typescriptlang.org/docs/handbook/functions.html
// are used hence why the returned types are arrays of length 1.
export type OptionalBodyArgument<TRequest> = TRequest extends never
  ? Array<never | null>
  : Array<TRequest>;

export const useAuthorisedFetch = <TResponse, TRequest = never>(
  method: HttpMethod,
): ((path: string, ...[requestBody]: OptionalBodyArgument<TRequest>) => Promise<TResponse>) => {
  const { authProvider } = useContext(AuthContext);
  const { config } = useContext(ConfigContext);
  const account = authProvider.getAccount();

  return useCallback(
    async (path: string, ...[requestBody]: OptionalBodyArgument<TRequest>) => {
      try {
        const { accessToken } = await authProvider.getAccessToken();

        const response = await fetch(
          `api/${path}`,
          createRequestInit(method, accessToken, requestBody),
        );

        await assertResponseIsSuccessful(response);

        return await parseJson<TResponse>(response);
      } catch (e) {
        // There rarely is an error when silently acquiring a token.
        // If there is an error thrown we need to catch it and non-silently authenticate the user.
        // This isn't handled automatically by the library. https://docs.microsoft.com/en-us/azure/active-directory/develop/msal-error-handling-js#errors-that-require-interaction
        // .acquireTokenRedirect gets a new token by redirecting the user away from the site and then back.
        // Alternatively a .acquireTokenPopup can be used which uses a popup
        const loginRequest = {
          account,
          scopes: [`api://${config.clientId}/default`, 'openid'],
        };

        if (e instanceof InteractionRequiredAuthError || e instanceof ClientAuthError) {
          authProvider.acquireTokenRedirect({
            ...loginRequest,
          });
          // Throw a new error to satisfy the typing of this method to show we're not returning a value.
          // The user won't see this is the acquireTokenRedirect works.
          throw new Error('Error with authentication token, attempting to acquire a new one');
        } else {
          throw e;
        }
      }
    },
    [authProvider, config, method],
  );
};
