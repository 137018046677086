import React from 'react';
import styled from 'styled-components/macro';
import { ContextMenuButton } from '../../../shared/buttons/ContextMenuButton';
import { InlineToggle } from '../../../shared/form/ToggleField';
import { darkTurquoise, fontGrey, translucentWhite, white } from '../../../styling/colours';
import { regularFont } from '../../../styling/fonts';
import { narrower } from '../../../styling/spacing';
import ConfettiSettings from '../../kanban/confetti/ConfettiSettings';

type Props = {
  personalDoneTasksHidden: boolean;
  toggleUserHideDoneTasks: () => void;
};

export const UserViewContextMenuButton = ({
  personalDoneTasksHidden,
  toggleUserHideDoneTasks,
}: Props) => (
  <StyledContextMenuButton
    menuDirection="column"
    iconColour={white}
    iconContrastColour={darkTurquoise}
    backgroundHoverColour={translucentWhite}
  >
    {() => (
      <>
        <HidePersonalDoneTasksToggle
          htmlFor="togglePersonalDoneTasks"
          checked={personalDoneTasksHidden}
          onChange={toggleUserHideDoneTasks}
          label={
            <>
              <u>H</u>ide your done tasks?
            </>
          }
        />
        <ConfettiSettings />
      </>
    )}
  </StyledContextMenuButton>
);

const StyledContextMenuButton = styled(ContextMenuButton)`
  margin-left: ${narrower};
  color: ${fontGrey};
`;

const HidePersonalDoneTasksToggle = styled(InlineToggle)`
  font-size: ${regularFont};
`;
