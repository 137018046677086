import { useCallback } from 'react';
import { CreateProjectDto, Project, UpdateProjectDto } from '../../features/projects/project';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useProjectsApi = () => {
  const getProjectsAuthorisedFetch = useAuthorisedFetch<Array<Project>>('get');
  const getProjects = useCallback(
    () => getProjectsAuthorisedFetch('projects'),
    [getProjectsAuthorisedFetch],
  );

  const createProjectAuthorisedFetch = useAuthorisedFetch<Project, CreateProjectDto>('post');
  const createProject = useCallback(
    (createProjectDto) => createProjectAuthorisedFetch('projects', createProjectDto),
    [createProjectAuthorisedFetch],
  );

  const updateProjectAuthorisedFetch = useAuthorisedFetch<Project, UpdateProjectDto>('put');
  const updateProject = useCallback(
    (updateProjectDto) => updateProjectAuthorisedFetch('projects', updateProjectDto),
    [updateProjectAuthorisedFetch],
  );

  return { getProjects, createProject, updateProject };
};
