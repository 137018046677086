import React from 'react';
import 'react-table/react-table.css';
import styled from 'styled-components/macro';
import { LoadingOverlay } from '../../shared/LoadingOverlay';
import { PaginationControl } from '../../shared/PaginationControl';
import { SortDirection } from '../../shared/Table';
import { medium, mediumNarrow } from '../../styling/spacing';
import { GoalState } from '../goal-states/goalState';
import { Goal } from '../goals/goal';
import { PageSize, PageSizeControl } from './PageSizeControl';
import { SearchGoalsPageSortByColumn } from './searchGoals';
import { SearchGoalsTable } from './SearchGoalsTable';

type SearchGoalsControlledTableProps = {
  goals: Array<Goal>;
  goalStates: Array<GoalState>;
  currentPage: number;
  totalPages: number;
  pageSize: PageSize;
  sortByColumn: SearchGoalsPageSortByColumn;
  sortDirection: SortDirection;
  onSortedChange: (sortByColumn: SearchGoalsPageSortByColumn, sortDirection: SortDirection) => void;
  onSetCurrentPage: (pageNumber: number) => void;
  onSetPageSize: (pageSize: PageSize) => void;
  loading: boolean;
};

export const SearchGoalsControlledTable = ({
  goals,
  goalStates,
  currentPage,
  totalPages,
  pageSize,
  sortByColumn,
  sortDirection,
  onSortedChange,
  onSetCurrentPage,
  onSetPageSize,
  loading,
}: SearchGoalsControlledTableProps) => (
  <Container>
    <TopPaginationControl
      totalPages={totalPages}
      currentPage={currentPage}
      setCurrentPage={onSetCurrentPage}
    />
    <LoadingOverlay loading={loading}>
      <SearchGoalsTable
        goals={goals}
        goalStates={goalStates}
        sortByColumn={sortByColumn}
        sortDirection={sortDirection}
        onSortedChange={onSortedChange}
      />
    </LoadingOverlay>
    <BottomPaginationControl
      totalPages={totalPages}
      currentPage={currentPage}
      setCurrentPage={onSetCurrentPage}
    />
    <StyledPageSizeControl
      selectedPageSize={pageSize}
      onPageSizeChanged={onSetPageSize}
      disabled={loading}
    />
  </Container>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TopPaginationControl = styled(PaginationControl)`
  margin-bottom: ${mediumNarrow};
`;

const BottomPaginationControl = styled(PaginationControl)`
  margin-top: ${mediumNarrow};
`;

const StyledPageSizeControl = styled(PageSizeControl)`
  margin-top: ${medium};
`;
