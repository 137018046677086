import { assertNotNull } from '../../utils/assertNotNull';
import { Team } from '../teams/team';

export type ProjectTeam = Team & {
  projectId: number | null;
};

export type UpdateProjectTeamDto = {
  teamId: number;
  projectId: number;
};

export type CreateProjectTeamDto = {
  teamId: number;
  projectId: number | null;
};

export const toCreateProjectTeamDto = (teams: Array<ProjectTeam>): Array<CreateProjectTeamDto> =>
  teams.map(team => ({
    projectId: team.projectId,
    teamId: team.teamId,
  }));

export const toUpdateProjectTeamDto = (teams: Array<ProjectTeam>): Array<UpdateProjectTeamDto> =>
  teams.map(team => ({
    projectId: assertNotNull(team.projectId),
    teamId: team.teamId,
  }));

export const toProjectTeam = (team: Team, projectId: number | null): ProjectTeam => ({
  ...team,
  projectId,
});
