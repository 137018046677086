import { Formik } from 'formik';
import { find } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { alertAccent, successAccent } from '../../../styling/colours';
import { DateStamp } from '../../../utils/dateStamp';
import { Assignee, resetAssigneesDone } from '../../goal-assignments/goalAssignment';
import { GoalStatesContext } from '../../goal-states/GoalStatesContextProvider';
import { ProjectsContext } from '../../projects/ProjectsContextProvider';
import { UsersContext } from '../../users/UsersContextProvider';
import { CreateUpdateGoalModalForm } from '../CreateUpdateGoalForm';
import { CreateGoalDto } from '../goal';
import {
  CreateGoalFormModel,
  CreateGoalFormValidator,
  toCreateGoalDto,
} from './createGoalFormModel';

type Props = {
  projectId?: number;
  description?: string;
  dueDate?: DateStamp;
  notes?: string;
  goalGroupName?: string;
  goalStateCode: string;
  assignees?: Array<Assignee>;
  onSubmit: (createGoalDto: CreateGoalDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateGoalFormValidator();

export const CreateGoalModal = ({
  projectId,
  description,
  dueDate,
  notes,
  goalGroupName,
  goalStateCode,
  assignees,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const { activeUsers } = useContext(UsersContext);
  const { activeProjects } = useContext(ProjectsContext);
  const { goalStates } = useContext(GoalStatesContext);

  const initialValues = useMemo(
    () => ({
      description: description || '',
      dueDate,
      goalState: find(goalStates, (goalState) => goalState.stateCode === goalStateCode) || null,
      notes: notes || '',
      goalGroupName: goalGroupName || '',
      project:
        find(activeProjects, (project) => project && project.projectId === projectId) || null,
      assignees: assignees ? resetAssigneesDone(assignees) : [],
    }),
    [
      goalStates,
      activeProjects,
      projectId,
      description,
      dueDate,
      notes,
      goalGroupName,
      goalStateCode,
      assignees,
    ],
  );

  const onFormSubmit = (formModel: CreateGoalFormModel) => {
    onSubmit(toCreateGoalDto(formModel));
    close();
  };

  return (
    <Formik<CreateGoalFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, submitForm, errors, values, setFieldValue }) => {
        triggerFormSubmission = () => {
          if (values.description === '') {
            close();
          } else {
            submitForm();
          }
        };

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateGoalModalForm
            activeUsers={activeUsers}
            activeProjects={activeProjects}
            goalStates={goalStates}
            project={initialValues.project}
            setGoalDescription={(value: string) => setFieldValue('description', value)}
          >
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Create New Goal"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={close}
              disabled={isSubmitting}
              title="Cancel Input"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateGoalModalForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
