import { Validator } from 'fluentvalidation-ts';
import { colourHexRegEx, getContrastedColour } from '../../../styling/colours';
import { assertNotNull } from '../../../utils/assertNotNull';
import { Team } from '../../teams/team';
import { CreateUserDto } from '../user';

export type CreateUserFormModel = {
  chosenName: string;
  familyName: string;
  email: string;
  colour: string;
  isActive: boolean;
  team: Team | null;
};

export class CreateUserFormValidator extends Validator<CreateUserFormModel> {
  constructor() {
    super();

    this.ruleFor('chosenName')
      .notEmpty()
      .maxLength(32);

    this.ruleFor('familyName').maxLength(32);

    this.ruleFor('email')
      .notEmpty()
      .emailAddress()
      .maxLength(128);

    this.ruleFor('colour')
      .notEmpty()
      .matches(colourHexRegEx(true))
      .withMessage('Must be a 3 or 6 digit hex value');

    this.ruleFor('team').notNull();
  }
}

export const toCreateUserDto = (formModel: CreateUserFormModel): CreateUserDto => ({
  chosenName: formModel.chosenName,
  familyName: formModel.familyName,
  email: formModel.email,
  colour: formModel.colour.toUpperCase(),
  contrastColour: getContrastedColour(formModel.colour),
  isActive: formModel.isActive,
  teamId: assertNotNull(formModel.team).teamId,
});
