import { FieldProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import { getOptionValue } from 'react-select/lib/builtins';
import { Styles } from 'react-select/lib/styles';
import { ValueType } from 'react-select/lib/types';
import { FormField } from '../FormField';
import { defaultSelectStyles } from './selectStyles';

export type SelectOption<TValue> = {
  value: TValue;
  label: string;
};

export type SelectGroup<TValue> = {
  options: Array<SelectOption<TValue>>;
  label: string;
};

type ValueToSelectOptionMapper<TValue> = (value: TValue) => SelectOption<TValue>;

type SelectFieldProps<TValue> = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
  disabled?: boolean;
  maxMenuHeightInPixels?: number;
  placeholder?: string;
  options: Array<SelectOption<TValue>> | Array<SelectGroup<TValue>>;
  mapValueToSelectOption: ValueToSelectOptionMapper<TValue>;
  mapOptionToKey: getOptionValue<SelectOption<TValue>>;
  styles?: Styles;
  innerRef?: React.RefObject<Select<SelectOption<TValue>>>;
  onItemSelected?: (newValue: TValue) => void;
  defaultValue?: TValue | null;
};

export class SelectField<TValue> extends React.Component<SelectFieldProps<TValue>> {
  render() {
    const {
      name,
      label,
      optional,
      className,
      disabled,
      maxMenuHeightInPixels,
      placeholder,
      options,
      mapValueToSelectOption,
      mapOptionToKey: getOptionKey,
      styles,
      innerRef,
      onItemSelected,
      defaultValue,
    } = this.props;

    const onChange =
      ({ form, field }: FieldProps) =>
      (newValue: ValueType<SelectOption<TValue>>) => {
        const option = newValue as SelectOption<TValue>;
        form.setFieldValue(field.name, option.value);
        if (onItemSelected) {
          onItemSelected(option.value);
        }
      };

    return (
      <FormField name={name} label={label} optional={optional} className={className}>
        {({ field, form }) => (
          <Select<SelectOption<TValue>>
            name={name}
            onChange={onChange({ form, field })}
            onBlur={field.onBlur} // TODO: This doesn't appear to set the field as touched
            value={
              field.value || defaultValue
                ? mapValueToSelectOption(field.value || defaultValue)
                : null
            }
            getOptionValue={getOptionKey}
            isDisabled={disabled || form.isSubmitting}
            maxMenuHeight={maxMenuHeightInPixels}
            placeholder={placeholder}
            options={options}
            styles={styles || defaultSelectStyles}
            ref={innerRef}
          />
        )}
      </FormField>
    );
  }
}
