import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useTeamsApi } from '../../hooks/api/useTeamsApi';
import { Team } from './team';

type TeamsContextValue = {
  teams: Array<Team>;
  activeTeams: Array<Team>;
  refreshTeams: () => Promise<void>;
};

export const TeamsContext = React.createContext<TeamsContextValue>({
  teams: [],
  activeTeams: [],
  refreshTeams: () => {
    throw new Error('Cannot refresh teams outside of its context provider');
  },
});

export const TeamsContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [teams, setTeams] = useState<Array<Team>>([]);
  const [activeTeams, setActiveTeams] = useState<Array<Team>>([]);
  const { getTeams } = useTeamsApi();

  useEffect(() => {
    refreshTeams();
  }, []);

  useEffect(() => {
    setActiveTeams(teams.filter((team) => team.isActive));
  }, [teams]);

  const refreshTeams = async () => {
    setTeams(await getTeams());
  };

  return (
    <TeamsContext.Provider value={{ teams, activeTeams, refreshTeams }}>
      {children}
    </TeamsContext.Provider>
  );
};
