import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ReactComponent as CraneIcon } from '../../../images/icons/crane-solid.svg';
import { ReactComponent as Question } from '../../../images/icons/question-circle-solid.svg';
import { ReactComponent as Search } from '../../../images/icons/search-solid.svg';
import { ReactComponent as TodayGoalsIcon } from '../../../images/icons/today-goals.svg';
import { ReactComponent as Recurring } from '../../../images/icons/undo-solid.svg';
import { Icon } from '../../../shared/Icon';
import { translucentWhite } from '../../../styling/colours';
import { largeFont } from '../../../styling/fonts';
import { defaultBorderRadius, mediumNarrow, narrow } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';
import {
  frameworksUrl,
  helpUrl,
  recurringGoalsUrl,
  searchGoalsUrl,
  todayGoalsUrl,
} from '../../../urls';

type PagesProps = {
  hideSettings: () => void;
};

export const Pages = ({ hideSettings }: PagesProps) => (
  <>
    <PageLink to={recurringGoalsUrl()} onClick={hideSettings}>
      <PageIcon>
        <Recurring />
      </PageIcon>
      Recurring Goals
    </PageLink>
    <PageLink to={helpUrl()} onClick={hideSettings}>
      <PageIcon>
        <Question />
      </PageIcon>
      Help
    </PageLink>
    <PageLink to={searchGoalsUrl()} onClick={hideSettings}>
      <PageIcon>
        <Search />
      </PageIcon>
      Search Goals
    </PageLink>
    <PageLink to={todayGoalsUrl()} onClick={hideSettings}>
      <PageIcon>
        <TodayGoalsIcon />
      </PageIcon>
      Today's Goals
    </PageLink>
    <PageLink to={frameworksUrl()} onClick={hideSettings}>
      <PageIcon>
        <CraneIcon />
      </PageIcon>
      Frameworks
    </PageLink>
  </>
);

const PageLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  color: white;
  padding: ${mediumNarrow};
  font-size: ${largeFont};
  border-radius: ${defaultBorderRadius};
  text-decoration: none;

  &:hover {
    background-color: ${translucentWhite};
  }

  transition: all ${regularTransitionDuration} ease;
`;

const PageIcon = styled(Icon)`
  margin-right: ${narrow};
`;
