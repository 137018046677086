import React from 'react';
import { Styles } from 'react-select/lib/styles';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import {
  selectControlStyles,
  selectMultiValueStyles,
} from '../../shared/form/select-fields/selectStyles';
import { black, fontGrey, lightGrey, white } from '../../styling/colours';
import { getUserFullName, User } from './user';

export const mapUsersToSelectOptionsWithNull = (
  users: Array<User>,
  nullLabel: string = 'All',
): Array<SelectOption<User | null>> => {
  const nullOption: SelectOption<User | null> = { value: null, label: nullLabel };
  const userOptions = mapUsersToSelectOptions(users);

  return [nullOption, ...userOptions];
};

export const mapUsersToSelectOptions = (users: Array<User>): Array<SelectOption<User>> =>
  users.map(mapUserToSelectOption);

export const mapUserToSelectOption = (user: User): SelectOption<User> => ({
  value: user,
  label: getUserFullName(user),
});

type UserSelectStylesOptionsState = {
  data: SelectOption<User>;
  isFocused: boolean;
};

export const userSelectStyles: Styles = {
  control: (styles: React.CSSProperties, { isFocused }) => ({
    ...selectControlStyles(styles, isFocused),
  }),
  option: (
    styles: React.CSSProperties,
    { data, isFocused }: UserSelectStylesOptionsState,
  ): React.CSSProperties => ({
    ...styles,
    backgroundColor: isFocused ? (!!data.value ? data.value.colour : lightGrey) : white,
    color: isFocused ? (!!data.value ? data.value.contrastColour : black) : fontGrey,
  }),
  multiValue: (styles: React.CSSProperties, { data }: UserSelectStylesOptionsState) => ({
    ...selectMultiValueStyles(styles),
    backgroundColor: data.value.colour,
  }),
  multiValueLabel: (styles: React.CSSProperties, { data }: UserSelectStylesOptionsState) => ({
    ...styles,
    color: data.value.contrastColour,
  }),
  multiValueRemove: (styles: React.CSSProperties, { data }: UserSelectStylesOptionsState) => ({
    ...styles,
    color: data.value.contrastColour,
    ':hover': {
      backgroundColor: data.value.contrastColour,
      color: data.value.colour,
    },
  }),
};
