import { Form } from 'formik';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components/macro';
import { DateField } from '../../shared/form/input-fields/DateField';
import { InputField } from '../../shared/form/input-fields/InputField';
import { TextAreaField } from '../../shared/form/input-fields/TextAreaField';
import { TextAreaFieldWithMarkdownShortcuts } from '../../shared/form/input-fields/TextAreaFieldWithMarkdownShortcuts';
import { MultiSelectField } from '../../shared/form/select-fields/MultiSelectField';
import { SelectField } from '../../shared/form/select-fields/SelectField';
import { ModalActionButtonsSection } from '../../shared/Modal';
import { lightGrey } from '../../styling/colours';
import { medium, wide } from '../../styling/spacing';
import {
  mapAssigneesToSelectOptions,
  mapUsersToAssigneeSelectOptions,
} from '../goal-assignments/assigneeSelectUtils';
import { Assignee } from '../goal-assignments/goalAssignment';
import {
  GoalState,
  mapGoalStatesToSelectOptions,
  mapGoalStateToSelectOption,
} from '../goal-states/goalState';
import { Project } from '../projects/project';
import {
  mapProjectsToSelectOptions,
  mapProjectToSelectOption,
  projectSelectStyles,
} from '../projects/projectSelectUtils';
import { User } from '../users/user';
import { userSelectStyles } from '../users/userSelectUtils';

type FilteredUsers = {
  usersOnProject: Array<User>;
  usersOffProject: Array<User>;
};

type Props = {
  goalId?: number;
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  children?: React.ReactNode;
  isViewOnly?: boolean;
  project: Project | null;
  setGoalDescription: (value: string) => void;
};

export const CreateUpdateGoalModalForm = ({
  goalId,
  activeUsers,
  activeProjects,
  goalStates,
  children,
  isViewOnly,
  project,
  setGoalDescription,
}: Props) => {
  const descriptionTextAreaRef = useRef<HTMLTextAreaElement>(null);

  const [filteredUsers, setFilteredUsers] = useState<FilteredUsers>({
    usersOnProject: activeUsers,
    usersOffProject: [],
  });

  const onProjectSelected = (newProject: Project) => {
    updateFilteredActiveUsers(newProject);
  };

  const updateFilteredActiveUsers = (newSelectedProject: Project) => {
    if (!newSelectedProject) {
      setFilteredUsers({ usersOnProject: [], usersOffProject: [] });
      return;
    }
    const allowedTeamIds = newSelectedProject.teams.map((team) => team.teamId);
    const usersOnProject = activeUsers.filter((user) => allowedTeamIds.includes(user.team.teamId));
    const usersOffProject = activeUsers.filter(
      (user) => !allowedTeamIds.includes(user.team.teamId),
    );
    setFilteredUsers({ usersOnProject, usersOffProject });
  };

  useEffect(() => {
    if (!!project) {
      onProjectSelected(project);
    }

    const descriptionTextArea = descriptionTextAreaRef.current;
    if (descriptionTextArea !== null) {
      descriptionTextArea.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const assigneeOptions = useMemo(
    () => [
      {
        label: '',
        options: mapUsersToAssigneeSelectOptions(filteredUsers.usersOnProject, goalId || null),
      },
      {
        label: 'Outside of project',
        options: mapUsersToAssigneeSelectOptions(filteredUsers.usersOffProject, goalId || null),
      },
    ],
    [filteredUsers, goalId],
  );

  const projectOptions = useMemo(
    () => mapProjectsToSelectOptions(activeProjects),
    [activeProjects],
  );

  const goalStateOptions = useMemo(() => mapGoalStatesToSelectOptions(goalStates), [goalStates]);

  return (
    <Form>
      <InputSection>
        <LeftGroup>
          <TextAreaFieldWithMarkdownShortcuts
            name="description"
            label="Description"
            placeholder="Add a description..."
            fontSize="large"
            fontWeight="bold"
            rows={2}
            maxLength={128}
            ref={descriptionTextAreaRef}
            disabled={!!isViewOnly}
            setGoalDescription={setGoalDescription}
          />
          <SelectField<Project>
            name="project"
            label="Project"
            placeholder="Select a project..."
            options={projectOptions}
            mapValueToSelectOption={mapProjectToSelectOption}
            mapOptionToKey={(option) => option.value.projectId.toString()}
            styles={projectSelectStyles}
            disabled={!!isViewOnly}
            onItemSelected={onProjectSelected}
          />
          <MultiSelectField<Assignee>
            name="assignees"
            label="Assignees"
            optional={true}
            placeholder="Select assignees..."
            options={assigneeOptions}
            mapValueToSelectOptions={mapAssigneesToSelectOptions}
            mapOptionToKey={(option) => option.value.userId.toString()}
            styles={userSelectStyles}
            disabled={!!isViewOnly}
          />
          <TextAreaField
            name="notes"
            label="Notes"
            placeholder="Add some notes..."
            optional={true}
            rows={5}
            maxLength={1024}
            disabled={!!isViewOnly}
          />
        </LeftGroup>
        <RightGroup>
          <InputField
            name="goalGroupName"
            label="Group"
            placeholder="Add a group name..."
            optional={true}
            maxLength={32}
            disabled={!!isViewOnly}
          />
          <Separator />
          <SelectField<GoalState>
            name="goalState"
            label="State"
            placeholder="Select a state..."
            options={goalStateOptions}
            mapValueToSelectOption={mapGoalStateToSelectOption}
            mapOptionToKey={(option) => option.value.stateCode}
            disabled={!!isViewOnly}
          />
          <Separator />
          <DateField name="dueDate" label="Due date" optional={true} disabled={!!isViewOnly} />
        </RightGroup>
      </InputSection>
      {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
    </Form>
  );
};

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftGroup = styled.div`
  width: 450px;
  margin-right: ${wide};

  display: flex;
  flex-direction: column;
`;

const RightGroup = styled.div`
  width: 240px;
`;

const Separator = styled.div`
  border-bottom: 1px solid ${lightGrey};
  width: 100%;
  margin-bottom: ${medium};
`;
