import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { User } from '../users/user';
import { StatusEmailReceiver, toStatusEmailReceiver } from './projectStatusEmailReceiver';

export const mapUsersToStatusEmailReceiverSelectOptions = (
  users: Array<User>,
  projectId: number | null,
): Array<SelectOption<StatusEmailReceiver>> =>
  users.map(user => ({
    value: toStatusEmailReceiver(user, projectId),
    label: `${user.chosenName} ${user.familyName}`,
  }));

export const mapStatusEmailReceiversToSelectOptions = (
  statusEmailReceivers: Array<StatusEmailReceiver>,
): Array<SelectOption<StatusEmailReceiver>> =>
  statusEmailReceivers.map(receiver => ({
    value: receiver,
    label: `${receiver.chosenName} ${receiver.familyName}`,
  }));
