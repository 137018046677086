import React from 'react';
import { getComponentDisplayName } from '../../utils/getComponentDisplayName';
import { Modal } from '../Modal';

type ModalProps<TParams> = { open: (params: TParams) => void; close: () => void };

export type WithModalProps<TParams, TModalName extends string> = {
  [modalName in TModalName]: ModalProps<TParams>
};

export type WithModalEnhancer<TOwnProps, TParams, TModalName extends string> = (
  WrappedComponent: React.ComponentType<TOwnProps & WithModalProps<TParams, TModalName>>,
) => React.ComponentType<TOwnProps>;

type WithModalState<TParams> = {
  params: TParams | null;
  isOpen: boolean;
  closeRequested: boolean;
};

export type WithModalRenderProps<TOwnProps, TParams> = {
  ownProps: TOwnProps;
  params: TParams;
  closeModal: () => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
};

export const withModal = <TOwnProps, TParams, TModalName extends string>(
  modalName: TModalName,
  renderModal: (renderProps: WithModalRenderProps<TOwnProps, TParams>) => React.ReactNode,
  overrideCloseAction: boolean,
  hideCloseButton?: boolean,
): WithModalEnhancer<TOwnProps, TParams, TModalName> => (
  WrappedComponent: React.ComponentType<TOwnProps & WithModalProps<TParams, TModalName>>,
): React.ComponentType<TOwnProps> =>
  class WithModalWrapper extends React.Component<TOwnProps, WithModalState<TParams>> {
    static displayName = `withModal(${getComponentDisplayName(WrappedComponent)})`;

    state: WithModalState<TParams> = { params: null, isOpen: false, closeRequested: false };

    openModal = (params: TParams) => this.setState({ params, isOpen: true, closeRequested: false });

    closeModal = () => this.setState({ params: null, isOpen: false, closeRequested: false });

    onOverrideCloseRequested = () => this.setState({ closeRequested: true });

    cancelCloseRequest = () => this.setState({ closeRequested: false });

    render() {
      const modalProps = ({
        [modalName]: { open: this.openModal, close: this.closeModal },
      } as unknown) as WithModalProps<TParams, TModalName>;

      const { params, isOpen } = this.state;

      return (
        <>
          <WrappedComponent {...this.props} {...modalProps} />
          {isOpen && params != null && (
            <Modal
              isOpen={isOpen}
              onClose={overrideCloseAction ? this.onOverrideCloseRequested : this.closeModal}
              hideCloseButton={hideCloseButton || false}
            >
              {params &&
                renderModal({
                  ownProps: this.props,
                  params,
                  closeModal: this.closeModal,
                  closeRequested: this.state.closeRequested,
                  cancelCloseRequest: this.cancelCloseRequest,
                })}
            </Modal>
          )}
        </>
      );
    }
  };
