import { FieldProps } from 'formik';
import React, { useState } from 'react';
import { ColorResult, SketchPicker } from 'react-color';
import styled from 'styled-components/macro';
import { ReactComponent as Cross } from '../../images/icons/times-solid.svg';
import { greyBorder, lightGrey, lightRed, red } from '../../styling/colours';
import { narrow, narrowPixels, smallBorderRadius } from '../../styling/spacing';
import { regularTransitionDuration } from '../../styling/transitions';
import { IconButton } from '../buttons/IconButton';
import { FormField } from './FormField';

type ColourPickerFieldProps = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
};

export const ColourPickerField = (props: ColourPickerFieldProps) => {
  const onChange = ({ form, field }: FieldProps) => (colour: ColorResult) => {
    form.setFieldValue(field.name, colour.hex);
  };

  return (
    <FormField
      name={props.name}
      label={props.label}
      optional={props.optional}
      className={props.className}
    >
      {({ field, form }) => (
        <Container>
          <ColourPicker colour={field.value} onChange={onChange({ form, field })} />
        </Container>
      )}
    </FormField>
  );
};

const Container = styled.div`
  margin-left: ${narrow};
`;

type ColourPickerProps = {
  colour: string;
  onChange: (colour: ColorResult) => void;
  className?: string;
};

const ColourPicker = (props: ColourPickerProps) => {
  const [pickerIsOpen, setPickerIsOpen] = useState<boolean>(false);

  const onColourPreviewClick = (mouseEvent: React.MouseEvent) => {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
    setPickerIsOpen(!pickerIsOpen);
  };

  return (
    <ColourPickerContainer>
      <ColourPreview
        className={props.className}
        colour={props.colour}
        onClick={onColourPreviewClick}
      />
      {pickerIsOpen && (
        <SketchPickerContainer>
          <SketchPicker color={props.colour} disableAlpha={true} onChange={props.onChange} />
          <SketchPickerCloseButton colour={red} onClick={() => setPickerIsOpen(false)}>
            <Cross />
          </SketchPickerCloseButton>
        </SketchPickerContainer>
      )}
    </ColourPickerContainer>
  );
};

const ColourPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

const colourPreviewWidthPixels = 40.4; // To match height of input

const ColourPreview = styled.div<{ colour: string }>`
  height: ${colourPreviewWidthPixels}px;
  width: ${colourPreviewWidthPixels}px;
  max-width: ${colourPreviewWidthPixels}px;
  border: 1px solid ${greyBorder};
  border-radius: ${smallBorderRadius};
  background-color: ${props => props.colour};
  cursor: pointer;

  &:hover {
    opacity: 0.75;
  }

  transition: all ${regularTransitionDuration} ease;
`;

const SketchPickerContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: ${colourPreviewWidthPixels + narrowPixels}px;
`;

const closeButtonWidthPixels = 24;

const SketchPickerCloseButton = styled(IconButton)`
  position: absolute;
  top: -${closeButtonWidthPixels / 2}px;
  right: -${closeButtonWidthPixels / 2}px;
  background-color: ${lightGrey};
  height: ${closeButtonWidthPixels}px;
  width: ${closeButtonWidthPixels}px;

  &:hover {
    background-color: ${lightRed};
  }
`;
