import { Validator } from 'fluentvalidation-ts';
import { colourHexRegEx, getContrastedColour } from '../../../styling/colours';
import { CreateTeamDto } from '../team';

export type CreateTeamFormModel = {
  name: string;
  colour: string;
  isActive: boolean;
};

export class CreateTeamFormValidator extends Validator<CreateTeamFormModel> {
  constructor() {
    super();

    this.ruleFor('name')
      .notEmpty()
      .maxLength(32);

    this.ruleFor('colour')
      .notEmpty()
      .matches(colourHexRegEx(true))
      .withMessage('Must be a 3 or 6 digit hex value');
  }
}

export const toCreateTeamDto = (formModel: CreateTeamFormModel): CreateTeamDto => ({
  name: formModel.name,
  colour: formModel.colour.trim().toUpperCase(),
  contrastColour: getContrastedColour(formModel.colour.trim()),
  isActive: formModel.isActive,
});
