import { Validator } from 'fluentvalidation-ts';
import { assertNotNull } from '../../../utils/assertNotNull';
import { DateStamp } from '../../../utils/dateStamp';
import { Assignee, toUpdateGoalAssignmentDto } from '../../goal-assignments/goalAssignment';
import { GoalState } from '../../goal-states/goalState';
import { Project } from '../../projects/project';
import { UpdateGoalDto } from '../goal';

export type UpdateGoalFormModel = {
  goalId: number;
  description: string;
  dueDate?: DateStamp;
  goalState: GoalState | null;
  notes: string;
  goalGroupName: string;
  project: Project | null;
  assignees: Array<Assignee>;
};

export class UpdateGoalFormValidator extends Validator<UpdateGoalFormModel> {
  constructor() {
    super();

    this.ruleFor('description').maxLength(128);

    this.ruleFor('description').notEmpty().withMessage('Please provide a description');

    this.ruleFor('goalState').notNull().withMessage('Please choose a state');

    this.ruleFor('notes').maxLength(1028);

    this.ruleFor('goalGroupName').maxLength(32);

    this.ruleFor('project').notNull().withMessage('Please choose a project');
  }
}

export const toUpdateGoalDto = (formModel: UpdateGoalFormModel): UpdateGoalDto => ({
  goalId: formModel.goalId,
  description: formModel.description,
  dueDate: formModel.dueDate,
  goalStateCode: assertNotNull(formModel.goalState).stateCode,
  notes: formModel.notes,
  goalGroupName: formModel.goalGroupName,
  projectId: assertNotNull(formModel.project).projectId,
  assignees: toUpdateGoalAssignmentDto(formModel.assignees),
});
