import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import 'react-table/react-table.css';
import styled from 'styled-components/macro';
import { useRecurringGoalsApi } from '../../hooks/api/useRecurringGoalsApi';
import { ErrorBox } from '../../shared/ErrorBox';
import { CentredLoadingSpinner } from '../../shared/LoadingSpinner';
import { PageContainer } from '../../shared/PageContainer';
import { PageHeader } from '../../shared/PageHeader';
import { GoalStatesContext } from '../goal-states/GoalStatesContextProvider';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { UsersContext } from '../users/UsersContextProvider';
import { CreateRecurringGoalButton } from './create-recurring-goal/CreateRecurringGoalModal';
import { CreateRecurringGoalDto, RecurringGoalData, UpdateRecurringGoalDto } from './recurringGoal';
import { RecurringGoalsTable } from './RecurringGoalsTable';

type Props = RouteComponentProps;

const RecurringGoalsPageComponent = ({ history }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const [{ recurringGoals }, setRecurringGoalData] = useState<RecurringGoalData>(
    {} as RecurringGoalData,
  );

  const { refreshProjects } = useContext(ProjectsContext);
  const { refreshUsers } = useContext(UsersContext);

  const { fetchRecurringGoalData, createRecurringGoal, updateRecurringGoal, deleteRecurringGoal } =
    useRecurringGoalsApi();

  useEffect(() => getLatestData(), []);

  const onCreateRecurringGoal = (createRecurringGoalDto: CreateRecurringGoalDto) =>
    createRecurringGoal(createRecurringGoalDto)
      .then(() => getLatestData())
      .catch(setError);

  const onUpdateRecurringGoal = (updateRecurringGoalDto: UpdateRecurringGoalDto) =>
    updateRecurringGoal(updateRecurringGoalDto)
      .then(() => getLatestData())
      .catch(setError);

  const onDeleteRecurringGoal = (recurringGoalId: number) =>
    deleteRecurringGoal(recurringGoalId)
      .then(() => getLatestData())
      .catch(setError);

  const getLatestData = () => {
    setLoading(true);
    setError(null);
    fetchRecurringGoalData()
      .then((response: RecurringGoalData) => {
        return setRecurringGoalData(response);
      })
      .catch(setError)
      .finally(() => {
        Promise.all([refreshProjects(), refreshUsers()]).then(() => setLoading(false));
      });
  };

  return (
    <>
      <PageHeader viewName="Recurring Goals" history={history} />
      <Container>
        {error && <ErrorBox error={error} />}
        {loading ? (
          <CentredLoadingSpinner />
        ) : (
          <RecurringGoalsTable
            recurringGoals={recurringGoals}
            onUpdateRecurringGoal={onUpdateRecurringGoal}
            onDeleteRecurringGoal={onDeleteRecurringGoal}
          />
        )}

        <CreateRecurringGoalButton onCreateRecurringGoal={onCreateRecurringGoal} />
      </Container>
    </>
  );
};

export const RecurringGoalsPage = withRouter(RecurringGoalsPageComponent);

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
