import { useCallback } from 'react';
import {
  CreateFrameworkDto,
  Framework,
  FrameworkData,
  UpdateFrameworkDto,
} from '../../features/frameworks/framework';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useFrameworksApi = () => {
  const fetchFrameworksAuthorisedFetch = useAuthorisedFetch<FrameworkData>('get');
  const fetchFrameworks = useCallback(
    () => fetchFrameworksAuthorisedFetch('frameworks'),
    [fetchFrameworksAuthorisedFetch],
  );

  const createFrameworkAuthorisedFetch = useAuthorisedFetch<Framework, CreateFrameworkDto>('post');
  const createFramework = useCallback(
    (createFrameworkDto) => createFrameworkAuthorisedFetch('frameworks', createFrameworkDto),
    [createFrameworkAuthorisedFetch],
  );

  const updateFrameworkAuthorisedFetch = useAuthorisedFetch<Framework, UpdateFrameworkDto>('put');
  const updateFramework = useCallback(
    (updateFrameworkDto) => updateFrameworkAuthorisedFetch(`frameworks`, updateFrameworkDto),
    [updateFrameworkAuthorisedFetch],
  );

  const deleteFrameworkAuthorisedFetch = useAuthorisedFetch<null>('delete');
  const deleteFramework = useCallback(
    (frameworkId) => deleteFrameworkAuthorisedFetch(`frameworks/${frameworkId}`),
    [deleteFrameworkAuthorisedFetch],
  );

  return { fetchFrameworks, createFramework, updateFramework, deleteFramework };
};
