import { mapValues } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useSearchGoalsApi } from '../../hooks/api/useSearchGoalsApi';
import { ErrorBox } from '../../shared/ErrorBox';
import { CentredLoadingSpinner } from '../../shared/LoadingSpinner';
import { PageContainer } from '../../shared/PageContainer';
import { PageHeader } from '../../shared/PageHeader';
import { SortDirection } from '../../shared/Table';
import { ApiResponse } from '../../utils/api';
import { DateStamp } from '../../utils/dateStamp';
import { GoalStatesContext } from '../goal-states/GoalStatesContextProvider';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { UsersContext } from '../users/UsersContextProvider';
import { defaultPageSize, PageSize } from './PageSizeControl';
import {
  getSearchGoalsFilterValuesFromPageRequest,
  SearchGoalsPage,
  SearchGoalsPageRequest,
  SearchGoalsPageSortByColumn,
} from './searchGoals';
import { SearchGoalsControlledTable } from './SearchGoalsControlledTable';
import { SearchGoalsFilters } from './SearchGoalsFilters';

type SearchProps = RouteComponentProps;

type SearchPageState = {
  searchGoalsPage: SearchGoalsPage;
  loading: boolean;
  firstLoad: boolean;
  error: Error | null;
};

const initialPageRequest: SearchGoalsPageRequest = {
  pageNumber: 1,
  pageSize: defaultPageSize,
  sortByColumn: 'CreationDate',
  sortDirection: 'Desc',
  projectId: null,
  goalStateCode: null,
  assigneeId: null,
  showInactiveAssignees: false,
  creationFromDate: null,
  creationToDate: null,
  completionFromDate: null,
  completionToDate: null,
  showCompleted: false,
};

const SearchPageComponent = ({ history }: SearchProps) => {
  const [isShowingInactiveProjects, setIsShowingInactiveProjects] = useState<boolean>(false);
  const [isShowingInactiveAssignees, setIsShowingInactiveAssignees] = useState<boolean>(false);
  const [searchPageState, setSearchPageState] = useState<SearchPageState>({
    searchGoalsPage: {
      pageRequest: initialPageRequest,
      totalPages: 0,
      goals: [],
    },
    loading: false,
    firstLoad: true,
    error: null,
  });

  const { goalStates } = useContext(GoalStatesContext);
  const { users, activeUsers, refreshUsers } = useContext(UsersContext);
  const { projects, activeProjects, refreshProjects } = useContext(ProjectsContext);

  const { fetchSearchGoalsPage } = useSearchGoalsApi();

  useEffect(() => {
    sendNewPageRequest({ ...searchPageState.searchGoalsPage.pageRequest });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendNewPageRequest = (pageRequest: SearchGoalsPageRequest) => {
    setSearchPageState((state) => ({ ...state, loading: true, error: null }));

    getSearchGoalsPage(pageRequest)
      .then((response: SearchGoalsPage) => {
        setSearchPageState((state) => ({
          ...state,
          searchGoalsPage: response,
        }));
      })
      .catch((error) => setSearchPageState((state) => ({ ...state, error })))
      .finally(() =>
        setSearchPageState((state) => ({ ...state, loading: false, firstLoad: false })),
      );
  };

  const getSearchGoalsPage = async (pageRequest: SearchGoalsPageRequest) => {
    await refreshProjects();
    await refreshUsers();
    return await fetchSearchGoalsPage(pageRequest);
  };

  const onSortedChange = (
    sortByColumn: SearchGoalsPageSortByColumn,
    sortDirection: SortDirection,
  ) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      sortByColumn,
      sortDirection,
    });

  const onSetCurrentPage = (pageNumber: number) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber,
    });

  const onSetProjectId = (projectId: number | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      projectId,
    });

  const onShowInactiveProjectsChanged = (showInactiveProjects: boolean) => {
    setIsShowingInactiveProjects(showInactiveProjects);
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      projectId: null,
    });
  };

  const onSetGoalStateCode = (goalStateCode: string | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      goalStateCode,
    });

  const onSetAssigneeId = (assigneeId: number | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      assigneeId,
    });

  const onShowInactiveAssigneesChanged = (showInactiveAssignees: boolean) => {
    setIsShowingInactiveAssignees(showInactiveAssignees);
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      assigneeId: null,
      showInactiveAssignees,
    });
  };

  const onSetCreationFromDate = (creationFromDate: DateStamp | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      creationFromDate,
    });

  const onSetCreationToDate = (creationToDate: DateStamp | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      creationToDate,
    });

  const onSetCompletionFromDate = (completionFromDate: DateStamp | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      completionFromDate,
    });

  const onSetCompletionToDate = (completionToDate: DateStamp | null) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      completionToDate,
    });

  const onShowCompletedChanged = (showCompleted: boolean) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      completionFromDate: null,
      completionToDate: null,
      showCompleted,
    });

  const onSetPageSize = (pageSize: PageSize) =>
    sendNewPageRequest({
      ...searchPageState.searchGoalsPage.pageRequest,
      pageNumber: 1,
      pageSize,
    });

  return (
    <>
      <PageHeader viewName="Search Goals" history={history} />
      <Container>
        <SearchGoalsFilters
          filterValues={getSearchGoalsFilterValuesFromPageRequest(
            searchPageState.searchGoalsPage.pageRequest,
            isShowingInactiveProjects,
          )}
          resetFilters={() => sendNewPageRequest(initialPageRequest)}
          projects={isShowingInactiveProjects ? projects : activeProjects}
          goalStates={goalStates}
          assignees={isShowingInactiveAssignees ? users : activeUsers}
          setProjectId={onSetProjectId}
          showInactiveProjectsChanged={onShowInactiveProjectsChanged}
          setGoalStateCode={onSetGoalStateCode}
          setAssigneeId={onSetAssigneeId}
          showInactiveAssigneesChanged={onShowInactiveAssigneesChanged}
          setCreationFromDate={onSetCreationFromDate}
          setCreationToDate={onSetCreationToDate}
          setCompletionFromDate={onSetCompletionFromDate}
          setCompletionToDate={onSetCompletionToDate}
          showCompletedChanged={onShowCompletedChanged}
          disabled={searchPageState.loading}
        />
        {searchPageState.error ? (
          <ErrorBox error={searchPageState.error} />
        ) : searchPageState.firstLoad ? (
          <CentredLoadingSpinner />
        ) : (
          <SearchGoalsControlledTable
            goals={searchPageState.searchGoalsPage.goals}
            goalStates={goalStates}
            currentPage={searchPageState.searchGoalsPage.pageRequest.pageNumber}
            totalPages={searchPageState.searchGoalsPage.totalPages}
            pageSize={searchPageState.searchGoalsPage.pageRequest.pageSize}
            sortByColumn={searchPageState.searchGoalsPage.pageRequest.sortByColumn}
            sortDirection={searchPageState.searchGoalsPage.pageRequest.sortDirection}
            onSortedChange={onSortedChange}
            onSetCurrentPage={onSetCurrentPage}
            onSetPageSize={onSetPageSize}
            loading={searchPageState.loading}
          />
        )}
      </Container>
    </>
  );
};

export const SearchPage = withRouter(SearchPageComponent);

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
