import { useCallback } from 'react';
import { SearchGoalsPage, SearchGoalsPageRequest } from '../../features/search-goals/searchGoals';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useSearchGoalsApi = () => {
  const fetchSearchGoalsPageAuthorisedFetch = useAuthorisedFetch<
    SearchGoalsPage,
    SearchGoalsPageRequest
  >('put');
  const fetchSearchGoalsPage = useCallback(
    (pageRequest) => fetchSearchGoalsPageAuthorisedFetch('search-goals/goals-page', pageRequest),
    [fetchSearchGoalsPageAuthorisedFetch],
  );

  return { fetchSearchGoalsPage };
};
