import { useCallback } from 'react';
import { GoalState } from '../../features/goal-states/goalState';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useGoalStateApi = () => {
  const getGoalStatesAuthorisedFetch = useAuthorisedFetch<Array<GoalState>>('get');
  const getGoalStates = useCallback(
    () => getGoalStatesAuthorisedFetch('goal-states'),
    [getGoalStatesAuthorisedFetch],
  );

  return { getGoalStates };
};
