import React from 'react';
import { ShortcutKey } from './ShortcutKey';
import { Table, TBody, Td, Tr } from './Table';

const shortcutKeys = [
  ['⬅', 'Navigate to the previous project / user view'],
  ['➡', 'Navigate to the next project / user view'],
  ['h', 'Toggle personal done tasks visible'],
  ['p', 'Navigate to first project view'],
  ['r', 'Refresh kanban board data'],
  ['s', 'Toggle settings visible'],
  ['u', 'Navigate to first user view'],
  ['c', 'Switch between person/project pages'],
  ['?', 'Open help modal'],
];

export const ShortcutList = () => {
  return (
    <Table>
      <TBody>
        {shortcutKeys.map(([shortcut, description]) => (
          <Tr key={shortcut}>
            <Td>{description}</Td>
            <Td>
              <ShortcutKey>{shortcut}</ShortcutKey>
            </Td>
          </Tr>
        ))}
      </TBody>
    </Table>
  );
};
