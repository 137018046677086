import { filter, find, findIndex, some } from 'lodash';
import { homeUrl, viewByProjectUrl, viewByUserUrl } from '../../urls';
import { KanbanViewUrlParams } from '../kanban/kanban';
import { Project } from '../projects/project';
import { StandupModeStatus } from '../standup/useStandup';
import { getUserFullName, User } from '../users/user';

export const isEverythingView = (urlParams: KanbanViewUrlParams): boolean =>
  !urlParams.projectId && !urlParams.userId;

export const EVERYTHING_VIEW_NAME = 'All the things';

export const isUserIdValidOrNull = (
  urlParams: KanbanViewUrlParams,
  activeUsers: Array<User>,
): boolean => {
  const userId = urlParams.userId;
  return !userId || activeUsers.some(u => u.userId === Number(userId));
};

export const isProjectIdValidOrNull = (
  urlParams: KanbanViewUrlParams,
  activeProjects: Array<Project>,
): boolean => {
  const projectId = urlParams.projectId;
  return !projectId || activeProjects.some(p => p.projectId === Number(projectId));
};

export const getViewName = (
  urlParams: KanbanViewUrlParams,
  activeProjects: Array<Project>,
  activeUsers: Array<User>,
): string => {
  const { projectId, userId } = urlParams;

  if (!projectId && !userId) {
    return EVERYTHING_VIEW_NAME;
  }

  const project = find(activeProjects, p => p.projectId === Number(projectId));
  const user = find(activeUsers, u => u.userId === Number(userId));

  return project ? project.name : user ? getUserFullName(user) : '';
};

export const getRetainerDaysRemaining = (
  urlParams: KanbanViewUrlParams,
  activeProjects: Array<Project>,
): number | null => {
  const project = activeProjects.find(p => p.projectId.toString() === urlParams.projectId);
  if (project) {
    return project.retainerDaysRemaining;
  }
  return null;
};

export const getFirstProjectUrl = (activeProjects: Array<Project>): string =>
  activeProjects && activeProjects.length
    ? viewByProjectUrl(activeProjects[0].projectId)
    : homeUrl();

export const getFirstUserUrl = (activeUsers: Array<User>): string =>
  activeUsers && activeUsers.length ? viewByUserUrl(activeUsers[0].userId) : homeUrl();

export const getRandomUserUrl = (
  activeUsers: Array<User>,
  standupModeStatus: StandupModeStatus,
  standupTeamIds: Array<number>,
) => {
  const filteredActiveUsers =
    standupModeStatus === 'Joined'
      ? filter(activeUsers, u => standupTeamIds.includes(u.team.teamId))
      : activeUsers;

  return filteredActiveUsers && filteredActiveUsers.length
    ? viewByUserUrl(
        filteredActiveUsers[Math.floor(Math.random() * filteredActiveUsers.length)].userId,
      )
    : homeUrl();
};

export const getRandomProjectUrl = (
  activeProjects: Array<Project>,
  standupModeStatus: StandupModeStatus,
  standupTeamIds: Array<number>,
  isPersonalDevelopmentStandup: boolean,
) => {
  if (isPersonalDevelopmentStandup && activeProjects && activeProjects.length) {
    const pdProject = find(activeProjects, p => p.name === 'PD');
    return pdProject ? viewByProjectUrl(pdProject.projectId) : homeUrl();
  }

  const filteredActiveProjects =
    standupModeStatus === 'Joined'
      ? filter(
          activeProjects,
          p => some(p.teams, team => standupTeamIds.includes(team.teamId)) && p.name !== 'PD',
        )
      : activeProjects;

  return filteredActiveProjects && filteredActiveProjects.length
    ? viewByProjectUrl(
        filteredActiveProjects[Math.floor(Math.random() * filteredActiveProjects.length)].projectId,
      )
    : homeUrl();
};

export const getPreviousUrl = (
  urlParams: KanbanViewUrlParams,
  activeProjects: Array<Project>,
  activeUsers: Array<User>,
  standupModeStatus: StandupModeStatus,
  standupTeamIds: Array<number>,
  isPersonalDevelopmentStandup: boolean,
): string => {
  const { projectId, userId } = urlParams;

  if (isPersonalDevelopmentStandup && projectId && activeProjects && activeProjects.length) {
    const pdProject = find(activeProjects, p => p.name === 'PD');
    return pdProject ? viewByProjectUrl(pdProject.projectId) : homeUrl();
  }

  if (projectId && activeProjects && activeProjects.length) {
    const filteredActiveProjects =
      standupModeStatus === 'Joined'
        ? filter(
            activeProjects,
            p => some(p.teams, team => standupTeamIds.includes(team.teamId)) && p.name !== 'PD',
          )
        : activeProjects;

    if (filteredActiveProjects.length) {
      const currentProjectIndex = findIndex(
        filteredActiveProjects,
        p => p.projectId === Number(projectId),
      );
      const previousProjectIndex =
        currentProjectIndex === -1
          ? filteredActiveProjects.length - 1
          : (currentProjectIndex + filteredActiveProjects.length - 1) %
            filteredActiveProjects.length;

      return viewByProjectUrl(filteredActiveProjects[previousProjectIndex].projectId);
    }
  }

  if (userId && activeUsers && activeUsers.length) {
    const filteredActiveUsers =
      standupModeStatus === 'Joined'
        ? filter(activeUsers, u => standupTeamIds.includes(u.team.teamId))
        : activeUsers;

    if (filteredActiveUsers.length) {
      const currentUserIndex = findIndex(filteredActiveUsers, u => u.userId === Number(userId));
      const previousUserIndex =
        currentUserIndex === -1
          ? filteredActiveUsers.length - 1
          : (currentUserIndex + filteredActiveUsers.length - 1) % filteredActiveUsers.length;

      return viewByUserUrl(filteredActiveUsers[previousUserIndex].userId);
    }
  }

  return homeUrl();
};

export const getNextUrl = (
  urlParams: KanbanViewUrlParams,
  activeProjects: Array<Project>,
  activeUsers: Array<User>,
  standupModeStatus: StandupModeStatus,
  standupTeamIds: Array<number>,
  isPersonalDevelopmentStandup: boolean,
): string => {
  const { projectId, userId } = urlParams;

  if (isPersonalDevelopmentStandup && projectId && activeProjects && activeProjects.length) {
    const pdProject = find(activeProjects, p => p.name === 'PD');
    return pdProject ? viewByProjectUrl(pdProject.projectId) : homeUrl();
  }

  if (projectId && activeProjects && activeProjects.length) {
    const filteredActiveProjects =
      standupModeStatus === 'Joined'
        ? filter(
            activeProjects,
            p => some(p.teams, team => standupTeamIds.includes(team.teamId)) && p.name !== 'PD',
          )
        : activeProjects;

    if (filteredActiveProjects.length) {
      const currentProjectIndex = findIndex(
        filteredActiveProjects,
        p => p.projectId === Number(projectId),
      );
      const nextProjectIndex =
        currentProjectIndex === -1 ? 0 : (currentProjectIndex + 1) % filteredActiveProjects.length;

      return viewByProjectUrl(filteredActiveProjects[nextProjectIndex].projectId);
    }
  }

  if (userId && activeUsers && activeUsers.length) {
    const filteredActiveUsers =
      standupModeStatus === 'Joined'
        ? filter(activeUsers, u => standupTeamIds.includes(u.team.teamId))
        : activeUsers;

    if (filteredActiveUsers.length) {
      const currentUserIndex = findIndex(filteredActiveUsers, u => u.userId === Number(userId));
      const nextUserIndex =
        currentUserIndex === -1 ? 0 : (currentUserIndex + 1) % filteredActiveUsers.length;

      return viewByUserUrl(filteredActiveUsers[nextUserIndex].userId);
    }
  }

  return homeUrl();
};

export const getUrl = (urlParams: KanbanViewUrlParams) => {
  const { projectId, userId } = urlParams;

  if (projectId) {
    return viewByProjectUrl(Number(projectId));
  }

  if (userId) {
    return viewByUserUrl(Number(userId));
  }

  return homeUrl();
};
