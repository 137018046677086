import React, { PropsWithChildren } from 'react';
import { useEffect, useState } from 'react';
import { ApiRequest, fetchJson, from, withJsonBody } from '../../utils/api';
import { isPositiveInteger } from '../../utils/isPositiveInteger';
import {
  CreateProjectFrameworkDto,
  ProjectFramework,
  UpdateProjectFrameworkDto,
} from '../project-frameworks/projectFrameworks';
import {
  CreateProjectStatusEmailReceiverDto,
  StatusEmailReceiver,
} from '../project-status-email-receivers/projectStatusEmailReceiver';
import {
  CreateProjectTeamDto,
  ProjectTeam,
  UpdateProjectTeamDto,
} from '../project-teams/projectTeam';

export type Project = {
  projectId: number;
  name: string;
  colour: string;
  contrastColour: string;
  externalTicketUrlTemplate: string;
  jiraKeys: string;
  isActive: boolean;
  teamMailingListEmail: string;
  additionalStatusEmailReceivers: Array<StatusEmailReceiver>;
  spreadsheetRetainerGuid: string;
  retainerDaysRemaining: number | null;
  teams: Array<ProjectTeam>;
  frameworks: Array<ProjectFramework>;
};

export type CreateProjectDto = {
  name: string;
  colour: string;
  contrastColour: string;
  externalTicketUrlTemplate: string;
  jiraKeys: string;
  isActive: boolean;
  teamMailingListEmail: string | null;
  additionalStatusEmailReceivers: Array<CreateProjectStatusEmailReceiverDto>;
  spreadsheetRetainerGuid: string;
  teams: Array<CreateProjectTeamDto>;
  frameworks: Array<CreateProjectFrameworkDto>;
};

export type UpdateProjectDto = {
  projectId: number;
  name: string;
  colour: string;
  contrastColour: string;
  externalTicketUrlTemplate: string;
  jiraKeys: string;
  isActive: boolean;
  teamMailingListEmail: string | null;
  additionalStatusEmailReceivers: Array<CreateProjectStatusEmailReceiverDto>;
  spreadsheetRetainerGuid: string;
  teams: Array<UpdateProjectTeamDto>;
  frameworks: Array<UpdateProjectFrameworkDto>;
};

export const isValidUrlAndTicketRef = (
  url: string,
  testString: string,
  jiraKeys: string,
): boolean =>
  url.includes('dev.azure.com')
    ? isValidDevOpsTicketRef(testString)
    : isValidJiraTicketRef(testString, jiraKeys);

const isValidDevOpsTicketRef = (testString: string): boolean => isPositiveInteger(testString);

const isValidJiraTicketRef = (testString: string, jiraKeys: string): boolean => {
  if (!jiraKeys) {
    return false;
  }

  const jiraKeyList = jiraKeys.split(',').map((key) => key.trim());
  return jiraKeyList.some((jiraKey) => new RegExp(`^${jiraKey}-\\d+$`).test(testString));
};
