import { Form } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { DateField } from '../../shared/form/input-fields/DateField';
import { TextAreaField } from '../../shared/form/input-fields/TextAreaField';
import { MultiSelectField } from '../../shared/form/select-fields/MultiSelectField';
import { ModalActionButtonsSection } from '../../shared/Modal';
import { wide } from '../../styling/spacing';
import { projectSelectStyles } from '../projects/projectSelectUtils';
import { FrameworkPageProject } from './framework';
import { mapFrameworkPageProjectsToSelectOptions } from './frameworkSelectUtils';

type Props = {
  projects: Array<FrameworkPageProject>;
  children?: React.ReactNode;
};

export const CreateUpdateFrameworkForm = ({ projects, children }: Props) => {
  const projectOptions = useMemo(
    () => mapFrameworkPageProjectsToSelectOptions(projects),
    [projects],
  );

  return (
    <Form>
      <InputSection>
        <LeftGroup>
          <TextAreaField
            name="name"
            label="Name"
            placeholder="Add a name..."
            fontWeight="bold"
            maxLength={128}
          />
          <MultiSelectField<FrameworkPageProject>
            name="projects"
            label="Projects"
            placeholder="Select the projects which use this framework..."
            options={projectOptions}
            mapValueToSelectOptions={mapFrameworkPageProjectsToSelectOptions}
            mapOptionToKey={(option) => option.value.projectId.toString()}
            styles={projectSelectStyles}
          />
        </LeftGroup>
        <RightGroup>
          <DateField name="endOfLifeDate" label="End of Life Date" optional={false} />
        </RightGroup>
      </InputSection>
      {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
    </Form>
  );
};

const InputSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const LeftGroup = styled.div`
  width: 450px;
  margin-right: ${wide};

  display: flex;
  flex-direction: column;
`;

const RightGroup = styled.div`
  width: 240px;
`;
