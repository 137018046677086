import React, { forwardRef } from 'react';
import styled from 'styled-components/macro';
import { FormField } from '../FormField';
import { inputStyles } from './inputStyles';

export type TextAreaFieldProps = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
  disabled?: boolean;
  placeholder?: string;
  fontSize?: 'tiny' | 'small' | 'large';
  fontWeight?: 'bold';
  rows?: number;
  maxLength?: number;
};

export const TextAreaField = forwardRef<HTMLTextAreaElement, TextAreaFieldProps>((props, ref) => (
  <FormField
    name={props.name}
    label={props.label}
    optional={props.optional}
    className={props.className}
  >
    {({ field, form }) => (
      <TextArea
        {...field}
        ref={ref}
        id={props.name}
        value={field.value || ''}
        disabled={props.disabled || form.isSubmitting}
        placeholder={props.placeholder}
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        rows={props.rows}
        maxLength={props.maxLength}
      />
    )}
  </FormField>
));

type TextAreaProps = {
  fontSize?: string;
  fontWeight?: string;
};

const TextArea = styled.textarea<TextAreaProps>`
  ${({ fontSize, fontWeight }) => inputStyles({ fontSize, fontWeight })};

  resize: vertical;
  font-family: inherit;
`;
