import { useCallback } from 'react';
import {
  KanbanData,
  KanbanViewOptions,
  KanbanViewOptionsParams,
  KanbanViewUrlParams,
} from '../../features/kanban/kanban';
import { UpdateKanbanViewOrdinalsDto } from '../../features/kanban/kanban-view-ordinal/kanbanViewOrdinal';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useKanbanApi = () => {
  const fetchKanbanDataAuthorisedFetch = useAuthorisedFetch<KanbanData, KanbanViewUrlParams>('put');
  const fetchKanbanData = useCallback(
    (urlParams) => fetchKanbanDataAuthorisedFetch('kanban/data', urlParams),
    [fetchKanbanDataAuthorisedFetch],
  );

  const sendOutTheCallAuthorisedFetch = useAuthorisedFetch<null>('post');
  const sendOutTheCall = useCallback(
    () => sendOutTheCallAuthorisedFetch('kanban/send-call'),
    [sendOutTheCallAuthorisedFetch],
  );

  const updateKanbanViewOrdinalsAuthorisedFetch = useAuthorisedFetch<
    null,
    UpdateKanbanViewOrdinalsDto
  >('put');
  const updateKanbanViewOrdinals = useCallback(
    (updates) => updateKanbanViewOrdinalsAuthorisedFetch('kanban/view-ordinals', updates),
    [updateKanbanViewOrdinalsAuthorisedFetch],
  );

  return { fetchKanbanData, sendOutTheCall, updateKanbanViewOrdinals };
};
