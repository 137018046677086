import { useCallback } from 'react';
import { CreateTeamDto, Team, UpdateTeamDto } from '../../features/teams/team';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useTeamsApi = () => {
  const createTeamAuthorisedFetch = useAuthorisedFetch<Team, CreateTeamDto>('post');
  const createTeam = useCallback(
    (createTeamDto) => createTeamAuthorisedFetch('teams', createTeamDto),
    [createTeamAuthorisedFetch],
  );

  const updateTeamAuthorisedFetch = useAuthorisedFetch<Team, UpdateTeamDto>('put');
  const updateTeam = useCallback(
    (updateTeamDto) => updateTeamAuthorisedFetch('teams', updateTeamDto),
    [updateTeamAuthorisedFetch],
  );

  const getTeamsAuthorisedFetch = useAuthorisedFetch<Array<Team>>('get');
  const getTeams = useCallback(() => getTeamsAuthorisedFetch('teams'), [getTeamsAuthorisedFetch]);

  return { createTeam, updateTeam, getTeams };
};
