import { Field, FieldProps, getIn } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Question } from '../../images/icons/question-circle-solid.svg';
import { alertText, lightGrey } from '../../styling/colours';
import { tinyFont } from '../../styling/fonts';
import { mediumNarrow, narrow, narrower } from '../../styling/spacing';
import { Icon } from '../Icon';
import { Tooltip, TooltipProps } from '../Tooltip';

type FormFieldProps = {
  name: string;
  label?: string;
  optional: boolean | undefined;
  type?: string;
  className?: string;
  children: (props: FieldProps) => React.ReactNode;
  tooltip?: TooltipProps;
};

export const FormField = (props: FormFieldProps) => (
  <FormFieldContainer className={props.className}>
    <Field name={props.name} type={props.type}>
      {({ form, field }: FieldProps) => {
        const touched: boolean = getIn(form.touched, props.name);
        const error = getIn(form.errors, props.name);
        const invalid: boolean = touched && error;

        return (
          <>
            {props.label && (
              <FieldLabel
                name={props.name}
                label={props.label}
                optional={props.optional}
                tooltipProps={props.tooltip}
              />
            )}
            {props.children({ form, field })}
            {invalid && <FieldError>{error}</FieldError>}
          </>
        );
      }}
    </Field>
  </FormFieldContainer>
);

const FormFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${mediumNarrow};
`;

type FieldLabelProps = {
  name: string;
  label: string;
  optional?: boolean;
  className?: string;
  tooltipProps?: TooltipProps;
};

export const FieldLabel = (props: FieldLabelProps) => (
  <Label htmlFor={props.name} className={props.className}>
    {`${props.label}${props.optional ? ' (optional)' : ''}`}
    {props.tooltipProps && (
      <Tooltip {...props.tooltipProps}>
        <Icon>
          <Question />
        </Icon>
      </Tooltip>
    )}
  </Label>
);

const Label = styled.label`
  text-transform: uppercase;
  font-size: ${tinyFont};
  font-color: ${lightGrey};
  margin-left: ${narrow};
`;

const FieldError = styled.div`
  color: ${alertText};
  margin-top: ${narrower};
  margin-left: ${narrow};
`;
