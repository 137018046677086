import { Formik } from 'formik';
import React, { createRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import {
  alertAccent,
  getRandomColour,
  successAccent,
  warningAccent,
} from '../../../styling/colours';
import { CreateUpdateUserForm } from '../CreateUpdateUserForm';
import { CreateUserDto } from '../user';
import {
  CreateUserFormModel,
  CreateUserFormValidator,
  toCreateUserDto,
} from './createUserFormModel';

type Props = {
  onSubmit: (createUserDto: CreateUserDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateUserFormValidator();

export const CreateUserModal = ({ onSubmit, closeRequested, cancelCloseRequest, close }: Props) => {
  const chosenNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const chosenNameInput = chosenNameInputRef.current;
    if (chosenNameInput !== null) {
      chosenNameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues = {
    chosenName: '',
    familyName: '',
    email: '',
    colour: getRandomColour(true),
    isActive: true,
    team: null,
  };

  return (
    <Formik<CreateUserFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        onSubmit(toCreateUserDto(formModel));
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateUserForm chosenNameInputRef={chosenNameInputRef} formTitle="Create new user">
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton
              onClick={close}
              disabled={isSubmitting}
              title="Cancel Input"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateUserForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
