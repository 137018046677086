import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../shared/buttons/IconButton';
import { alertAccent, successAccent, warningAccent } from '../../styling/colours';
import { CreateUpdateFrameworkForm } from './CreateUpdateFrameworkForm';
import { CreateFrameworkDto, FrameworkPageProject, FrameworkWithProjects } from './framework';
import {
  toUpdateFrameworkDto,
  UpdateFrameworkFormModel,
  UpdateFrameworkFormValidator,
} from './updateFrameworkFormModel';

type Props = {
  framework: FrameworkWithProjects;
  projects: Array<FrameworkPageProject>;
  onSubmit: (framework: CreateFrameworkDto) => void;
  onDelete: (frameworkId: number | null) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new UpdateFrameworkFormValidator();

export const UpdateFrameworkModal = ({
  framework,
  projects,
  onSubmit,
  onDelete,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues: UpdateFrameworkFormModel = useMemo(
    () => ({
      frameworkId: framework.frameworkId,
      name: framework.name,
      endOfLifeDate: framework.endOfLifeDate,
      projects: framework.projects,
    }),
    [framework],
  );

  return (
    <Formik<UpdateFrameworkFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toUpdateFrameworkDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, handleReset, submitForm, errors, values }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateFrameworkForm projects={projects}>
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton
              onClick={() => onDelete(framework.frameworkId)}
              title={
                values.projects.length > 0
                  ? 'Some projects are still using this framework'
                  : 'Delete Framework'
              }
              hoverColour={alertAccent}
              disabled={values.projects.length > 0}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateFrameworkForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
