import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useKanbanApi } from '../../../hooks/api/useKanbanApi';
import { ReactComponent as Moose } from '../../../images/icons/moose.svg';
import { IconButton } from '../../../shared/buttons/IconButton';
import { red, translucentWhite, white } from '../../../styling/colours';
import { mediumPixels, navMenuHeightPixels } from '../../../styling/spacing';

export const CallButton = () => {
  const [mooseCallError, setMooseCallError] = useState<Error | null>(null);

  const { sendOutTheCall } = useKanbanApi();

  const onSendOutTheCall = () => {
    setMooseCallError(null);
    sendOutTheCall().catch((error: Error) => {
      setMooseCallError(error);
    });
  };

  return (
    <MooseButton
      onClick={onSendOutTheCall}
      colour={mooseCallError ? red : white}
      hoverColour={translucentWhite}
      title="Send out the call..."
    >
      <Moose />
    </MooseButton>
  );
};

const MooseButton = styled(IconButton)`
  position: absolute;
  top: ${mediumPixels / 2}px;
  right: ${mediumPixels / 2}px;
  height: ${navMenuHeightPixels - mediumPixels}px;
  width: ${navMenuHeightPixels - mediumPixels}px;
`;
