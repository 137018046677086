import React, { InputHTMLAttributes } from 'react';
import styled from 'styled-components/macro';
import { narrow } from '../../styling/spacing';

export type InlineSliderProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> & {
  className?: string;
  htmlFor?: string;
};

export const InlineSlider = ({ className, htmlFor, ...rest }: InlineSliderProps) => (
  <InlineSliderContainer className={className}>
    <Slider {...rest} id={htmlFor} />
  </InlineSliderContainer>
);

const InlineSliderContainer = styled.div`
  color: ${({ color }) => color};
  align-items: center;
  padding: ${narrow};
`;

const Slider = styled.input.attrs({ type: 'range' })`
  -webkit-appearance: none;
  width: 100%;
  height: 15px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0060df;
    cursor: pointer;
    border: none;
  }
  &::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #0060df;
    cursor: pointer;
    border: none;
  }
`;

export default InlineSlider;
