import { filter, some } from 'lodash';
import { ApiRequest, fetchJson, from, withJsonBody } from '../../utils/api';
import { DateStamp } from '../../utils/dateStamp';
import {
  Assignee,
  CreateGoalAssignmentDto,
  toUpdateGoalAssignmentDto,
  UpdateGoalAssignmentDto,
} from '../goal-assignments/goalAssignment';
import { GoalState, GoalStateCode } from '../goal-states/goalState';
import { Project } from '../projects/project';

export type Goal = {
  goalId: number;
  description: string;
  stateCode: GoalStateCode;
  goalState: GoalState;
  notes: string;
  goalGroupId: number;
  goalGroupName: string;
  project: Project;
  creationDate: DateStamp;
  lastUpdatedDate: DateStamp;
  completionDate: DateStamp;
  dueDate: DateStamp;
  assignees: Array<Assignee>;
  ordinal: number;
};

export type CreateGoalDto = {
  description: string;
  dueDate?: DateStamp;
  goalStateCode: GoalStateCode;
  notes: string;
  goalGroupName: string;
  projectId: number;
  assignees: Array<CreateGoalAssignmentDto>;
};

export type UpdateGoalDto = {
  goalId: number;
  description: string;
  dueDate?: DateStamp;
  goalStateCode: GoalStateCode;
  notes: string;
  goalGroupName: string;
  projectId: number;
  assignees: Array<UpdateGoalAssignmentDto>;
};

export const toUpdateGoalDto = (goal: Goal): UpdateGoalDto => ({
  goalId: goal.goalId,
  description: goal.description,
  dueDate: goal.dueDate,
  goalStateCode: goal.stateCode,
  notes: goal.notes,
  goalGroupName: goal.goalGroupName,
  projectId: goal.project.projectId,
  assignees: toUpdateGoalAssignmentDto(goal.assignees),
});

export const getIncompleteGoals = (goals: Array<Goal>, userId: number): Array<Goal> =>
  filter(goals, (goal) =>
    some(goal.assignees, (assignee) => assignee.userId === userId && !assignee.assigneeDone),
  );

export const getCurrentTeamOnlyGoals = (goals: Array<Goal>, currentTeamId: number): Array<Goal> =>
  filter(
    goals,
    (goal) =>
      goal.assignees.length === 0 || goal.assignees.some((a) => a.team.teamId === currentTeamId),
  );

export const getIncompleteGoalsIds = (
  goalIds: Array<number>,
  goalsById: { [goalId: number]: Goal },
  userId: number | null,
): Array<number> =>
  filter(goalIds, (goalId) =>
    some(
      goalsById[goalId].assignees,
      (assignee) => assignee.userId === userId && !assignee.assigneeDone,
    ),
  );
