import React from 'react';
import styled from 'styled-components/macro';
import { darkGrey, lightGrey } from '../../styling/colours';
import { Button, ButtonProps, standardButtonHeight } from './Button';

type IconButtonProps = ButtonProps &
  IconContainerProps &
  IconButtonContainerProps & {
    type?: 'submit' | 'reset' | 'button';
    title?: string;
  };

export const IconButton = (props: IconButtonProps) => (
  <IconButtonContainer className={props.className} type={props.type || 'button'} {...props}>
    <IconContainer colour={props.colour}>{props.children}</IconContainer>
  </IconButtonContainer>
);

type IconButtonContainerProps = {
  hoverColour?: string;
};

// eslint-disable-next-line no-unexpected-multiline
const IconButtonContainer = styled(({ hoverColour, ...props }) => <Button {...props} />)<
  IconButtonContainerProps
>`
  background-color: transparent;
  height: ${standardButtonHeight};
  width: ${standardButtonHeight};
  border-radius: 50%;
  padding: 0;

  &:hover {
    background-color: ${props => !props.disabled && (props.hoverColour || lightGrey)};
  }

  &:hover,
  &:focus {
    outline: none;
  }
`;

type IconContainerProps = {
  colour?: string;
};

const IconContainer = styled.div<IconContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  svg {
    height: 50%;
    width: 50%;
    color: ${props => props.colour};
  }
`;

export const BorderedIconButton = styled(IconButton)`
  border: 1px solid ${darkGrey};
`;
