import React from 'react';
import styled from 'styled-components/macro';
import {
  Assignee,
  AssigneesDoneById,
  getAssigneeFullName,
} from '../features/goal-assignments/goalAssignment';
import { ReactComponent as Tick } from '../images/icons/check-solid.svg';
import { ReactComponent as Circle } from '../images/icons/circle-regular.svg';
import { fontGrey, lightGrey, white } from '../styling/colours';
import { tinyFont } from '../styling/fonts';
import { narrow, narrower, smallBorderRadius } from '../styling/spacing';
import { regularTransitionDuration } from '../styling/transitions';
import { Icon } from './Icon';

type AssigneeStatusIndicatorGroupProps = {
  assignees: Array<Assignee>;
  assigneesDoneById: AssigneesDoneById;
  onAssigneeClick: (mouseEvent: React.MouseEvent<HTMLElement>, assignee: Assignee) => void;
  justify?: 'end';
  className?: string;
};

export const AssigneeStatusIndicatorGroup = ({
  assignees,
  assigneesDoneById,
  onAssigneeClick,
  justify,
  className,
}: AssigneeStatusIndicatorGroupProps) => (
  <AssigneeGroup justify={justify} className={className}>
    {assignees.map(assignee => (
      <AssigneeStatusIndicator
        key={assignee.userId}
        assignee={assignee}
        assigneeDone={assigneesDoneById[assignee.userId].assigneeDone}
        onAssigneeClick={onAssigneeClick}
        justify={justify}
      />
    ))}
  </AssigneeGroup>
);

type AssigneeGroupProps = {
  justify?: 'end';
  className?: string;
};

export const AssigneeGroup = styled.div<AssigneeGroupProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${props => (props.justify === 'end' ? 'flex-end' : 'flex-start')};
  flex-wrap: wrap;
`;

type AssigneeStatusIndicatorProps = {
  assignee: Assignee;
  assigneeDone: boolean;
  onAssigneeClick: (mouseEvent: React.MouseEvent<HTMLElement>, assignee: Assignee) => void;
  justify?: 'end';
};

export const AssigneeStatusIndicator = ({
  assignee,
  assigneeDone,
  onAssigneeClick,
  justify,
}: AssigneeStatusIndicatorProps) => (
  <Container
    onClick={event => onAssigneeClick(event, assignee)}
    colour={assignee.colour}
    contrastColour={assignee.contrastColour}
    assigneeDone={assigneeDone}
    justify={justify}
  >
    {getAssigneeFullName(assignee)}
    {assigneeDone ? (
      <AssigneeDoneIcon colour={assignee.contrastColour} size="font">
        <Tick />
      </AssigneeDoneIcon>
    ) : (
      <AssigneeDoneIcon colour={fontGrey} size="font">
        <Circle />
      </AssigneeDoneIcon>
    )}
  </Container>
);

type ContainerProps = {
  colour: string;
  contrastColour: string;
  assigneeDone: boolean;
  justify?: 'end';
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;

  padding: ${narrower};
  margin-left: ${props => (props.justify === 'end' ? narrow : 0)};
  margin-right: ${props => (props.justify === 'end' ? 0 : narrow)};
  margin-top: ${narrow};
  font-size: ${tinyFont};
  white-space: nowrap;

  cursor: pointer;

  border: 2px solid ${props => props.colour};
  border-radius: ${smallBorderRadius};
  background-color: ${props => (props.assigneeDone ? props.colour : white)};
  color: ${props => (props.assigneeDone ? props.contrastColour : fontGrey)};

  &:hover {
    border-color: ${lightGrey};
  }

  transition: all ${regularTransitionDuration} ease;
`;

const AssigneeDoneIcon = styled(Icon)`
  margin-left: ${narrower};
`;
