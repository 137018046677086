import React from 'react';
import styled from 'styled-components/macro';
import { fontGrey, white, yellow } from '../styling/colours';
import { medium, narrow, wide } from '../styling/spacing';

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin-bottom: ${wide};
  border-radius: 10px;
  overflow: hidden;
`;

export const THead = styled.thead``;

export const TBody = styled.tbody``;

export const Tr = styled.tr`
  &:nth-child(odd) {
    background-color: ${white};
  }

  &:nth-child(even) {
    background-color: whitesmoke;
  }
`;

// eslint-disable-next-line no-unexpected-multiline
export const Th = styled(({ centreAligned, rightAligned, ...props }) => <th {...props} />)<{
  centreAligned?: boolean;
  rightAligned?: boolean;
}>`
  color: ${fontGrey};
  background-color: ${yellow};
  padding: ${narrow} ${medium};
  text-align: ${(props) =>
    (props.centreAligned && 'center') || (props.rightAligned && 'right') || 'start'};
`;

// eslint-disable-next-line no-unexpected-multiline
export const Td = styled(({ centreAligned, rightAligned, ...props }) => <td {...props} />)<{
  centreAligned?: boolean;
  rightAligned?: boolean;
}>`
  color: ${fontGrey};
  padding: ${narrow} ${medium};
  text-align: ${(props) =>
    (props.centreAligned && 'center') || (props.rightAligned && 'right') || 'start'};
`;

export type SortDirection = 'Asc' | 'Desc';
