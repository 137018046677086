import React from 'react';
import { Styles } from 'react-select/lib/styles';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import {
  selectControlStyles,
  selectMultiValueStyles,
} from '../../shared/form/select-fields/selectStyles';
import { black, fontGrey, lightGrey, white } from '../../styling/colours';
import { Team } from './team';

export const mapTeamToSelectOption = (team: Team): SelectOption<Team> => ({
  value: team,
  label: team.name,
});

export const mapTeamsToSelectOptions = (teams: Array<Team>): Array<SelectOption<Team>> =>
  teams.map(mapTeamToSelectOption);

type TeamSelectStylesOptionsState = {
  data: SelectOption<Team>;
  isFocused: boolean;
};

export const teamSelectStyles: Styles = {
  control: (styles: React.CSSProperties, { isFocused }) => ({
    ...selectControlStyles(styles, isFocused),
  }),
  option: (
    styles: React.CSSProperties,
    { data, isFocused }: TeamSelectStylesOptionsState,
  ): React.CSSProperties => ({
    ...styles,
    backgroundColor: isFocused ? (!!data.value ? data.value.colour : lightGrey) : white,
    color: isFocused ? (!!data.value ? data.value.contrastColour : black) : fontGrey,
  }),
  multiValue: (styles: React.CSSProperties, { data }: TeamSelectStylesOptionsState) => ({
    ...selectMultiValueStyles(styles),
    backgroundColor: data.value.colour,
  }),
  multiValueLabel: (styles: React.CSSProperties, { data }: TeamSelectStylesOptionsState) => ({
    ...styles,
    color: data.value.contrastColour,
  }),
  multiValueRemove: (styles: React.CSSProperties, { data }: TeamSelectStylesOptionsState) => ({
    ...styles,
    color: data.value.contrastColour,
    ':hover': {
      backgroundColor: data.value.contrastColour,
      color: data.value.colour,
    },
  }),
};

type TeamMultipleSelectStylesOptionsState = {
  data: SelectOption<Team>;
  isFocused: boolean;
};

export const teamMultiSelectStyles: Styles = {
  control: (styles: React.CSSProperties, { isFocused }) => ({
    ...selectControlStyles(styles, isFocused),
  }),
  option: (
    styles: React.CSSProperties,
    { data, isFocused }: TeamMultipleSelectStylesOptionsState,
  ): React.CSSProperties => ({
    ...styles,
    backgroundColor: isFocused ? (!!data.value ? data.value.colour : lightGrey) : white,
    color: isFocused ? (!!data.value ? data.value.contrastColour : black) : fontGrey,
  }),
};
