import { flowRight } from 'lodash';
import React from 'react';
import { ReactComponent as Plus } from '../../../images/icons/plus-solid.svg';
import { withModal, WithModalProps } from '../../../shared/higher-order-components/withModal';
import { lightPurple, translucentWhite } from '../../../styling/colours';
import { viewByUserUrl } from '../../../urls';
import { Team } from '../../teams/team';
import { CreateUserModal } from '../../users/create-user/CreateUserModal';
import { UpdateUserModal } from '../../users/update-user/UpdateUserModal';
import { CreateUserDto, getUserFullName, UpdateUserDto, User } from '../../users/user';
import { KanbanViewUrlParams } from '../kanban';
import { TeamGroupContainer, TeamGroupTitle } from './KanbanOptions';
import { OptionsColumn, OptionsColumnHeader, OptionsColumnHeaderButton } from './KanbanSettings';
import { ViewOptionsLink } from './ViewOptionsLink';

type CreateUserModalParams = {
  onSubmit: (createUserDto: CreateUserDto) => void;
};

type CreateUserModalProps = WithModalProps<CreateUserModalParams, 'createUserModal'>;

type UpdateUserModalParams = {
  user: User;
  onSubmit: (updateUserDto: UpdateUserDto) => void;
};

type UpdateUserModalProps = WithModalProps<UpdateUserModalParams, 'updateUserModal'>;

type OwnProps = {
  urlParams?: KanbanViewUrlParams;
  users: Array<User>;
  teams: Array<Team>;
  hideSettings: () => void;
  onCreateUser: (createUserDto: CreateUserDto) => void;
  onUpdateUser: (updateUserDto: UpdateUserDto) => void;
};

type UserViewOptionsProps = CreateUserModalProps & UpdateUserModalProps & OwnProps;

const UserViewOptionsComponent = ({
  createUserModal,
  updateUserModal,
  urlParams,
  users,
  teams,
  hideSettings,
  onCreateUser,
  onUpdateUser,
}: UserViewOptionsProps) => (
  <OptionsColumn>
    <OptionsColumnHeader>
      Users
      <OptionsColumnHeaderButton
        onClick={() => createUserModal.open({ onSubmit: onCreateUser })}
        colour={lightPurple}
        hoverColour={translucentWhite}
      >
        <Plus />
      </OptionsColumnHeaderButton>
    </OptionsColumnHeader>
    {teams.map(team => (
      <TeamGroupContainer key={team.teamId}>
        <TeamGroupTitle>{team.name}</TeamGroupTitle>
        {users
          .filter(user => user.team.teamId === team.teamId)
          .map(user => (
            <ViewOptionsLink
              key={user.userId}
              text={getUserFullName(user)}
              to={viewByUserUrl(user.userId)}
              isCurrentView={urlParams && user.userId.toString() === urlParams.userId}
              disabled={!user.isActive}
              onClick={hideSettings}
              onEditClick={() =>
                updateUserModal.open({
                  user,
                  onSubmit: onUpdateUser,
                })
              }
            />
          ))}
      </TeamGroupContainer>
    ))}
  </OptionsColumn>
);

const withCreateUserModal = withModal<OwnProps, CreateUserModalParams, 'createUserModal'>(
  'createUserModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <CreateUserModal
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const withUpdateUserModal = withModal<
  OwnProps & CreateUserModalProps,
  UpdateUserModalParams,
  'updateUserModal'
>(
  'updateUserModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <UpdateUserModal
      user={params.user}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const enhance = flowRight(
  withCreateUserModal,
  withUpdateUserModal,
);

export const UserViewOptions = enhance(UserViewOptionsComponent);
