import { format, parse } from 'date-fns';
import { SelectOption } from '../shared/form/select-fields/SelectField';
import { assertNotNull } from './assertNotNull';

export type DateStamp = string;
export const dateStampFormatString = 'YYYY-MM-DD';

export const toDateStamp = (date: Date): DateStamp => format(date, dateStampFormatString);

export const toDate = (dateStamp: DateStamp): Date => parse(dateStamp);

export const dateDisplayFormat = 'D-MMM-YY';

export const formatDateStamp = (dateStamp: DateStamp) =>
  dateStamp ? format(dateStamp, dateDisplayFormat) : '';

export const formatDateStampWithDay = (dateStamp: DateStamp) =>
  dateStamp ? format(dateStamp, 'ddd ' + dateDisplayFormat) : '';

export type WeekdayOption = null | 1 | 2 | 3 | 4 | 5;

export const weekdayOptions: Array<SelectOption<WeekdayOption>> = [
  { value: null, label: 'Any' },
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
];

export const mapNumberToWeekdaySelectOption = (n: WeekdayOption) =>
  weekdayOptions.find(option => option.value === n) || weekdayOptions[0];

export const getWeekdayNameFromValue = (value: WeekdayOption): string =>
  assertNotNull(weekdayOptions.find(option => option.value === value)).label;
