import { FieldArray, FieldArrayRenderProps, Form, Formik } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { ContextMenuButton } from '../../../shared/buttons/ContextMenuButton';
import {
  darkTurquoise,
  fontGrey,
  lightGrey,
  lightTurquoise,
  translucentTurquoise,
  translucentWhite,
  white,
} from '../../../styling/colours';
import { defaultBorderRadius, narrow } from '../../../styling/spacing';
import { Team } from '../../teams/team';
import { StandupOption } from './StandupOption';

type Props = {
  defaultTeamId?: number;
  teams: Array<Team>;
  selectTeams: (teamIds: Array<number>, isPersonalDevelopmentStandup: boolean) => void;
  isPersonalDevelopmentStandup: boolean;
  setPersonalDevelopmentStandup: (newValue: boolean) => void;
};

export type SelectStandupTeamsFormModel = {
  teamIds: Array<number>;
};

export const StandupMenuButton = ({
  defaultTeamId,
  teams,
  selectTeams,
  isPersonalDevelopmentStandup,
  setPersonalDevelopmentStandup,
}: Props) => {
  const handleSingleTeamCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: SelectStandupTeamsFormModel,
    fieldArrayProps: FieldArrayRenderProps,
    team: Team,
  ) => {
    if (event.target.checked) {
      fieldArrayProps.push(team.teamId);
    } else {
      const index = values.teamIds.indexOf(team.teamId);
      fieldArrayProps.remove(index);
    }
  };

  const changeAllTeamCheckboxes = (
    newValue: boolean,
    values: SelectStandupTeamsFormModel,
    fieldArrayProps: FieldArrayRenderProps,
  ) => {
    teams.forEach(team => {
      const isTeamChecked = values.teamIds.includes(team.teamId);
      if (newValue && !isTeamChecked) {
        fieldArrayProps.push(team.teamId);
      } else if (!newValue && isTeamChecked) {
        fieldArrayProps.pop();
      }
    });
  };

  const handleSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: SelectStandupTeamsFormModel,
    fieldArrayProps: FieldArrayRenderProps,
  ) => {
    const isSelectAllChecked = event.target.checked;
    changeAllTeamCheckboxes(isSelectAllChecked, values, fieldArrayProps);
  };

  const handlePersonalDevelopmentCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    values: SelectStandupTeamsFormModel,
    fieldArrayProps: FieldArrayRenderProps,
  ) => {
    const isPersonalDevelopmentChecked = event.target.checked;
    setPersonalDevelopmentStandup(isPersonalDevelopmentChecked);
    changeAllTeamCheckboxes(isPersonalDevelopmentChecked, values, fieldArrayProps);
  };

  return (
    <StyledContextMenuButton
      menuDirection="column"
      iconColour={white}
      iconContrastColour={darkTurquoise}
      backgroundHoverColour={translucentWhite}
      openToTheLeft={true}
    >
      {() => (
        <Formik<SelectStandupTeamsFormModel>
          initialValues={{
            teamIds: defaultTeamId ? [defaultTeamId] : [],
          }}
          onSubmit={values => {
            selectTeams(values.teamIds, isPersonalDevelopmentStandup);
          }}
        >
          {({ values }) => (
            <Form>
              <FieldArray
                name="teamIds"
                render={fieldArrayProps => (
                  <div>
                    {teams.map(team => (
                      <StandupOption
                        key={team.teamId}
                        name="teamIds"
                        value={team.teamId}
                        label={team.name}
                        checked={values.teamIds.includes(team.teamId)}
                        disabled={isPersonalDevelopmentStandup}
                        onChange={event =>
                          handleSingleTeamCheckboxChange(event, values, fieldArrayProps, team)
                        }
                      />
                    ))}
                    <StandupOption
                      name="teamIds"
                      label="Global standup"
                      checked={values.teamIds.length === teams.length}
                      disabled={isPersonalDevelopmentStandup}
                      onChange={event =>
                        handleSelectAllCheckboxChange(event, values, fieldArrayProps)
                      }
                    />
                    <StandupOption
                      name="isPersonalDevelopmentStandup"
                      label="PD standup"
                      onChange={event =>
                        handlePersonalDevelopmentCheckboxChange(event, values, fieldArrayProps)
                      }
                    />
                  </div>
                )}
              />
              <JoinStandupButtonContainer>
                <JoinStandupButton type="submit" disabled={values.teamIds.length === 0}>
                  Join
                </JoinStandupButton>
              </JoinStandupButtonContainer>
            </Form>
          )}
        </Formik>
      )}
    </StyledContextMenuButton>
  );
};

const StyledContextMenuButton = styled(ContextMenuButton)`
  color: ${fontGrey};
`;

const JoinStandupButtonContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const JoinStandupButton = styled.button`
  font-size: large;
  background-color: ${lightTurquoise};
  color: ${white};
  padding: ${narrow};
  border-radius: ${defaultBorderRadius};
  border: 0;
  margin: ${narrow};

  &:disabled {
    background-color: ${translucentTurquoise};
    color: ${lightGrey};
  }
`;
