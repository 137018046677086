export const homeUrl = () => '/';

export const viewByProjectUrl = (projectId?: number) =>
  projectId ? `${homeUrl()}project/${projectId}` : `${homeUrl()}project`;

export const viewByUserUrl = (userId?: number) =>
  userId ? `${homeUrl()}user/${userId}` : `${homeUrl()}user`;

export const viewByTeamUrl = (teamId?: number) =>
  teamId ? `${homeUrl()}team/${teamId}` : `${homeUrl()}team`;

export const todayGoalsUrl = () => `${homeUrl()}today`;

export const helpUrl = () => `${homeUrl()}help`;

export const recurringGoalsUrl = () => `${homeUrl()}recurring-goals`;

export const searchGoalsUrl = () => `${homeUrl()}search-goals`;

export const frameworksUrl = () => `${homeUrl()}frameworks`;
