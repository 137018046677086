import { CreateGoalDto, Goal } from '../features/goals/goal';

export const doCaging = (
  createGoalDto: CreateGoalDto,
  createGoal: (createGoalDto: any) => Promise<Goal>,
  refreshClients: () => void,
  setError: React.Dispatch<React.SetStateAction<Error | null>>,
) => {
  const randomNumber = Math.floor(Math.random() * 200) + 1;

  if (randomNumber === 69) {
    const newCreateGoalDto1: CreateGoalDto = {
      ...createGoalDto,
      description: '![Get caged](https://j.gifs.com/mGWb6R.gif)',
    };
    createGoal(newCreateGoalDto1).then(refreshClients).catch(setError);

    const newCreateGoalDto2: CreateGoalDto = {
      ...createGoalDto,
      description: '![Get caged](https://i.gifer.com/16tw.gif)',
    };
    createGoal(newCreateGoalDto2).then(refreshClients).catch(setError);

    const newCreateGoalDto3: CreateGoalDto = {
      ...createGoalDto,
      description: '![Get caged](https://i.imgur.com/Vvhvljv.gif)',
    };
    createGoal(newCreateGoalDto3).then(refreshClients).catch(setError);

    const newCreateGoalDto4: CreateGoalDto = {
      ...createGoalDto,
      description: '![Get caged](https://j.gifs.com/KYzPZN.gif)',
    };
    createGoal(newCreateGoalDto4).then(refreshClients).catch(setError);
  }
};
