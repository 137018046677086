import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Plus } from '../../../images/icons/plus-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton, IconButton } from '../../../shared/buttons/IconButton';
import { withModal, WithModalProps } from '../../../shared/higher-order-components/withModal';
import { alertAccent, successAccent, warningAccent } from '../../../styling/colours';
import { narrower } from '../../../styling/spacing';
import { GoalState } from '../../goal-states/goalState';
import { GoalStatesContext } from '../../goal-states/GoalStatesContextProvider';
import { Project } from '../../projects/project';
import { ProjectsContext } from '../../projects/ProjectsContextProvider';
import { User } from '../../users/user';
import { UsersContext } from '../../users/UsersContextProvider';
import { CreateUpdateRecurringGoalForm } from '../CreateUpdateRecurringGoalForm';
import { CreateRecurringGoalDto } from '../recurringGoal';
import {
  CreateRecurringGoalFormModel,
  CreateRecurringGoalFormValidator,
  toCreateRecurringGoalDto,
} from './createRecurringGoalFormModel';

type Props = {
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  onSubmit: (recurringGoal: CreateRecurringGoalDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateRecurringGoalFormValidator();

const CreateRecurringGoalModal = ({
  activeUsers,
  activeProjects,
  goalStates,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues: CreateRecurringGoalFormModel = useMemo(
    () => ({
      description: '',
      notes: '',
      startDate: '',
      daysFrequency: 0,
      monthsFrequency: 0,
      dayOfWeek: null,
      runsOnLastWeekdayOfMonth: false,
      runOnWeekends: false,
      nextOccurrence: null,
      goalState: goalStates.find((state) => state.stateCode === 'NEW') || null,
      goalGroupName: '',
      project: null,
      assignees: new Array<User>(),
    }),
    [goalStates],
  );

  return (
    <Formik<CreateRecurringGoalFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toCreateRecurringGoalDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, handleReset, submitForm, errors, values }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateRecurringGoalForm
            runsOnLastWeekdayOfMonth={values.runsOnLastWeekdayOfMonth}
            runOnWeekends={values.runOnWeekends}
            activeUsers={activeUsers}
            activeProjects={activeProjects}
            goalStates={goalStates}
          >
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton onClick={close} title="Close" hoverColour={alertAccent}>
              <Trash />
            </BorderedIconButton>
          </CreateUpdateRecurringGoalForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;

type CreateRecurringGoalButtonComponentProps = {
  onCreateRecurringGoal: (createRecurringGoal: CreateRecurringGoalDto) => Promise<void>;
};

type CreateRecurringGoalButtonProps = CreateRecurringGoalButtonComponentProps &
  WithModalProps<CreateRecurringGoalModalParams, 'createRecurringGoalModal'>;

const CreateRecurringGoalButtonComponent = ({
  createRecurringGoalModal,
  onCreateRecurringGoal,
}: CreateRecurringGoalButtonProps) => {
  const { activeUsers } = useContext(UsersContext);
  const { activeProjects } = useContext(ProjectsContext);
  const { goalStates } = useContext(GoalStatesContext);

  return (
    <ButtonContainer>
      <IconButton
        onClick={() => {
          return createRecurringGoalModal.open({
            activeUsers,
            activeProjects,
            goalStates,
            onSubmit: onCreateRecurringGoal,
          });
        }}
        title="Add New Recurring Goal"
        children={<Plus />}
      />
    </ButtonContainer>
  );
};

const withCreateRecurringGoalModal = withModal<
  CreateRecurringGoalButtonComponentProps,
  CreateRecurringGoalModalParams,
  'createRecurringGoalModal'
>(
  'createRecurringGoalModal',
  ({ params, closeModal, closeRequested, cancelCloseRequest }) => (
    <CreateRecurringGoalModal
      activeUsers={params.activeUsers}
      activeProjects={params.activeProjects}
      goalStates={params.goalStates}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

type CreateRecurringGoalModalParams = {
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  onSubmit: (createRecurringGoal: CreateRecurringGoalDto) => void;
};

const ButtonContainer = styled.div`
  padding-top: ${narrower};
`;

export const CreateRecurringGoalButton = withCreateRecurringGoalModal(
  CreateRecurringGoalButtonComponent,
);
