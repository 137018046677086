import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { translucentWhite } from '../styling/colours';
import { narrow } from '../styling/spacing';
import { regularTransitionDuration } from '../styling/transitions';
import { standardButtonHeight } from './buttons/Button';

export const IconLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: ${standardButtonHeight};
  width: ${standardButtonHeight};
  padding: ${narrow};
  border-radius: 50%;

  &:hover {
    background-color: ${translucentWhite};
  }

  transition: all ${regularTransitionDuration} ease;
`;
