import { filter, find, findIndex, keyBy, map, mapValues, sortBy } from 'lodash';
import React, { useContext, useRef } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { Redirect, RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { SuperAgentRequest } from 'superagent';
import { useGoalAssignmentsApi } from '../../hooks/api/useGoalAssignmentsApi';
import { useGoalsApi } from '../../hooks/api/useGoalsApi';
import { useKanbanApi } from '../../hooks/api/useKanbanApi';
import { useUsersApi } from '../../hooks/api/useUsersApi';
import { ErrorBox } from '../../shared/ErrorBox';
import { CentredLoadingSpinner } from '../../shared/LoadingSpinner';
import { PageContainer } from '../../shared/PageContainer';
import { narrow } from '../../styling/spacing';
import { assertNotNull } from '../../utils/assertNotNull';
import { FrameworksContext } from '../frameworks/FrameworksContextProvider';
import { UpdateGoalAssignmentDto } from '../goal-assignments/goalAssignment';
import { GoalStatesContext } from '../goal-states/GoalStatesContextProvider';
import { Goal, toUpdateGoalDto, UpdateGoalDto } from '../goals/goal';
import { ConfettiContext } from '../kanban/confetti/ConfettiContext';
import { Column, createOrdinalUpdatesDto, KanbanData, KanbanViewUrlParams } from '../kanban/kanban';
import { KanbanSettings } from '../kanban/kanban-settings/KanbanSettings';
import { UpdateKanbanViewOrdinalsDto } from '../kanban/kanban-view-ordinal/kanbanViewOrdinal';
import { KanbanBoard } from '../kanban/KanbanBoard';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { useStandup } from '../standup/useStandup';
import { TeamsContext } from '../teams/TeamsContextProvider';
import { CurrentUserProvider } from '../users/CurrentUserProvider';
import { UsersContext } from '../users/UsersContextProvider';
import {
  getNewActiveUsersOnUpdateUserHideDoneTasks,
  getNewMainPageGoalsStateAssigneeDoneUpdated,
  getNewMainPageGoalsStateCardMoved,
} from './mainPageStateHelpers';
import {
  getRetainerDaysRemaining,
  getViewName,
  isEverythingView,
  isProjectIdValidOrNull,
  isUserIdValidOrNull,
} from './mainPageUrlParamsHelpers';
import { NavMenu } from './nav-menu/NavMenu';

type MainPageProps = RouteComponentProps<KanbanViewUrlParams>;

export type MainPageGoalsState = {
  goals: Array<Goal>;
  columnsByGoalStateCode: { [goalStateCode: string]: Column };
};

export const MainPage = ({ history, match }: MainPageProps) => {
  const { setLastChangedGoalId } = useContext(ConfettiContext);

  const [mainPageGoalsState, setMainPageGoalsState] = useState<MainPageGoalsState>({
    goals: [],
    columnsByGoalStateCode: {},
  });

  const [loading, setLoading] = useState<boolean>(true);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const prevKanbanDataReqRef = useRef<SuperAgentRequest | null>(null);
  const prevKanbanTimeoutRef = useRef<number | null>(null);

  const [settingsVisible, setSettingsVisible] = useState<boolean>(false);

  const { activeUsers, refreshUsers } = useContext(UsersContext);
  const { activeProjects, refreshProjects } = useContext(ProjectsContext);
  const { isGoalStatesLoading, goalStates } = useContext(GoalStatesContext);
  const { refreshTeams } = useContext(TeamsContext);
  const { refreshFrameworks } = useContext(FrameworksContext);

  const { fetchKanbanData, updateKanbanViewOrdinals } = useKanbanApi();
  const { updateAssigneeDone } = useGoalAssignmentsApi();
  const { updateGoal } = useGoalsApi();
  const { updateUserHideDoneTasks } = useUsersApi();

  const goalsById = useMemo(
    () => keyBy(mainPageGoalsState.goals, (goal) => goal.goalId),
    [mainPageGoalsState.goals],
  );

  const columnOrder = useMemo(
    () => goalStates.map((goalState) => goalState.stateCode),
    [goalStates],
  );

  const updateStateFromKanbanDataResponse = (response: KanbanData) => {
    const goals = response.goals;
    const columnsByGoalStateCode = mapValues(
      keyBy(goalStates, (goalState) => goalState.stateCode),
      (goalState) => ({
        goalStateCode: goalState.stateCode,
        isCompleteState: goalState.isComplete,
        title: goalState.name,
        goalIds: map(
          filter(
            sortBy(response.goals, ['ordinal']),
            (goal) => goal.stateCode === goalState.stateCode,
          ),
          (goal) => goal.goalId,
        ),
      }),
    );

    setMainPageGoalsState({ goals, columnsByGoalStateCode });
    Promise.all([refreshUsers(), refreshProjects(), refreshTeams(), refreshFrameworks()]).catch(
      setError,
    );
  };

  const { params } = match;

  const fetchKanbanDataAfterTimeout = () => {
    const request = fetchKanbanData({ ...params });

    request
      .then(updateStateFromKanbanDataResponse)
      .catch((fetchKanbanDataError: Error) => {
        if (fetchKanbanDataError.message !== 'Aborted') {
          setError(fetchKanbanDataError);
        }
      })
      .finally(() => {
        setLoading(false);
        setFirstLoad(false);
      });
  };

  const getLatestKanbanData = () => {
    setLoading(true);
    setError(null);

    if (prevKanbanTimeoutRef.current != null) {
      clearTimeout(prevKanbanTimeoutRef.current);
    }
    if (prevKanbanDataReqRef.current != null) {
      prevKanbanDataReqRef.current.abort();
      prevKanbanDataReqRef.current = null;
    }

    prevKanbanTimeoutRef.current = setTimeout(fetchKanbanDataAfterTimeout, 50);
  };

  useEffect(() => {
    if (!isGoalStatesLoading) {
      getLatestKanbanData();
    }
  }, [match.params.projectId, match.params.userId, isGoalStatesLoading]);

  useEffect(() => {
    if (!firstLoad && !settingsVisible) {
      getLatestKanbanData();
    }
  }, [settingsVisible]);

  const {
    standupModeStatus,
    joinStandup,
    leaveStandup,
    notifyClientsOfGoalUpdate,
    notifyClientsOfNavigation,
  } = useStandup(
    (urlParams: KanbanViewUrlParams) => {
      fetchKanbanData({ ...urlParams })
        .then(updateStateFromKanbanDataResponse)
        .catch((fetchKanbanDataError: Error) => {
          if (fetchKanbanDataError.message !== 'Aborted') {
            setError(fetchKanbanDataError);
          }
        });
    },
    (newUrl) => history.push(newUrl),
  );

  const onKanbanCardDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;
    const { columnsByGoalStateCode } = mainPageGoalsState;

    if (!source || !destination) {
      return;
    }

    const sourceColumn: Column = columnsByGoalStateCode[source.droppableId];
    const destinationColumn: Column = columnsByGoalStateCode[destination.droppableId];

    const sourceCardIndex = source.index;
    const destinationCardIndex = destination.index;

    const goalId = Number(draggableId);
    setLastChangedGoalId(goalId);

    const userIdOrNan = Number(params.userId);
    const userOrNull = find(activeUsers, (u) => u.userId === userIdOrNan) || null;

    const movementOnSameColumn = sourceColumn === destinationColumn;
    const noChange = movementOnSameColumn && destinationCardIndex === sourceCardIndex;

    if (noChange) {
      return;
    }

    const newState = getNewMainPageGoalsStateCardMoved(
      mainPageGoalsState,
      goalsById,
      goalStates,
      movementOnSameColumn,
      sourceCardIndex,
      destinationCardIndex,
      sourceColumn,
      destinationColumn,
      goalId,
      userOrNull,
      standupModeStatus,
    );

    setMainPageGoalsState(newState);

    const ordinalUpdates: UpdateKanbanViewOrdinalsDto = createOrdinalUpdatesDto(
      newState.columnsByGoalStateCode[sourceColumn.goalStateCode],
      newState.columnsByGoalStateCode[destinationColumn.goalStateCode],
      movementOnSameColumn,
      params,
    );

    if (movementOnSameColumn) {
      updateKanbanViewOrdinals(ordinalUpdates)
        .then(() => notifyClientsOfGoalUpdate(goalId))
        .catch((updateKanbanViewOrdinalsError: Error) => setError(updateKanbanViewOrdinalsError));
    } else {
      const updateGoalDto: UpdateGoalDto = toUpdateGoalDto(
        assertNotNull(find(newState.goals, (goal) => goal.goalId === goalId)),
      );

      updateGoal(updateGoalDto)
        .then(() =>
          updateKanbanViewOrdinals(ordinalUpdates)
            .then(() => notifyClientsOfGoalUpdate(goalId))
            .catch((updateKanbanViewOrdinalsError: Error) =>
              setError(updateKanbanViewOrdinalsError),
            ),
        )
        .catch((updateGoalError: Error) => setError(updateGoalError));
    }
  };

  const onUpdateAssigneeDone = (updateGoalAssignmentDto: UpdateGoalAssignmentDto) => {
    setMainPageGoalsState(
      getNewMainPageGoalsStateAssigneeDoneUpdated(
        mainPageGoalsState,
        goalsById,
        updateGoalAssignmentDto,
      ),
    );

    setError(null);
    updateAssigneeDone(updateGoalAssignmentDto)
      .then(() => notifyClientsOfGoalUpdate(undefined))
      .catch((updateAssigneeDoneError: Error) => setError(updateAssigneeDoneError));
  };

  const toggleSettingsVisible = () => setSettingsVisible(!settingsVisible);

  const onToggleUserHideDoneTasks = () => {
    const userId = Number(params.userId);
    const userIndex = findIndex(activeUsers, (u) => u.userId === userId);

    const newIsHidingDoneTask = !activeUsers[userIndex].isHidingDoneTasks;

    setError(null);
    updateUserHideDoneTasks({
      userId,
      isHidingDoneTasks: newIsHidingDoneTask,
    })
      .then(refreshUsers)
      .catch((updateUserHideDoneTasksError: Error) => setError(updateUserHideDoneTasksError));
  };

  const user = find(activeUsers, (u) => u.userId === Number(params.userId));

  const personalDoneTasksHidden = !!user && user.isHidingDoneTasks;

  if (activeUsers.length > 0 && !isUserIdValidOrNull(params, activeUsers)) {
    return <Redirect to={`/404/invalid-user/` + params.userId} />;
  }
  if (activeProjects.length > 0 && !isProjectIdValidOrNull(params, activeProjects)) {
    return <Redirect to={`/404/invalid-project/` + params.projectId} />;
  }

  return (
    <CurrentUserProvider>
      {isEverythingView(params) ? (
        <NavMenu
          viewName={getViewName(params, activeProjects, activeUsers)}
          showPersonalDoneTasksToggle={!!params.userId}
          personalDoneTasksHidden={personalDoneTasksHidden}
          toggleUserHideDoneTasks={onToggleUserHideDoneTasks}
          toggleSettingsVisible={toggleSettingsVisible}
          refreshData={getLatestKanbanData}
          retainerDaysRemaining={null}
          standupModeStatus={standupModeStatus}
          joinStandup={joinStandup}
          leaveStandup={leaveStandup}
          notifyClientsOfNavigation={notifyClientsOfNavigation}
        />
      ) : (
        <NavMenu
          viewName={getViewName(params, activeProjects, activeUsers)}
          showPersonalDoneTasksToggle={!!params.userId}
          personalDoneTasksHidden={personalDoneTasksHidden}
          toggleUserHideDoneTasks={onToggleUserHideDoneTasks}
          toggleSettingsVisible={toggleSettingsVisible}
          refreshData={getLatestKanbanData}
          showNavButtons={true}
          urlParams={params}
          retainerDaysRemaining={getRetainerDaysRemaining(params, activeProjects)}
          standupModeStatus={standupModeStatus}
          joinStandup={joinStandup}
          leaveStandup={leaveStandup}
          notifyClientsOfNavigation={notifyClientsOfNavigation}
        />
      )}
      {settingsVisible && (
        <KanbanSettings urlParams={params} hideSettings={toggleSettingsVisible} />
      )}
      <PageContainer>
        {error && <StyledErrorBox error={error} />}
        {firstLoad ? (
          <CentredLoadingSpinner />
        ) : (
          <KanbanBoard
            urlParams={params}
            goalsById={goalsById}
            columnsById={mainPageGoalsState.columnsByGoalStateCode}
            columnOrder={columnOrder}
            personalDoneTasksHidden={
              personalDoneTasksHidden && standupModeStatus === 'Not Participating'
            }
            getLatestKanbanData={getLatestKanbanData}
            onKanbanCardDragEnd={onKanbanCardDragEnd}
            onUpdateAssigneeDone={onUpdateAssigneeDone}
            loading={loading}
            notifyClientsOfGoalUpdate={notifyClientsOfGoalUpdate}
          />
        )}
      </PageContainer>
    </CurrentUserProvider>
  );
};

const StyledErrorBox = styled(ErrorBox)`
  margin-bottom: ${narrow};
`;
