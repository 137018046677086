import { History } from 'history';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { KanbanSettings } from '../features/kanban/kanban-settings/KanbanSettings';
import { ReactComponent as Settings } from '../images/icons/bars-solid.svg';
import { ReactComponent as Left } from '../images/icons/chevron-left-solid.svg';
import { darkTurquoise, translucentWhite, turquoise, white } from '../styling/colours';
import { headerFont } from '../styling/fonts';
import {
  narrow,
  narrowPixels,
  navMenuHeight,
  navMenuHeightPixels,
  pageHeaderBorderWidth,
  pageHeaderBorderWidthPixels,
} from '../styling/spacing';
import { IconButton } from './buttons/IconButton';
import { Icon } from './Icon';

type Props = {
  viewName: string;
  history: History<unknown>;
};

export const PageHeader = ({ viewName, history }: Props) => {
  const [settingsVisible, setSettingsVisible] = useState(false);

  return (
    <>
      <Container>
        <SettingsButton
          onClick={() => setSettingsVisible(!settingsVisible)}
          colour={white}
          hoverColour={translucentWhite}
          data-cy="settings-button"
        >
          <Settings />
        </SettingsButton>
        <BackButton hoverColour={translucentWhite} onClick={history.goBack}>
          <Icon colour={white} size="small">
            <Left />
          </Icon>
        </BackButton>
        <ViewNameContainer>
          <ViewName>{viewName}</ViewName>
        </ViewNameContainer>
      </Container>
      {settingsVisible && <KanbanSettings hideSettings={() => setSettingsVisible(false)} />}
    </>
  );
};

const Container = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${navMenuHeight};
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;

  background-image: linear-gradient(to bottom right, ${turquoise}, ${darkTurquoise});
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
`;

const ViewNameContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
`;

const ViewName = styled.div`
  display: flex;
  align-items: center;
  font-size: ${headerFont};
  color: ${white};
  margin: 0 ${narrow};
`;

const BackButton = styled(IconButton)`
  margin: 0 0 0 ${navMenuHeightPixels + pageHeaderBorderWidthPixels + narrowPixels}px;
  position: absolute;
`;

const SettingsButton = styled(IconButton)`
  position: absolute;
  height: ${navMenuHeight};
  width: ${navMenuHeightPixels + pageHeaderBorderWidthPixels}px;
  border-radius: 0;
  border-right: solid ${pageHeaderBorderWidth} ${white};
`;
