export const tinyFont = '11px';
export const smallFont = '16px';
export const regularFont = '18px';
export const largeFont = '21px';
export const headerFont = '30px';

export const regularWeight = 500;
export const bold = 600;

export const regularFontFamily = '"Lato", sans-serif';
export const lightFontFamily = '"Lato Light", sans-serif';
