import React, { forwardRef, useEffect } from 'react';
import { TextAreaField, TextAreaFieldProps } from './TextAreaField';

type TextAreaFieldWithMarkdownShortcutsProps = TextAreaFieldProps & {
  setGoalDescription: (value: string) => void;
};

const decoratorsByShortcutKey: { [key: string]: string } = {
  b: '**',
  i: '*',
  u: '__',
  s: '~~',
  '`': '`',
};

export const TextAreaFieldWithMarkdownShortcuts = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldWithMarkdownShortcutsProps
>((props, ref) => {
  useEffect(() => {
    if (ref == null || !('current' in ref) || ref.current == null) {
      return;
    }

    const keydownHandler = (e: KeyboardEvent) =>
      executeGoalTextAreaShortcut(e, ref.current as HTMLTextAreaElement);

    ref.current.addEventListener('keydown', keydownHandler);

    return () => ref.current?.removeEventListener('keydown', keydownHandler);
  }, [ref]);

  const executeGoalTextAreaShortcut = (e: KeyboardEvent, textArea: HTMLTextAreaElement) => {
    if (e.ctrlKey && e.key in decoratorsByShortcutKey) {
      e.preventDefault();

      addOrRemoveDecoratorsFromTextAreaSelection(
        decoratorsByShortcutKey[e.key],
        textArea,
        props.setGoalDescription,
      );
    } else if (e.key === 'k' && e.ctrlKey) {
      e.preventDefault();

      addLinkToTextAreaSelection(textArea, props.setGoalDescription);
    }
  };

  return <TextAreaField {...props} ref={ref} />;
});

const addOrRemoveDecoratorsFromTextAreaSelection = (
  decorator: string,
  textArea: HTMLTextAreaElement,
  setGoalDescription: (value: string) => void,
): void => {
  const text = textArea.value;
  const selectionStart = textArea.selectionStart;
  const selectionEnd = textArea.selectionEnd;

  const isSelectedTextAlreadyDecorated =
    text.substring(selectionStart - decorator.length, selectionStart) === decorator &&
    text.substring(selectionEnd, selectionEnd + decorator.length) === decorator;

  if (isSelectedTextAlreadyDecorated) {
    textArea.selectionStart = selectionStart - decorator.length;
    textArea.selectionEnd = selectionEnd + decorator.length;

    replaceSelectionWithText(text.substring(selectionStart, selectionEnd));

    textArea.selectionStart = selectionStart - decorator.length;
    textArea.selectionEnd = selectionEnd - decorator.length;
  } else {
    replaceSelectionWithText(decorator + text.substring(selectionStart, selectionEnd) + decorator);

    textArea.selectionStart = selectionStart + decorator.length;
    textArea.selectionEnd = selectionEnd + decorator.length;
  }

  setGoalDescription(textArea.value);
};

const addLinkToTextAreaSelection = (
  textArea: HTMLTextAreaElement | null,
  setGoalDescription: (value: string) => void,
): void => {
  if (textArea === null) {
    return;
  }

  const selectionStart = textArea.selectionStart;
  const selectionEnd = textArea.selectionEnd;

  const selectedText = textArea.value.substring(selectionStart, selectionEnd);

  replaceSelectionWithText(`[${selectedText}]()`);

  textArea.selectionStart = selectionStart + selectedText.length + 3;
  textArea.selectionEnd = selectionStart + selectedText.length + 3;

  setGoalDescription(textArea.value);
};

const replaceSelectionWithText = (text: string) => {
  // We have to use the deprecated execCommand here because otherwise Ctrl + Z stops working

  // > "However, there is no replacement for the editing commands: unlike direct DOM manipulation,
  //   modifications performed by execCommand() preserve the undo buffer (edit history)."
  // Source: https://developer.mozilla.org/en-US/docs/Web/API/document/execCommand, 2077-03-01

  document.execCommand('insertText', false, text);
};
