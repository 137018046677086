import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../images/icons/check-solid.svg';
import { ReactComponent as Plus } from '../../images/icons/plus-solid.svg';
import { ReactComponent as Trash } from '../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../images/icons/undo-solid.svg';
import { BorderedIconButton, IconButton } from '../../shared/buttons/IconButton';
import { withModal, WithModalProps } from '../../shared/higher-order-components/withModal';
import { alertAccent, successAccent, warningAccent } from '../../styling/colours';
import { narrower } from '../../styling/spacing';
import { Project } from '../projects/project';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import {
  CreateFrameworkFormModel,
  CreateFrameworkFormValidator,
  toCreateFrameworkDto,
} from './createFrameworkFormModel';
import { CreateUpdateFrameworkForm } from './CreateUpdateFrameworkForm';
import { CreateFrameworkDto, FrameworkPageProject } from './framework';

type Props = {
  projects: Array<FrameworkPageProject>;
  onSubmit: (framework: CreateFrameworkDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateFrameworkFormValidator();

const CreateFrameworkModal = ({
  projects,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues: CreateFrameworkFormModel = useMemo(
    () => ({
      name: '',
      endOfLifeDate: '',
      projects: [],
    }),
    [],
  );

  const onFormSubmit = (formModel: CreateFrameworkFormModel) => {
    if (!isEqual(formModel, initialValues)) {
      onSubmit(toCreateFrameworkDto(formModel));
    }
    close();
  };

  return (
    <Formik<CreateFrameworkFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={onFormSubmit}
    >
      {({ isSubmitting, handleReset, submitForm, errors, values }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateFrameworkForm projects={projects}>
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton onClick={close} title="Close" hoverColour={alertAccent}>
              <Trash />
            </BorderedIconButton>
          </CreateUpdateFrameworkForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;

type CreateFrameworkButtonComponentProps = {
  onCreateFramework: (createFramework: CreateFrameworkDto) => Promise<void>;
};

type CreateFrameworkButtonProps = CreateFrameworkButtonComponentProps &
  WithModalProps<CreateFrameworkModalParams, 'createFrameworkModal'>;

const CreateFrameworkButtonComponent = ({
  createFrameworkModal,
  onCreateFramework,
}: CreateFrameworkButtonProps) => {
  const { activeProjects } = useContext(ProjectsContext);

  return (
    <ButtonContainer>
      <IconButton
        onClick={() => {
          return createFrameworkModal.open({
            projects: activeProjects,
            onSubmit: onCreateFramework,
          });
        }}
        title="Add New Framework"
        children={<Plus />}
      />
    </ButtonContainer>
  );
};

const withCreateFrameworkModal = withModal<
  CreateFrameworkButtonComponentProps,
  CreateFrameworkModalParams,
  'createFrameworkModal'
>(
  'createFrameworkModal',
  ({ params, closeModal, closeRequested, cancelCloseRequest }) => (
    <CreateFrameworkModal
      projects={params.projects}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

type CreateFrameworkModalParams = {
  projects: Array<Project>;
  onSubmit: (createFramework: CreateFrameworkDto) => void;
};

const ButtonContainer = styled.div`
  padding-top: ${narrower};
`;

export const CreateFrameworkButton = withCreateFrameworkModal(CreateFrameworkButtonComponent);
