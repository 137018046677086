import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as CloneAndComplete } from '../../images/icons/clone-and-complete-solid.svg';
import { ReactComponent as Clone } from '../../images/icons/clone-solid.svg';
import { ReactComponent as Trash } from '../../images/icons/trash-solid.svg';
import { ContextMenuButton, ContextMenuItemButton } from '../../shared/buttons/ContextMenuButton';
import {
  alertAccent,
  lightRed,
  red,
  successAccent,
  warningAccent,
  white,
} from '../../styling/colours';
import { narrow } from '../../styling/spacing';

type Props = {
  onDelete: () => void;
  onDuplicate: () => void;
  onCompleteAndDuplicate: () => void;
  isCardInLastColumn?: boolean;
};

export const KanbanCardContextMenuButton = ({
  onDelete,
  onDuplicate,
  onCompleteAndDuplicate,
  isCardInLastColumn,
}: Props) => (
  <ContextMenuButton
    menuDirection={!!isCardInLastColumn ? 'column' : 'row'}
    iconColour={red}
    iconContrastColour={white}
    backgroundHoverColour={lightRed}
  >
    {closeContextMenu => (
      <>
        <ContextMenuItemButton
          onClick={() => {
            onDuplicate();
            closeContextMenu();
          }}
          title="Duplicate Goal"
          hoverColour={warningAccent}
        >
          <Clone />
        </ContextMenuItemButton>
        <ContextMenuItemButton
          onClick={() => {
            onCompleteAndDuplicate();
            closeContextMenu();
          }}
          title="Complete Goal and Duplicate"
          hoverColour={successAccent}
        >
          <CloneAndComplete />
        </ContextMenuItemButton>
        <ContextMenuItemButton
          onClick={() => {
            onDelete();
            closeContextMenu();
          }}
          title="Delete Goal"
          hoverColour={alertAccent}
        >
          <Trash />
        </ContextMenuItemButton>
      </>
    )}
  </ContextMenuButton>
);
