import { Validator } from 'fluentvalidation-ts';
import { DateStamp } from '../../utils/dateStamp';
import { FrameworkPageProject, UpdateFrameworkDto } from './framework';

export type UpdateFrameworkFormModel = {
  name: string;
  endOfLifeDate: DateStamp;
  projects: Array<FrameworkPageProject>;
  frameworkId: number;
};

export class UpdateFrameworkFormValidator extends Validator<UpdateFrameworkFormModel> {
  constructor() {
    super();

    this.ruleFor('name').notEmpty().withMessage('Please add a name').maxLength(128);

    this.ruleFor('endOfLifeDate').notEmpty().withMessage('Please choose an end of life date');

    this.ruleFor('frameworkId')
      .notNull()
      .withMessage(
        'Framework ID has come though as null, uh-oh. Ask Jack if this ever happens lmao',
      );
  }
}

export const toUpdateFrameworkDto = (formModel: UpdateFrameworkFormModel): UpdateFrameworkDto => {
  return {
    name: formModel.name,
    endOfLifeDate: formModel.endOfLifeDate,
    projectIds: formModel.projects.map((project) => project.projectId),
    frameworkId: formModel.frameworkId,
  };
};
