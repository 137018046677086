export const pageHorizontalPadding = '20px';
export const pageVerticalPaddingPixels = 20;
export const pageVerticalPadding = `${pageVerticalPaddingPixels}px`;

export const navMenuHeightPixels = 80;
export const navMenuHeight = `${navMenuHeightPixels}px`;
export const standupMenuWidthPixels = 180;
export const standupMenuWidth = `${standupMenuWidthPixels}px`;
export const pageHeaderBorderWidthPixels = 1;
export const pageHeaderBorderWidth = `${pageHeaderBorderWidthPixels}px`;

export const tiny = '2px';
export const narrowerPixels = 5;
export const narrower = `${narrowerPixels}px`;
export const narrowPixels = 10;
export const narrow = `${narrowPixels}px`;
export const mediumNarrowPixels = 15;
export const mediumNarrow = `${mediumNarrowPixels}px`;
export const mediumPixels = 20;
export const medium = `${mediumPixels}px`;
export const widePixels = 30;
export const wide = `${widePixels}px`;
export const wider = '40px';
export const massive = '60px';

export const defaultBorderRadius = '10px';
export const smallBorderRadius = '5px';
