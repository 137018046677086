import { differenceInDays, distanceInWordsToNow } from 'date-fns';
import { Formik } from 'formik';
import { find, isEqual } from 'lodash';
import React, { useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { alertAccent, successAccent, warningAccent } from '../../../styling/colours';
import { GoalStatesContext } from '../../goal-states/GoalStatesContextProvider';
import { ProjectsContext } from '../../projects/ProjectsContextProvider';
import { UsersContext } from '../../users/UsersContextProvider';
import { CreateUpdateGoalModalForm } from '../CreateUpdateGoalForm';
import { Goal, UpdateGoalDto } from '../goal';
import {
  toUpdateGoalDto,
  UpdateGoalFormModel,
  UpdateGoalFormValidator,
} from './updateGoalFormModel';

type Props = {
  goal: Goal;
  onSubmit: (updateGoalDto: UpdateGoalDto) => void;
  onDelete: (goalId: number) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
  isViewOnly?: boolean;
};

const formValidator = new UpdateGoalFormValidator();

export const UpdateGoalModal = ({
  goal,
  onSubmit,
  onDelete,
  closeRequested,
  cancelCloseRequest,
  close,
  isViewOnly,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const { activeUsers } = useContext(UsersContext);
  const { activeProjects } = useContext(ProjectsContext);
  const { goalStates } = useContext(GoalStatesContext);

  const initialValues = React.useMemo(
    () => ({
      goalId: goal.goalId,
      description: goal.description,
      dueDate: goal.dueDate,
      goalState: find(goalStates, (goalState) => goalState.stateCode === goal.stateCode) || null,
      notes: goal.notes,
      goalGroupName: goal.goalGroupName,
      project:
        find(activeProjects, (project) => project.projectId === goal.project.projectId) || null,
      assignees: goal.assignees,
    }),
    [goal, goalStates, activeProjects],
  );

  return (
    <Formik<UpdateGoalFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toUpdateGoalDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, handleReset, submitForm, errors, setFieldValue }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        const timeString = (date: string): string =>
          differenceInDays(new Date(), date) === 0
            ? 'today'
            : differenceInDays(new Date(), date) === 1
            ? 'yesterday'
            : distanceInWordsToNow(date) + ' ago';

        return (
          <CreateUpdateGoalModalForm
            goalId={goal.goalId}
            activeUsers={activeUsers}
            activeProjects={activeProjects}
            goalStates={goalStates}
            isViewOnly={!!isViewOnly}
            project={initialValues.project}
            setGoalDescription={(value: string) => setFieldValue('description', value)}
          >
            <InfoBox>
              <div>Created {timeString(goal.creationDate)}</div>
              {goal.lastUpdatedDate && <div>Last Updated {timeString(goal.lastUpdatedDate)}</div>}
            </InfoBox>

            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting || !!isViewOnly}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton
              onClick={() => onDelete(goal.goalId)}
              disabled={isSubmitting}
              title="Delete Goal"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateGoalModalForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;

const InfoBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  position: relative;
  top: -15px;
  margin-bottom: -15px;
  width: 600px;
  text-align: left;
`;
