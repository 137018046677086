import * as React from 'react';
import { Styles } from 'react-select/lib/styles';
import { greyBorder } from '../../../styling/colours';
import { smallBorderRadius } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';

export const defaultSelectStyles: Styles = {
  control: (styles: React.CSSProperties, { isFocused }) => ({
    ...selectControlStyles(styles, isFocused),
  }),
  multiValue: (styles: React.CSSProperties) => ({
    ...selectMultiValueStyles(styles),
  }),
};

export const selectControlStyles = (styles: React.CSSProperties, isFocused: boolean) => ({
  ...styles,
  minHeight: '40px',
  border: isFocused ? `1px solid ${greyBorder}` : '1px solid transparent',
  boxShadow: 'none',
  '&:hover': {
    borderColor: isFocused ? `1px solid ${greyBorder}` : '1px solid transparent',
  },
  transition: `all ${regularTransitionDuration} ease`,
});

export const selectMultiValueStyles = (styles: React.CSSProperties) => ({
  ...styles,
  borderRadius: smallBorderRadius,
});
