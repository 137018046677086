import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { GlobalStylesContext } from '../../../GlobalStylesProvider';
import { useKanbanApi } from '../../../hooks/api/useKanbanApi';
import { useProjectsApi } from '../../../hooks/api/useProjectsApi';
import { useTeamsApi } from '../../../hooks/api/useTeamsApi';
import { useUsersApi } from '../../../hooks/api/useUsersApi';
import { ReactComponent as Cross } from '../../../images/icons/times-solid.svg';
import { IconButton } from '../../../shared/buttons/IconButton';
import { ErrorBox } from '../../../shared/ErrorBox';
import { CentredLoadingSpinner } from '../../../shared/LoadingSpinner';
import { darkTurquoise, translucentWhite, turquoise, white } from '../../../styling/colours';
import { massive, mediumPixels, narrow, navMenuHeightPixels, wide } from '../../../styling/spacing';
import { CreateProjectDto, UpdateProjectDto } from '../../projects/project';
import { ProjectsContext } from '../../projects/ProjectsContextProvider';
import { CreateTeamDto, Team, UpdateTeamDto } from '../../teams/team';
import { TeamsContext } from '../../teams/TeamsContextProvider';
import { CreateUserDto, UpdateUserDto } from '../../users/user';
import { UsersContext } from '../../users/UsersContextProvider';
import { KanbanViewOptions, KanbanViewUrlParams } from '../kanban';
import { CallButton } from './CallButton';
import { Pages } from './Pages';
import { ProjectViewOptions } from './ProjectViewOptions';
import { ShowInactive } from './ShowInactive';
import { TeamNoViewOptions } from './TeamNoViewOptions';
import { UserViewOptions } from './UserViewOptions';

type KanbanSettingsProps = {
  urlParams?: KanbanViewUrlParams;
  hideSettings: () => void;
};

export const KanbanSettings = ({ urlParams, hideSettings }: KanbanSettingsProps) => {
  const { createProject, updateProject } = useProjectsApi();
  const { createTeam, updateTeam } = useTeamsApi();
  const { createUser, updateUser } = useUsersApi();
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const { users, activeUsers, refreshUsers } = useContext(UsersContext);
  const { projects, activeProjects, refreshProjects } = useContext(ProjectsContext);
  const { teams, activeTeams, refreshTeams } = useContext(TeamsContext);

  const { setIsKanbanSettingsVisible } = useContext(GlobalStylesContext);

  const getKanbanViewOptions = () => {
    setLoading(true);
    setError(null);

    Promise.all([refreshUsers(), refreshProjects(), refreshTeams()])
      .catch(setError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    getKanbanViewOptions();
  }, [showInactive]);

  useEffect(() => {
    setIsKanbanSettingsVisible(true);
  }, [setIsKanbanSettingsVisible]);

  const onShowInactiveChanged = (showInactive: boolean) => {
    setShowInactive(showInactive);
  };

  const onCreateProject = (createProjectDto: CreateProjectDto) => {
    setError(null);
    createProject(createProjectDto).then(() => {
      getKanbanViewOptions();
    });
  };

  const onUpdateProject = (updateProjectDto: UpdateProjectDto) => {
    setError(null);
    updateProject(updateProjectDto).then(() => {
      getKanbanViewOptions();
    });
  };

  const onCreateUser = (createUserDro: CreateUserDto) => {
    setError(null);
    createUser(createUserDro).then(() => {
      getKanbanViewOptions();
    });
  };

  const onUpdateUser = (updateUserDto: UpdateUserDto) => {
    setError(null);
    updateUser(updateUserDto).then(() => {
      getKanbanViewOptions();
    });
  };

  const onCreateTeam = (createTeamDto: CreateTeamDto) => {
    setError(null);
    createTeam(createTeamDto).then(() => {
      getKanbanViewOptions();
    });
  };

  const onUpdateTeam = (updateTeamDto: UpdateTeamDto) => {
    setError(null);
    updateTeam(updateTeamDto).then(() => getKanbanViewOptions());
  };

  const onHideSettings = () => {
    setIsKanbanSettingsVisible(false);

    hideSettings();
  };

  return (
    <Container>
      <ExitButton colour={white} hoverColour={translucentWhite} onClick={onHideSettings}>
        <Cross />
      </ExitButton>
      <CallButton />
      <ViewOptionsAndPagesContainer>
        <ViewOptionsContainer>
          <ShowInactive onShowInactiveChanged={onShowInactiveChanged} showInactive={showInactive} />
          {error ? (
            <StyledErrorBox error={error} />
          ) : loading ? (
            <CentredLoadingSpinner colour={white} />
          ) : (
            <ViewOptionsColumns>
              <ProjectViewOptions
                urlParams={urlParams}
                projects={showInactive ? projects : activeProjects}
                teams={showInactive ? teams : activeTeams}
                hideSettings={onHideSettings}
                onCreateProject={onCreateProject}
                onUpdateProject={onUpdateProject}
              />
              <UserViewOptions
                urlParams={urlParams}
                users={showInactive ? users : activeUsers}
                teams={showInactive ? teams : activeTeams}
                hideSettings={onHideSettings}
                onCreateUser={onCreateUser}
                onUpdateUser={onUpdateUser}
              />
              <TeamNoViewOptions
                urlParams={urlParams}
                teams={showInactive ? teams : activeTeams}
                hideSettings={onHideSettings}
                onCreateTeam={onCreateTeam}
                onUpdateTeam={onUpdateTeam}
              />
            </ViewOptionsColumns>
          )}
        </ViewOptionsContainer>
        <PagesColumn>
          <Pages hideSettings={onHideSettings} />
        </PagesColumn>
      </ViewOptionsAndPagesContainer>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 30;
  padding: 0 ${wide} ${wide} ${wide};
  background-image: linear-gradient(to bottom right, ${turquoise}, ${darkTurquoise});
  overflow: auto;
`;

const ExitButton = styled(IconButton)`
  position: absolute;
  top: ${mediumPixels / 2}px;
  left: ${mediumPixels / 2}px;
  height: ${navMenuHeightPixels - mediumPixels}px;
  width: ${navMenuHeightPixels - mediumPixels}px;
`;

const StyledErrorBox = styled(ErrorBox)`
  margin-top: ${narrow};
`;

const ViewOptionsAndPagesContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ViewOptionsContainer = styled.div`
  width: 80%;
  margin-right: ${narrow};
`;

const ViewOptionsColumns = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

export const OptionsColumn = styled.div`
  width: 50%;
  max-width: 400px;
  display: flex;
  flex-direction: column;

  margin: ${narrow};
  &:last-child {
    margin-right: 0;
  }
  &:first-child {
    margin-left: 0;
  }
`;

const PagesColumn = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: ${navMenuHeightPixels}px;
  border-left: 2px solid ${translucentWhite};
  padding: ${narrow} 0 0 ${narrow};
`;

export const OptionsColumnHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: ${narrow};
  margin-left: 40px;
  margin-bottom: ${narrow};

  font-size: ${massive};
  color: ${white};
`;

export const OptionsColumnHeaderButton = styled(IconButton)`
  height: 45px;
  width: 45px;
  margin-left: ${narrow};
`;
