import DOMPurify from 'dompurify';
import React from 'react';

type MarkdownTextBoxProps = {
  text: string;
};

const MarkdownTextBox = ({ text }: MarkdownTextBoxProps) => {
  // noinspection HtmlUnknownTarget
  const markdownText = DOMPurify.sanitize(
    text
      .replace(/&/gim, '&#x26;') // Sanitize ampersand
      .replace(/</gim, '&#x3c;') // Sanitize less than
      .replace(/>/gim, '&#x3e;') // Sanitize more than
      .replace(/\\#/gim, '&#x23;') // Escape hashtags
      .replace(/\\\*/gim, '&#x2a;') // Escape asterisks
      .replace(/\\`/gim, '&#96;') // Escape backticks
      .replace(/\\🌈/gim, '&#x1F308;') // Escape rainbow emoji
      .replace(/\\🔥/gim, '&#x1F525;') // Escape fire emoji
      .replace(/^### (.+$)/gim, '<h3>$1</h3>') // h3 tag
      .replace(/^## (.+$)/gim, '<h2>$1</h2>') // h2 tag
      .replace(/^# (.+$)/gim, '<h1>$1</h1>') // h1 tag
      .replace(/\*\*(.+?)\*\*/gim, '<b>$1</b>') // bold text
      .replace(/\*(.+?)\*/gim, '<i>$1</i>') // italic text
      .replace(/`(.+?)`/gim, '<code>$1</code>') // code block
      .replace(/==(.+?)==/gim, '<mark>$1</mark>') // highlighted text
      .replace(/__(.+?)__/gim, '<u>$1</u>') // underlined text
      .replace(/~~(.+?)~~/gim, '<s>$1</s>') // strikethrough
      .replace(/\|\|(.+?)\|\|/gim, '<span class="spoiler">$1</span>') // spoiler
      .replace(/🔥(.+?)🔥/gim, '<span class="burning_text_animated">$1</span>') // Text on fire (excluded from reference for a good reason)
      .replace(/🌈(.+?)🌈/gim, '<span class="rainbow_text_animated">$1</span>') // Taste the rainbow! (excluded from reference for an even better reason)
      .replace(/!\[(.*?)]\((.+?)\)/gim, '<img src="$2" alt="$1" style="width:100%"/>') // Images / GIFs
      .replace(/\[(.+?)]\((.*?)\)/gim, '<a href="$2" target="_blank">$1</a>'), // Links
  );

  return <span dangerouslySetInnerHTML={{ __html: markdownText }} />;
};

export default MarkdownTextBox;
