import styled from 'styled-components/macro';
import { Icon } from '../../../shared/Icon';
import { lessTranslucentWhite, translucentWhite } from '../../../styling/colours';
import { defaultBorderRadius, mediumNarrow, narrow, narrower } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';

export type KanbanOptionProps = (
  | { onEditClick: () => void; removeEdit?: false }
  | { onEditClick?: undefined; removeEdit: true }) & {
  disabled?: boolean;
  text: string;
  className?: string;
};

export const OptionNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const StyledIcon = styled(Icon)`
  transition: all ${regularTransitionDuration} ease;
  width: 20px;
  height: 20px;
`;

export const OptionName = styled.div`
  margin-left: ${mediumNarrow};
`;

export const TeamGroupContainer = styled.fieldset`
  border: 2px solid ${translucentWhite};
  border-radius: ${defaultBorderRadius};
  margin: ${narrower};
  padding: ${narrow};
`;

export const TeamGroupTitle = styled.legend`
  color: ${lessTranslucentWhite};
  padding: 0 ${narrow};
`;
