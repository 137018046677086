import { Formik } from 'formik';
import { find, isEqual } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { alertAccent, successAccent, warningAccent } from '../../../styling/colours';
import { GoalState } from '../../goal-states/goalState';
import { Project } from '../../projects/project';
import { User } from '../../users/user';
import { CreateUpdateRecurringGoalForm } from '../CreateUpdateRecurringGoalForm';
import { RecurringGoal, UpdateRecurringGoalDto } from '../recurringGoal';
import {
  toUpdateRecurringGoalDto,
  UpdateRecurringGoalFormModel,
  UpdateRecurringGoalFormValidator,
} from './updateRecurringGoalFormModel';

type Props = {
  recurringGoal: RecurringGoal;
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  onSubmit: (recurringGoal: UpdateRecurringGoalDto) => void;
  onDelete: (recurringGoalId: number | null) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new UpdateRecurringGoalFormValidator();

export const UpdateRecurringGoalModal = ({
  recurringGoal,
  activeUsers,
  activeProjects,
  goalStates,
  onSubmit,
  onDelete,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues: UpdateRecurringGoalFormModel = useMemo(
    () => ({
      recurringGoalId: recurringGoal.recurringGoalId,
      description: recurringGoal.description,
      notes: recurringGoal.notes,
      startDate: recurringGoal.startDate,
      daysFrequency: recurringGoal.daysFrequency,
      monthsFrequency: recurringGoal.monthsFrequency,
      dayOfWeek: recurringGoal.dayOfWeek,
      runsOnLastWeekdayOfMonth: !!recurringGoal.runsOnLastWeekdayOfMonth,
      runOnWeekends: !!recurringGoal.runOnWeekends,
      nextOccurrence: recurringGoal.nextOccurrence,
      goalState:
        find(goalStates, goalState => goalState.stateCode === recurringGoal.stateCode) || null,
      goalGroupName: recurringGoal.goalGroupName,
      project: recurringGoal.project,
      assignees: recurringGoal.assignees,
    }),
    [recurringGoal, goalStates],
  );

  return (
    <Formik<UpdateRecurringGoalFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={formModel => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toUpdateRecurringGoalDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, handleReset, submitForm, errors, values }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateRecurringGoalForm
            recurringGoalId={recurringGoal.recurringGoalId}
            runsOnLastWeekdayOfMonth={values.runsOnLastWeekdayOfMonth}
            runOnWeekends={values.runOnWeekends}
            activeUsers={activeUsers}
            activeProjects={activeProjects}
            goalStates={goalStates}
          >
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>

            <BorderedIconButton
              onClick={() => onDelete(recurringGoal.recurringGoalId)}
              title="Delete Recurring Goal"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateRecurringGoalForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
