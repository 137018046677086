import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { createRef, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { successAccent, warningAccent } from '../../../styling/colours';
import { FrameworksContext } from '../../frameworks/FrameworksContextProvider';
import { TeamsContext } from '../../teams/TeamsContextProvider';
import { UsersContext } from '../../users/UsersContextProvider';
import { CreateUpdateProjectForm } from '../CreateUpdateProjectForm';
import { Project, UpdateProjectDto } from '../project';
import {
  toUpdateProjectDto,
  UpdateProjectFormModel,
  UpdateProjectFormValidator,
} from './updateProjectFormModel';

type Props = {
  project: Project;
  onSubmit: (updateProjectDto: UpdateProjectDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new UpdateProjectFormValidator();

export const UpdateProjectModal = ({
  project,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  const nameInputRef = createRef<HTMLInputElement>();

  const { activeUsers } = useContext(UsersContext);
  const { activeTeams } = useContext(TeamsContext);
  const { frameworks } = useContext(FrameworksContext);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues = {
    projectId: project.projectId,
    name: project.name,
    colour: project.colour,
    externalTicketUrlTemplate: project.externalTicketUrlTemplate,
    jiraKeys: project.jiraKeys,
    isActive: project.isActive,
    teamMailingListEmail: project.teamMailingListEmail,
    additionalStatusEmailReceivers: project.additionalStatusEmailReceivers,
    spreadsheetRetainerGuid: project.spreadsheetRetainerGuid,
    teams: project.teams,
    frameworks: project.frameworks,
  };

  return (
    <Formik<UpdateProjectFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toUpdateProjectDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateProjectForm
            projectId={project.projectId}
            activeUsers={activeUsers}
            activeTeams={activeTeams}
            frameworks={frameworks}
            nameInputRef={nameInputRef}
            formTitle="Update project"
          >
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
          </CreateUpdateProjectForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
