import React, { useContext } from 'react';
import { AuthenticationState, AzureAD, IAzureADFunctionProps } from 'react-aad-msal';
import { FrameworksContextProvider } from './features/frameworks/FrameworksContextProvider';
import { GoalStatesContextProvider } from './features/goal-states/GoalStatesContextProvider';
import { ConfettiProvider } from './features/kanban/confetti/ConfettiContext';
import { ProjectsContextProvider } from './features/projects/ProjectsContextProvider';
import { TeamsContextProvider } from './features/teams/TeamsContextProvider';
import { UsersContextProvider } from './features/users/UsersContextProvider';
import { GlobalProvider } from './GlobalProvider';
import { GlobalStylesProvider } from './GlobalStylesProvider';
import { PortalRoots } from './PortalRoots';
import { AuthContext } from './shared/auth/AuthenticationContext';
import { LoadingOverlay } from './shared/LoadingOverlay';
import { Routes } from './shared/Routes';
import { GlobalStyles } from './styling/GlobalStyles';

export const App = () => {
  const { authProvider } = useContext(AuthContext);

  return (
    <React.StrictMode>
      <GlobalStylesProvider>
        <AzureAD provider={authProvider} forceLogin={true}>
          {({ authenticationState }: IAzureADFunctionProps) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
                authProvider.setAuthenticationParameters({
                  ...authProvider.getAuthenticationParameters(),
                  sid: authProvider.getAccountInfo()?.account.sid,
                });
                return (
                  <>
                    <GlobalProvider>
                      <ConfettiProvider>
                        <UsersContextProvider>
                          <ProjectsContextProvider>
                            <GoalStatesContextProvider>
                              <TeamsContextProvider>
                                <FrameworksContextProvider>
                                  <Routes />
                                </FrameworksContextProvider>
                              </TeamsContextProvider>
                            </GoalStatesContextProvider>
                          </ProjectsContextProvider>
                        </UsersContextProvider>
                      </ConfettiProvider>
                    </GlobalProvider>
                    <PortalRoots />
                  </>
                );
              case AuthenticationState.Unauthenticated:
                if (authProvider.getError()?.errorCode === 'access_denied') {
                  authProvider.login();
                }
                return <LoadingOverlay loading={true} children={null} />;
              case AuthenticationState.InProgress:
                return <LoadingOverlay loading={true} children={null} />;
            }
          }}
        </AzureAD>
      </GlobalStylesProvider>
    </React.StrictMode>
  );
};
