import { Formik } from 'formik';
import { isEqual } from 'lodash';
import React, { createRef, useContext, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import {
  alertAccent,
  getRandomColour,
  successAccent,
  warningAccent,
} from '../../../styling/colours';
import { FrameworksContext } from '../../frameworks/FrameworksContextProvider';
import { TeamsContext } from '../../teams/TeamsContextProvider';
import { UsersContext } from '../../users/UsersContextProvider';
import { CreateUpdateProjectForm } from '../CreateUpdateProjectForm';
import { CreateProjectDto } from '../project';
import {
  CreateProjectFormModel,
  CreateProjectFormValidator,
  toCreateProjectDto,
} from './createProjectFormModel';

type Props = {
  onSubmit: (createProjectDto: CreateProjectDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateProjectFormValidator();

export const CreateProjectModal = ({
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  const nameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const nameInput = nameInputRef.current;
    if (nameInput !== null) {
      nameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { activeUsers } = useContext(UsersContext);
  const { activeTeams } = useContext(TeamsContext);
  const { frameworks } = useContext(FrameworksContext);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues: CreateProjectFormModel = {
    name: '',
    colour: getRandomColour(true),
    externalTicketUrlTemplate: '',
    jiraKeys: '',
    isActive: true,
    teamMailingListEmail: '',
    additionalStatusEmailReceivers: [],
    spreadsheetRetainerGuid: '',
    teams: [],
    frameworks: [],
  };

  return (
    <Formik<CreateProjectFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        if (!isEqual(formModel, initialValues)) {
          onSubmit(toCreateProjectDto(formModel));
        }
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateProjectForm
            activeUsers={activeUsers}
            activeTeams={activeTeams}
            frameworks={frameworks}
            nameInputRef={nameInputRef}
            formTitle="Create new project"
          >
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton
              onClick={close}
              disabled={isSubmitting}
              title="Cancel Input"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateProjectForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
