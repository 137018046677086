import { ApiRequest, fetchJson, from, withJsonBody } from '../../../utils/api';
import { KanbanViewUrlParams } from '../kanban';

export type UpdateKanbanViewOrdinalDto = {
  ordinal: number;
  goalId: number;
  projectId: number;
  userId: number;
};

export type UpdateKanbanViewOrdinalsDto = {
  updates: Array<UpdateKanbanViewOrdinalDto>;
};

export const toUpdateKanbanViewOrdinalDto = (
  viewParams: KanbanViewUrlParams,
  goalId: number,
  ordinal: number,
): UpdateKanbanViewOrdinalDto => ({
  projectId: Number(viewParams.projectId),
  userId: Number(viewParams.userId),
  goalId,
  ordinal,
});
