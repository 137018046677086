import React from 'react';
import { Route, Switch } from 'react-router';
import { FourOhFour } from '../features/FourOhFourPage';
import { FrameworksPage } from '../features/frameworks/FrameworksPage';
import { HelpPage } from '../features/HelpPage';
import { MainPage } from '../features/main-page/MainPage';
import { RecurringGoalsPage } from '../features/recurring-goals/RecurringGoalsPage';
import { SearchPage } from '../features/search-goals/SearchGoalsPage';
import { TodayGoalsPage } from '../features/TodayGoalsPage';
import {
  frameworksUrl,
  helpUrl,
  homeUrl,
  recurringGoalsUrl,
  searchGoalsUrl,
  todayGoalsUrl,
  viewByProjectUrl,
  viewByUserUrl,
} from '../urls';

export const Routes = () => (
  <Switch>
    <Route exact={true} path={homeUrl()} render={(routerProps) => <MainPage {...routerProps} />} />
    <Route
      path={`${viewByProjectUrl()}/:projectId`}
      render={(routerProps) => <MainPage {...routerProps} />}
    />
    <Route
      path={`${viewByUserUrl()}/:userId`}
      render={(routerProps) => <MainPage {...routerProps} />}
    />
    <Route path={todayGoalsUrl()} render={(routerProps) => <TodayGoalsPage {...routerProps} />} />
    <Route path={helpUrl()} render={(routerProps) => <HelpPage {...routerProps} />} />
    <Route
      path={recurringGoalsUrl()}
      render={(routerProps) => <RecurringGoalsPage {...routerProps} />}
    />
    <Route path={searchGoalsUrl()} render={(routerProps) => <SearchPage {...routerProps} />} />
    <Route path={frameworksUrl()} render={(routerProps) => <FrameworksPage {...routerProps} />} />
    <Route
      path={`/404/invalid-user/:userId`}
      render={() => <FourOhFour errorMessage="This user doesn't exist!" />}
    />
    <Route
      path={`/404/invalid-project/:projectId`}
      render={() => <FourOhFour errorMessage="This project doesn't exist!" />}
    />
    <Route render={() => <FourOhFour errorMessage="This page doesn't exist!" />} />
  </Switch>
);
