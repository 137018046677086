import { Formik } from 'formik';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Switch, { ReactSwitchProps } from 'react-switch';
import styled from 'styled-components/macro';
import { GlobalContext } from '../../../GlobalProvider';
import { ReactComponent as Settings } from '../../../images/icons/bars-solid.svg';
import { ReactComponent as Left } from '../../../images/icons/chevron-left-solid.svg';
import { ReactComponent as Right } from '../../../images/icons/chevron-right-solid.svg';
import { ReactComponent as FryingPanActive } from '../../../images/icons/frying-pan-active.svg';
import { ReactComponent as FryingPan } from '../../../images/icons/frying-pan.svg';
import { standardButtonHeight } from '../../../shared/buttons/Button';
import { IconButton } from '../../../shared/buttons/IconButton';
import { SelectField } from '../../../shared/form/select-fields/SelectField';
import { Icon } from '../../../shared/Icon';
import { IconLink } from '../../../shared/IconLink';
import { LoadingOverlay } from '../../../shared/LoadingOverlay';
import {
  darkTurquoise,
  green,
  translucentWhite,
  turquoise,
  warningText,
  white,
  yellow,
} from '../../../styling/colours';
import { headerFont } from '../../../styling/fonts';
import {
  medium,
  narrow,
  navMenuHeight,
  navMenuHeightPixels,
  pageHeaderBorderWidth,
} from '../../../styling/spacing';
import { HelpModal } from '../../HelpModal';
import { ConfettiContext } from '../../kanban/confetti/ConfettiContext';
import { KanbanViewUrlParams } from '../../kanban/kanban';
import { ProjectsContext } from '../../projects/ProjectsContextProvider';
import { StandupModeStatus } from '../../standup/useStandup';
import { TeamsContext } from '../../teams/TeamsContextProvider';
import { CurrentUserContext } from '../../users/CurrentUserProvider';
import { User } from '../../users/user';
import { UsersContext } from '../../users/UsersContextProvider';
import {
  mapUsersToSelectOptions,
  mapUserToSelectOption,
  userSelectStyles,
} from '../../users/userSelectUtils';
import {
  getFirstProjectUrl,
  getFirstUserUrl,
  getNextUrl,
  getPreviousUrl,
  getRandomProjectUrl,
  getRandomUserUrl,
} from '../mainPageUrlParamsHelpers';
import { SelectUserFormModel } from './SelectUserFormModel';
import { StandupMenuButton } from './StandupMenuButton';
import { UserViewContextMenuButton } from './UserViewContextMenuButton';

type NavMenuProps = (
  | { showNavButtons?: false; urlParams?: undefined }
  | { showNavButtons: true; urlParams: KanbanViewUrlParams }
) & {
  viewName: string;
  showPersonalDoneTasksToggle: boolean;
  personalDoneTasksHidden: boolean;
  toggleUserHideDoneTasks: () => void;
  toggleSettingsVisible: () => void;
  refreshData: () => void;
  retainerDaysRemaining: number | null;
  standupModeStatus: StandupModeStatus;
  joinStandup: (teamIds: Array<number>, isPersonalDevelopmentStandup: boolean) => void;
  leaveStandup: () => void;
  notifyClientsOfNavigation: (newUrl: string) => void;
} & RouteComponentProps;

const selectUserMaxMenuHeightInPixels = 560;

const NavMenuComponent = ({
  showNavButtons,
  urlParams,
  viewName,
  showPersonalDoneTasksToggle,
  personalDoneTasksHidden,
  toggleUserHideDoneTasks,
  toggleSettingsVisible,
  refreshData,
  history,
  retainerDaysRemaining,
  standupModeStatus,
  joinStandup,
  leaveStandup,
  notifyClientsOfNavigation,
}: NavMenuProps) => {
  const { activeUsers } = useContext(UsersContext);
  const { activeProjects } = useContext(ProjectsContext);
  const { activeTeams } = useContext(TeamsContext);
  const { setLastChangedGoalId } = useContext(ConfettiContext);
  const { currentUser, setCurrentUser } = useContext(CurrentUserContext);
  const { onlyShowCurrentTeamTasks, setOnlyShowCurrentTeamTasks } = useContext(GlobalContext);

  const [standupTeamIds, setStandupTeamIds] = useState<Array<number>>([]);
  const [isPersonalDevelopmentStandup, setPersonalDevelopmentStandup] = useState<boolean>(false);
  const [helpVisible, setHelpVisible] = useState<boolean>(false);

  const firstProjectUrl = useMemo(() => getFirstProjectUrl(activeProjects), [activeProjects]);
  const firstUserUrl = useMemo(() => getFirstUserUrl(activeUsers), [activeUsers]);
  const nextUrl = useMemo(
    () =>
      urlParams &&
      getNextUrl(
        urlParams,
        activeProjects,
        activeUsers,
        standupModeStatus,
        standupTeamIds,
        isPersonalDevelopmentStandup,
      ),
    [
      urlParams,
      activeProjects,
      activeUsers,
      standupModeStatus,
      standupTeamIds,
      isPersonalDevelopmentStandup,
    ],
  );
  const previousUrl = useMemo(
    () =>
      urlParams &&
      getPreviousUrl(
        urlParams,
        activeProjects,
        activeUsers,
        standupModeStatus,
        standupTeamIds,
        isPersonalDevelopmentStandup,
      ),
    [
      urlParams,
      activeProjects,
      activeUsers,
      standupModeStatus,
      standupTeamIds,
      isPersonalDevelopmentStandup,
    ],
  );

  const toggleHelpVisible = () => setHelpVisible(!helpVisible);

  const randomNumber = Math.floor(Math.random() * 200) + 1;

  const handleKeydown = (event: Event) => {
    const keyboardEvent = event as unknown as React.KeyboardEvent;

    if (String(keyboardEvent.target) === '[object HTMLBodyElement]') {
      // Use 'keyCode' instead of 'key' because keyCode is not affected by ctrl and alt keys.
      // List of keyCodes can be found at https://css-tricks.com/snippets/javascript/javascript-keycodes/
      switch (keyboardEvent.keyCode) {
        case 37: // ArrowLeft
          if (previousUrl) {
            keyboardEvent.preventDefault();
            notifyClientsOfNavigation(previousUrl);
            history.push(previousUrl);
          }
          setLastChangedGoalId(0);
          return;
        case 39: // ArrowRight
          if (nextUrl) {
            keyboardEvent.preventDefault();
            notifyClientsOfNavigation(nextUrl);
            history.push(nextUrl);
            if (randomNumber === 69) {
              window.open('https://www.youtube.com/watch?v=2qBlE2-WL60');
            }
          }
          setLastChangedGoalId(0);
          return;
        case 67: // c (for Change to person/project)
          if (nextUrl) {
            keyboardEvent.preventDefault();
            if (urlParams && urlParams.projectId) {
              const newUserUrl = getRandomUserUrl(activeUsers, standupModeStatus, standupTeamIds);
              notifyClientsOfNavigation(newUserUrl);
              history.push(newUserUrl);
            }
            if (urlParams && urlParams.userId) {
              const newProjectUrl = getRandomProjectUrl(
                activeProjects,
                standupModeStatus,
                standupTeamIds,
                isPersonalDevelopmentStandup,
              );
              notifyClientsOfNavigation(newProjectUrl);
              history.push(newProjectUrl);
            }
          }
          setLastChangedGoalId(0);
          break;
        case 72: // h
          keyboardEvent.preventDefault();
          toggleUserHideDoneTasks();
          return;
        case 80: // p
          keyboardEvent.preventDefault();
          history.push(firstProjectUrl);
          return;
        case 85: // u
          keyboardEvent.preventDefault();
          history.push(firstUserUrl);
          return;
        case 83: // s
          keyboardEvent.preventDefault();
          toggleSettingsVisible();
          return;
        case 82: // r
          keyboardEvent.preventDefault();
          refreshData();
          return;
        case 191: // / (forward slash) or ? (question mark)
          keyboardEvent.preventDefault();
          toggleHelpVisible();
          return;
        default:
          return;
      }
    }
  };

  const selectTeams = (teamIds: Array<number>, forPersonalDevelopmentStandup: boolean) => {
    setStandupTeamIds(teamIds);
    setPersonalDevelopmentStandup(forPersonalDevelopmentStandup);
    joinStandup(teamIds, forPersonalDevelopmentStandup);
  };

  const leaveStandupAndResetTeamIds = () => {
    if (currentUser) {
      setStandupTeamIds([currentUser.team.teamId]);
    }
    setPersonalDevelopmentStandup(false);
    leaveStandup();
  };

  const userOptions = useMemo(() => mapUsersToSelectOptions(activeUsers), [activeUsers]);

  const onUserChange = (newValue: User) => {
    localStorage.setItem('currentUserObject', JSON.stringify(newValue));

    setCurrentUser(newValue);
    setStandupTeamIds([newValue.team.teamId]);
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, /* useCapture: */ true);
    return () => {
      document.removeEventListener('keydown', handleKeydown, /* useCapture: */ true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParams, activeProjects, activeUsers]);

  useEffect(() => {
    const localStorageUser = localStorage.getItem('currentUserObject');
    const currentUserObject =
      localStorageUser != null ? (JSON.parse(localStorageUser) as User) : null;

    if (currentUserObject && currentUserObject.team) {
      setCurrentUser(currentUserObject);
      setStandupTeamIds([currentUserObject.team.teamId]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeUsers]);

  return (
    <Container inStandupMode={standupModeStatus === 'Joined'}>
      {helpVisible && <HelpModal hideHelp={toggleHelpVisible} />}
      <NavMenuIconButton
        onClick={toggleSettingsVisible}
        colour={white}
        hoverColour={translucentWhite}
        data-cy="settings-button"
      >
        <Settings />
      </NavMenuIconButton>
      <ViewNameContainer withNavButtons={showNavButtons || false}>
        {showNavButtons && previousUrl && (
          <NavButton to={previousUrl}>
            <Icon colour={white} size="small">
              <Left />
            </Icon>
          </NavButton>
        )}
        {standupModeStatus === 'Joined' ? (
          <ToggleField
            checked={onlyShowCurrentTeamTasks}
            onChange={setOnlyShowCurrentTeamTasks}
            label="Only Current Team Tasks"
          />
        ) : null}
        <ViewName>
          {viewName}
          {viewName && showPersonalDoneTasksToggle && (
            <UserViewContextMenuButton
              personalDoneTasksHidden={personalDoneTasksHidden}
              toggleUserHideDoneTasks={toggleUserHideDoneTasks}
            />
          )}
        </ViewName>
        <Details>
          {retainerDaysRemaining != null && (
            <>
              Retainer: <strong>{retainerDaysRemaining}</strong>
            </>
          )}
        </Details>
        <SelectUser>
          <Formik<SelectUserFormModel> initialValues={{}} onSubmit={() => ({})}>
            <SelectField<User>
              className="select-user-input-container"
              name="select-user"
              placeholder="Select a user..."
              options={userOptions}
              mapValueToSelectOption={mapUserToSelectOption}
              maxMenuHeightInPixels={selectUserMaxMenuHeightInPixels}
              mapOptionToKey={(option) => option.value.userId.toString()}
              styles={userSelectStyles}
              onItemSelected={onUserChange}
              defaultValue={currentUser}
            />
          </Formik>
        </SelectUser>
        {showNavButtons && nextUrl && (
          <NavButton
            onClick={() =>
              randomNumber === 69 && window.open('https://www.youtube.com/watch?v=2qBlE2-WL60')
            }
            to={nextUrl}
          >
            <Icon colour={white} size="small">
              <Right />
            </Icon>
          </NavButton>
        )}
      </ViewNameContainer>
      <LoadingOverlay loading={standupModeStatus === 'Joining' || standupModeStatus === 'Leaving'}>
        <StandupContainer>
          <NavMenuIconButton
            disabled={!currentUser}
            onClick={() =>
              standupTeamIds.length > 0 && standupModeStatus === 'Not Participating'
                ? joinStandup(standupTeamIds, isPersonalDevelopmentStandup)
                : leaveStandupAndResetTeamIds()
            }
            colour={white}
            title="Standup Mode"
            hoverColour={translucentWhite}
          >
            <StandUpIcon>
              {standupModeStatus === 'Joined' ? (
                <FryingPanActive data-cy="standup-active-icon" />
              ) : (
                <FryingPan data-cy="standup-inactive-icon" />
              )}
            </StandUpIcon>
          </NavMenuIconButton>
          {currentUser && standupModeStatus === 'Not Participating' && (
            <StandupMenuButton
              defaultTeamId={currentUser && currentUser.team.teamId}
              teams={activeTeams}
              selectTeams={selectTeams}
              isPersonalDevelopmentStandup={isPersonalDevelopmentStandup}
              setPersonalDevelopmentStandup={setPersonalDevelopmentStandup}
            />
          )}
        </StandupContainer>
      </LoadingOverlay>
    </Container>
  );
};

export const NavMenu = withRouter(NavMenuComponent);

const Details = styled.div`
  text-align: center;
  bottom: 0.25em;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding-top: 0.2em;

  height: 1.5em;
  z-index: 15;
  font-size: small;
  color: white;
`;

const Container = styled.header<{ inStandupMode: boolean }>`
  position: fixed;
  top: 0;
  width: 100%;
  height: ${navMenuHeight};
  z-index: 10;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-image: linear-gradient(
    to bottom right,
    ${(props) => (props.inStandupMode ? yellow : turquoise)},
    ${(props) => (props.inStandupMode ? warningText : darkTurquoise)}
  );
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
`;

const NavButton = styled(IconLink)`
  margin: 0 ${narrow};
`;

const NavMenuIconButton = styled(IconButton)`
  height: ${navMenuHeight};
  width: ${navMenuHeight};
  border-radius: 0;
`;

const ViewNameContainer = styled.div<{ withNavButtons: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.withNavButtons ? 'space-between' : 'center')};
  align-items: center;
  flex-grow: 1;
  height: 100%;

  border-right: solid ${pageHeaderBorderWidth} ${white};
  border-left: solid ${pageHeaderBorderWidth} ${white};
`;

const ViewName = styled.div`
  display: flex;
  align-items: center;
  font-size: ${headerFont};
  color: ${white};
  margin: 0 ${narrow};
`;

const SelectUser = styled.div`
  text-align: center;
  width: 15%;
  position: absolute;
  right: max(${navMenuHeightPixels * 2}px, 15%);
`;

const StandupContainer = styled.div`
  height: ${navMenuHeight};
  width: ${navMenuHeightPixels + 25}px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const StandUpIcon = styled(Icon)`
  width: ${navMenuHeightPixels - 25}px;
  height: ${navMenuHeightPixels - 25}px;
`;

type ToggleFieldProps = ReactSwitchProps & {
  label: string;
};

const ToggleField = (props: ToggleFieldProps) => (
  <ToggleContainer>
    <ToggleLabel>{props.label}</ToggleLabel>
    <Switch checked={props.checked} onChange={props.onChange} />
  </ToggleContainer>
);

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: calc(${medium} + ${navMenuHeight} + ${standardButtonHeight});
`;

const ToggleLabel = styled.label`
  color: ${white};
  margin-right: ${medium};
`;
