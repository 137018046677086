import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { black, lightGrey } from '../styling/colours';

export type TooltipProps = {
  timeout?: number;
  content: string;
  children?: React.ReactNode;
};

const DefaultTimeoutInMilliseconds = 400;

export const Tooltip = ({ timeout, content, children }: TooltipProps) => {
  const [active, setActive] = useState(false);

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true);
    }, timeout || DefaultTimeoutInMilliseconds);
  };

  const hideTip = () => {
    clearInterval(timeout);
    setActive(false);
  };

  return (
    <TooltipWrapper onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <TooltipSection>{content}</TooltipSection>}
    </TooltipWrapper>
  );
};

const TooltipArrowSize = '6px';
const TooltipMargin = '40px';

export const TooltipWrapper = styled.div`
  display: inline-block;
  position: relative;
  left: 5px;
  text-transform: none;
`;

export const TooltipSection = styled.div`
  left: 100% + ${TooltipMargin};
  top: 50%;

  position: absolute;
  border-radius: 4px;
  left: 50%;
  transform: translateX(0) translateY(-50%);
  padding: 6px;
  color: ${black};
  background: ${lightGrey};
  font-size: 14px;
  font-family: sans-serif;
  line-height: 1;
  z-index: 100;
  white-space: nowrap;

  &:before {
    content: ' ';
    left: -${TooltipArrowSize};
    top: 50%;
    transform: translateX(0) translateY(-50%);
    border-right-color: ${lightGrey};
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-width: ${TooltipArrowSize};
    top: 100%;
    border-top-color: ${lightGrey};
    margin-left: -${TooltipArrowSize};
  }
`;
