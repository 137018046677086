import { Formik } from 'formik';
import React, { createRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Trash } from '../../../images/icons/trash-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import {
  alertAccent,
  getRandomColour,
  successAccent,
  warningAccent,
} from '../../../styling/colours';
import { CreateUpdateTeamForm } from '../CreateUpdateTeamForm';
import { CreateTeamDto } from '../team';
import {
  CreateTeamFormModel,
  CreateTeamFormValidator,
  toCreateTeamDto,
} from './createTeamFormModel';

type Props = {
  onSubmit: (createTeamDto: CreateTeamDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new CreateTeamFormValidator();

export const CreateTeamModal = ({ onSubmit, closeRequested, cancelCloseRequest, close }: Props) => {
  const teamNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const teamNameInput = teamNameInputRef.current;
    if (teamNameInput !== null) {
      teamNameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues = {
    name: '',
    colour: getRandomColour(true),
    isActive: true,
  };

  return (
    <Formik<CreateTeamFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={formModel => {
        onSubmit(toCreateTeamDto(formModel));
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateTeamForm teamNameInputRef={teamNameInputRef} formTitle="Create new team">
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
            <BorderedIconButton
              onClick={close}
              disabled={isSubmitting}
              title="Cancel Input"
              hoverColour={alertAccent}
            >
              <Trash />
            </BorderedIconButton>
          </CreateUpdateTeamForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
