import React, { useContext } from 'react';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { withModal, WithModalProps } from '../../shared/higher-order-components/withModal';
import { yellow } from '../../styling/colours';
import { formatDateStampWithDay } from '../../utils/dateStamp';
import { GoalState } from '../goal-states/goalState';
import { GoalStatesContext } from '../goal-states/GoalStatesContextProvider';
import { Project } from '../projects/project';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { User } from '../users/user';
import { UsersContext } from '../users/UsersContextProvider';
import { frequencyString, RecurringGoal, UpdateRecurringGoalDto } from './recurringGoal';
import { UpdateRecurringGoalModal } from './update-recurring-goal/UpdateRecurringGoalModal';

type OwnProps = {
  recurringGoals: Array<RecurringGoal>;
  onUpdateRecurringGoal: (updateRecurringGoal: UpdateRecurringGoalDto) => Promise<void>;
  onDeleteRecurringGoal: (recurringGoalId: number) => Promise<void>;
};

type RecurringGoalsTableProps = OwnProps &
  WithModalProps<UpdateRecurringGoalModalParams, 'updateRecurringGoalModal'>;

const RecurringGoalsTableComponent = ({
  recurringGoals,
  onUpdateRecurringGoal,
  onDeleteRecurringGoal,
  updateRecurringGoalModal,
}: RecurringGoalsTableProps) => {
  const { activeUsers } = useContext(UsersContext);
  const { activeProjects } = useContext(ProjectsContext);
  const { goalStates } = useContext(GoalStatesContext);

  const launchEditModal = (recurringGoal: RecurringGoal) => {
    updateRecurringGoalModal.open({
      recurringGoal,
      activeUsers,
      activeProjects,
      goalStates,
      onSubmit: onUpdateRecurringGoal,
      onDelete: (recurringGoalId: number | null) => {
        updateRecurringGoalModal.close();
        if (recurringGoalId != null) {
          onDeleteRecurringGoal(recurringGoalId);
        }
      },
    });
  };

  const headerProps = () => ({
    style: { background: yellow, fontWeight: 'bold', boxShadow: '0px 0px 0px' },
  });

  const rowProps = (state: {}, rowInfo: RowInfo | undefined) =>
    typeof rowInfo === 'undefined'
      ? {}
      : {
          style: {
            borderBottom: 'solid 1px rgba(0,0,0,0.15)',
            cursor: 'pointer',
          },
          onClick: () => launchEditModal(rowInfo.original),
        };

  const columns = [
    {
      Header: 'Description',
      accessor: 'description',
      width: 250,
    },
    {
      Header: 'Frequency',
      id: 'frequency',
      accessor: frequencyString,
      width: 250,
    },
    {
      Header: 'Next Occurrence',
      accessor: 'nextOccurrence',
      Cell: (cell: CellInfo) => formatDateStampWithDay(cell.value),
      width: 150,
    },
    {
      Header: 'Project',
      id: 'project',
      accessor: (recurringGoal: RecurringGoal) => recurringGoal.project.name,
      width: 150,
    },
    {
      Header: 'Notes',
      accessor: 'notes',
    },
    {
      Header: 'State',
      accessor: 'stateCode',
      Cell: (cell: CellInfo) => {
        const goalState = goalStates.find((gS) => gS.stateCode === cell.value);
        return goalState ? goalState.name : 'UNKNOWN';
      },
      width: 115,
    },
    {
      Header: 'Goal Group',
      accessor: 'goalGroupName',
    },
    {
      Header: 'Assignees',
      id: 'assignees',
      accessor: (recurringGoal: RecurringGoal) =>
        recurringGoal.assignees.map((user: User) => user.chosenName).join(', '),
    },
  ];

  return (
    <ReactTable
      className="-striped -highlight"
      style={{ backgroundColor: 'white' }}
      data={recurringGoals}
      columns={columns}
      defaultSorted={[{ id: 'nextOccurrence', desc: false }]}
      showPagination={false}
      defaultPageSize={200}
      minRows={0}
      getTheadProps={headerProps}
      getTrProps={rowProps}
    />
  );
};

const withUpdateRecurringGoalModal = withModal<
  OwnProps,
  UpdateRecurringGoalModalParams,
  'updateRecurringGoalModal'
>(
  'updateRecurringGoalModal',
  ({ params, closeModal, closeRequested, cancelCloseRequest }) => (
    <UpdateRecurringGoalModal
      recurringGoal={params.recurringGoal}
      activeUsers={params.activeUsers}
      activeProjects={params.activeProjects}
      goalStates={params.goalStates}
      onSubmit={params.onSubmit}
      onDelete={params.onDelete}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

export type UpdateRecurringGoalModalParams = {
  recurringGoal: RecurringGoal;
  activeUsers: Array<User>;
  activeProjects: Array<Project>;
  goalStates: Array<GoalState>;
  onSubmit: (updateRecurringGoal: UpdateRecurringGoalDto) => void;
  onDelete: (recurringGoalId: number | null) => void;
};

export const RecurringGoalsTable = withUpdateRecurringGoalModal(RecurringGoalsTableComponent);
