import { createGlobalStyle } from 'styled-components';
import { assertNotNull } from '../utils/assertNotNull';
import { defaultBackgroundColour, fontGrey } from './colours';
import { lightFontFamily, regularFontFamily, regularWeight, smallFont } from './fonts';
import { medium } from './spacing';

export const modalIsOpenClassName = 'modal-is-open';

export const getDocumentHtmlNode = () =>
  assertNotNull(document.getElementsByTagName('html').item(0));

type GlobalStylesProps = {
  isKanbanSettingsVisible: boolean;
  isModalVisible: boolean;
};

export const GlobalStyles = createGlobalStyle<GlobalStylesProps>`
  html, body {
    box-sizing: border-box;
    font-family: ${regularFontFamily};
    font-size: ${smallFont};
    font-weight: ${regularWeight};
    line-height: 1.3;
    color: ${fontGrey};
    margin: 0;
  }

  code {
    background-color: #eee;
    border-radius: 3px;
    font-family: monospace;
    padding: 0 3px;
  }
  
  .rainbow_text_animated {
    text-align: center;
    text-decoration: underline;
    font-size: 32px;
    font-family: monospace;
    letter-spacing: 5px;
    
    background: linear-gradient(to right, #6666ff, #0099ff , #00ff00, #ffd400, #ff1d00, #ff3399, #6666ff);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    animation: rainbow_animation 6s ease-in-out infinite;
    background-size: 400% 100%;
  }

  @keyframes rainbow_animation {
    0%,100% {
      background-position: 0 0;
    }

    50% {
      background-position: 100% 0;
    }
  }

  .burning_text_animated {
    font-size: 32px;
    font-family: monospace;
    letter-spacing: 5px;

    animation: burning_animation 1s ease-in-out infinite alternate;
    -moz-animation: burning_animation 1s ease-in-out infinite alternate;
    -webkit-animation: burning_animation 1s ease-in-out infinite alternate;
    -o-animation: burning_animation 1s ease-in-out infinite alternate;
  }

  @keyframes burning_animation
  {
    0% {text-shadow: 0 0 4px #fefcc9,
      2px -2px 6px #feec85,
      -4px -4px 8px #ffae34,
      4px -8px 10px #ec760c,
      -4px -12px 12px #cd4606,
      0 -16px 14px #973716,
      2px -18px 16px #451b0e;}
    100% {text-shadow: 0 0 20px #fefcc9,
      2px -2px 6px #fefcc9,
      -4px -4px 8px #feec85,
      5px -9px 12px #ffae34,
      -5px -11px 10px #ec760c,
      0 -17px 16px #cd4606,
      2px -18px 16px  #973716;}
  }
  
  .spoiler {
    transition: all 0.5s;
    color: transparent;
    background-color: #888;
    border-radius: 4px;
    padding: 1px;
    display: inline-block;
    filter: saturate(0%) brightness(0%) contrast(0%);
  }
  
  .spoiler:hover {
      background-color: transparent;
      color: inherit;
        filter: none;
  }

  html.${modalIsOpenClassName} {
    overflow-y: hidden;
  }

  html {
    height: 100%;
    overflow-y: ${(props) =>
      props.isKanbanSettingsVisible || props.isModalVisible ? 'hidden' : 'scroll'};
  }
  
  body, #root {
    height: 100%;
    background-color: ${defaultBackgroundColour};
  }
    
  *, *:before, *:after {
    box-sizing: inherit;
  }
    
  h1, h2, h3, h4, h5, h6 {
    font-family: ${lightFontFamily};
    margin-bottom: ${medium};
    margin-top: 0;
    font-weight: ${regularWeight};
  }
`;
