import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { Team } from '../teams/team';
import { ProjectTeam, toProjectTeam } from './projectTeam';

export const mapTeamsToProjectTeamSelectOptions = (
  teams: Array<Team>,
  projectId: number | null,
): Array<SelectOption<ProjectTeam>> =>
  teams.map(team => ({
    value: toProjectTeam(team, projectId),
    label: team.name,
  }));

export const mapProjectTeamsToSelectOptions = (
  projectTeams: Array<ProjectTeam>,
): Array<SelectOption<ProjectTeam>> =>
  projectTeams.map(projectTeam => ({
    value: projectTeam,
    label: projectTeam.name,
  }));
