import React, { useMemo } from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import styled from 'styled-components/macro';
import { ReactComponent as Reset } from '../../images/icons/undo-solid.svg';
import { IconButton } from '../../shared/buttons/IconButton';
import { DateInput } from '../../shared/form/input-fields/DateField';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { defaultSelectStyles } from '../../shared/form/select-fields/selectStyles';
import { Toggle } from '../../shared/form/ToggleField';
import { bold, smallFont } from '../../styling/fonts';
import { narrow, narrowPixels } from '../../styling/spacing';
import { DateStamp, toDate, toDateStamp } from '../../utils/dateStamp';
import {
  GoalState,
  mapGoalStatesToSelectOptionsWithNull,
  mapGoalStateToSelectOption,
} from '../goal-states/goalState';
import { Project } from '../projects/project';
import {
  mapProjectsToSelectOptionsWithNull,
  mapProjectToSelectOption,
  projectSelectStyles,
} from '../projects/projectSelectUtils';
import { User } from '../users/user';
import { mapUsersToSelectOptionsWithNull, mapUserToSelectOption } from '../users/userSelectUtils';
import { SearchGoalsFilterValues } from './searchGoals';

type Props = {
  filterValues: SearchGoalsFilterValues;
  resetFilters: () => void;

  projects: Array<Project>;
  goalStates: Array<GoalState>;
  assignees: Array<User>;

  setProjectId: (projectId: number | null) => void;
  showInactiveProjectsChanged: (showInactive: boolean) => void;
  setGoalStateCode: (stateCode: string | null) => void;
  setAssigneeId: (assigneeId: number | null) => void;
  showInactiveAssigneesChanged: (showInactive: boolean) => void;
  setCreationFromDate: (fromDate: DateStamp | null) => void;
  setCreationToDate: (toDate: DateStamp | null) => void;
  setCompletionFromDate: (fromDate: DateStamp | null) => void;
  setCompletionToDate: (toDate: DateStamp | null) => void;
  showCompletedChanged: (showCompleted: boolean) => void;

  disabled: boolean;
};

export const SearchGoalsFilters = ({
  filterValues,
  resetFilters,

  projects,
  goalStates,
  assignees,

  setProjectId,
  showInactiveProjectsChanged,
  setGoalStateCode,
  setAssigneeId,
  showInactiveAssigneesChanged,
  setCreationFromDate,
  setCreationToDate,
  setCompletionFromDate,
  setCompletionToDate,
  showCompletedChanged,

  disabled,
}: Props) => {
  return (
    <Container>
      <Filters>
        <ProjectFilter
          projects={projects}
          selectedProjectId={filterValues.projectId}
          showInactive={filterValues.showInactiveProjects}
          onProjectIdChanged={setProjectId}
          onShowInactiveProjectsChanged={showInactiveProjectsChanged}
          disabled={disabled}
        />
        <GoalStateFilter
          goalStates={goalStates}
          selectedGoalStateCode={filterValues.goalStateCode}
          onGoalStateCodeChanged={setGoalStateCode}
          disabled={disabled}
        />
        <AssigneeFilter
          assignees={assignees}
          selectedAssigneeId={filterValues.assigneeId}
          showInactive={filterValues.showInactiveAssignees}
          onAssigneeIdChanged={setAssigneeId}
          onShowInactiveAssigneesChanged={showInactiveAssigneesChanged}
          disabled={disabled}
        />
        <CreationDateFilter
          selectedFromDate={filterValues.creationFromDate}
          selectedToDate={filterValues.creationToDate}
          onFromDateChanged={setCreationFromDate}
          onToDateChanged={setCreationToDate}
          disabled={disabled}
        />
        <CompletionDateFilter
          selectedFromDate={filterValues.completionFromDate}
          selectedToDate={filterValues.completionToDate}
          onFromDateChanged={setCompletionFromDate}
          onToDateChanged={setCompletionToDate}
          disabled={disabled}
          showCompleted={filterValues.showCompleted}
          onShowCompletedChanged={showCompletedChanged}
        />
      </Filters>
      <IconButton onClick={resetFilters} disabled={disabled} title="Reset filters">
        <Reset />
      </IconButton>
    </Container>
  );
};

type ProjectFilterProps = {
  projects: Array<Project>;
  selectedProjectId: number | null;
  showInactive: boolean;
  onProjectIdChanged: (projectId: number | null) => void;
  onShowInactiveProjectsChanged: (showInactive: boolean) => void;
  disabled: boolean;
};

const ProjectFilter = ({
  projects,
  selectedProjectId,
  showInactive,
  onProjectIdChanged,
  onShowInactiveProjectsChanged,
  disabled,
}: ProjectFilterProps) => {
  const onChange = (newValue: ValueType<SelectOption<Project | null>>) => {
    const option = newValue as SelectOption<Project | null>;
    onProjectIdChanged(!!option.value ? option.value.projectId : null);
  };

  const allProjectsLabel = 'All Projects';

  const projectOptions = useMemo(
    () => mapProjectsToSelectOptionsWithNull(projects, allProjectsLabel),
    [projects],
  );

  const selectedProject = projects.find(project => project.projectId === selectedProjectId) || null;

  return (
    <FilterContainer>
      <FilterLabel>Project</FilterLabel>
      <SelectContainer>
        <Select<SelectOption<Project | null>>
          name="project"
          onChange={onChange}
          value={!!selectedProject ? mapProjectToSelectOption(selectedProject) : null}
          isDisabled={disabled}
          placeholder={allProjectsLabel}
          options={projectOptions}
          styles={projectSelectStyles}
        />
      </SelectContainer>
      <FilterToggle
        label="Show Inactive?"
        onChange={onShowInactiveProjectsChanged}
        checked={showInactive}
        disabled={disabled}
      />
    </FilterContainer>
  );
};

type GoalStateFilterProps = {
  goalStates: Array<GoalState>;
  selectedGoalStateCode: string | null;
  onGoalStateCodeChanged: (goalStateCode: string | null) => void;
  disabled: boolean;
};

const GoalStateFilter = ({
  goalStates,
  selectedGoalStateCode,
  onGoalStateCodeChanged,
  disabled,
}: GoalStateFilterProps) => {
  const onChange = (newValue: ValueType<SelectOption<GoalState | null>>) => {
    const option = newValue as SelectOption<GoalState | null>;
    onGoalStateCodeChanged(!!option.value ? option.value.stateCode : null);
  };

  const allGoalStatesLabel = 'All States';

  const goalStateOptions = useMemo(
    () => mapGoalStatesToSelectOptionsWithNull(goalStates, allGoalStatesLabel),
    [goalStates],
  );

  const selectedGoalState =
    goalStates.find(goalState => goalState.stateCode === selectedGoalStateCode) || null;

  return (
    <FilterContainer>
      <FilterLabel>State</FilterLabel>
      <SelectContainer>
        <Select<SelectOption<GoalState | null>>
          name="goalState"
          onChange={onChange}
          value={!!selectedGoalState ? mapGoalStateToSelectOption(selectedGoalState) : null}
          isDisabled={disabled}
          placeholder={allGoalStatesLabel}
          options={goalStateOptions}
          styles={defaultSelectStyles}
        />
      </SelectContainer>
    </FilterContainer>
  );
};

type AssigneeFilterProps = {
  assignees: Array<User>;
  selectedAssigneeId: number | null;
  showInactive: boolean;
  onAssigneeIdChanged: (assigneeId: number | null) => void;
  onShowInactiveAssigneesChanged: (showInactive: boolean) => void;
  disabled: boolean;
};

const AssigneeFilter = ({
  assignees,
  selectedAssigneeId,
  showInactive,
  onAssigneeIdChanged,
  onShowInactiveAssigneesChanged,
  disabled,
}: AssigneeFilterProps) => {
  const onChange = (newValue: ValueType<SelectOption<User | null>>) => {
    const option = newValue as SelectOption<User | null>;
    onAssigneeIdChanged(!!option.value ? option.value.userId : null);
  };

  const allAssigneesLabel = 'All Assignees';

  const assigneeOptions = useMemo(
    () => mapUsersToSelectOptionsWithNull(assignees, allAssigneesLabel),
    [assignees],
  );

  const selectedAssignee =
    assignees.find(assignee => assignee.userId === selectedAssigneeId) || null;

  return (
    <FilterContainer>
      <FilterLabel>Assignee</FilterLabel>
      <SelectContainer>
        <Select<SelectOption<User | null>>
          name="assignee"
          onChange={onChange}
          value={!!selectedAssignee ? mapUserToSelectOption(selectedAssignee) : null}
          isDisabled={disabled}
          placeholder={allAssigneesLabel}
          options={assigneeOptions}
          styles={projectSelectStyles}
        />
      </SelectContainer>
      <FilterToggle
        label="Show Inactive?"
        onChange={onShowInactiveAssigneesChanged}
        checked={showInactive}
        disabled={disabled}
      />
    </FilterContainer>
  );
};

type CreationDateFilterProps = FromAndToDateFilterProps;

const CreationDateFilter = ({
  selectedFromDate,
  selectedToDate,
  onFromDateChanged,
  onToDateChanged,
  disabled,
}: CreationDateFilterProps) => (
  <FilterContainer>
    <FilterLabel>Creation Date</FilterLabel>
    <FromAndToDateInputFilters
      selectedFromDate={selectedFromDate}
      selectedToDate={selectedToDate}
      onFromDateChanged={onFromDateChanged}
      onToDateChanged={onToDateChanged}
      disabled={disabled}
    />
  </FilterContainer>
);

type CompletionDateFilterProps = FromAndToDateFilterProps & {
  showCompleted: boolean;
  onShowCompletedChanged: (showCompleted: boolean) => void;
};

const CompletionDateFilter = ({
  selectedFromDate,
  selectedToDate,
  onFromDateChanged,
  onToDateChanged,
  disabled,
  onShowCompletedChanged,
  showCompleted,
}: CompletionDateFilterProps) => (
  <FilterContainer>
    <FilterLabel>Completion Date</FilterLabel>
    <FromAndToDateInputFilters
      selectedFromDate={selectedFromDate}
      selectedToDate={selectedToDate}
      onFromDateChanged={onFromDateChanged}
      onToDateChanged={onToDateChanged}
      disabled={disabled || !showCompleted}
    />
    <FilterToggle
      label="Show Completed?"
      onChange={onShowCompletedChanged}
      checked={showCompleted}
      disabled={disabled}
    />
  </FilterContainer>
);

type FromAndToDateFilterProps = {
  selectedFromDate: DateStamp | null;
  selectedToDate: DateStamp | null;
  onFromDateChanged: (fromDate: DateStamp | null) => void;
  onToDateChanged: (toDate: DateStamp | null) => void;
  disabled: boolean;
};

const FromAndToDateInputFilters = ({
  selectedFromDate,
  selectedToDate,
  onFromDateChanged,
  onToDateChanged,
  disabled,
}: FromAndToDateFilterProps) => {
  const onChangeFromDate = (newValue: Date | null) => {
    onFromDateChanged(newValue ? toDateStamp(newValue) : null);
  };

  const onChangeToDate = (newValue: Date | null) => {
    onToDateChanged(newValue ? toDateStamp(newValue) : null);
  };

  return (
    <>
      <DateInputFilter
        selected={selectedFromDate ? toDate(selectedFromDate) : null}
        onChange={onChangeFromDate}
        dateFormat="d-MMM-yy"
        locale="en-GB"
        placeholderText="From..."
        disabled={disabled}
      />
      <LastDateInputFilter
        selected={selectedToDate ? toDate(selectedToDate) : null}
        onChange={onChangeToDate}
        dateFormat="d-MMM-yy"
        locale="en-GB"
        placeholderText="To..."
        disabled={disabled}
      />
    </>
  );
};

type FilterToggleProps = {
  label: string;
  onChange: (checked: boolean) => void;
  checked: boolean;
  disabled: boolean;
};

const FilterToggle = ({ label, onChange, checked, disabled }: FilterToggleProps) => (
  <ToggleContainer>
    <Toggle onChange={onChange} checked={checked} disabled={disabled} />
    <ToggleLabel>{label}</ToggleLabel>
  </ToggleContainer>
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${narrow};
`;

const Filters = styled.div``;

const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: ${narrow};
`;

const Label = styled.div`
  text-transform: uppercase;
  font-size: ${smallFont};
  font-weight: ${bold};
`;

const FilterLabel = styled(Label)`
  text-align: right;
  width: 150px;
  margin-right: ${narrow};
`;

const filterWidthPixels = 360;

const SelectContainer = styled.div`
  width: ${filterWidthPixels}px;
`;

const DateInputFilter = styled(DateInput)`
  width: ${filterWidthPixels / 2 - narrowPixels / 2}px;
  margin-right ${narrow};
`;

const LastDateInputFilter = styled(DateInputFilter)`
  margin-right: 0;
`;

const ToggleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: ${narrow};
`;

const ToggleLabel = styled(Label)`
  margin-left: ${narrow};
`;
