import React from 'react';
import styled from 'styled-components/macro';
import {
  black,
  blue,
  blueHoverAccent,
  darkGrey,
  greyHoverAccent,
  lightGrey,
  white,
} from '../../styling/colours';
import { bold } from '../../styling/fonts';
import { narrow, smallBorderRadius, wide } from '../../styling/spacing';
import { regularTransitionDuration } from '../../styling/transitions';

export type ButtonProps = {
  onClick?: (mouseEvent: React.MouseEvent<HTMLElement>) => void;
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset';
  size?: 'normal' | 'small';
};

export const standardButtonHeight = '46px';

const BaseButton = (
  props: React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
) => <button {...props} type={props.type || 'button'} />;

export const Button = styled(BaseButton)<ButtonProps>`
  position: relative;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: ${bold};

  border: none;
  border-radius: ${smallBorderRadius};

  height: ${props => (props.size === 'small' ? wide : standardButtonHeight)};
  padding: 0 ${props => (props.size === 'small' ? narrow : wide)};
  transition: all ${regularTransitionDuration} ease;

  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  opacity: ${props => (props.disabled ? 0.4 : null)};
`;

export const PrimaryButton = styled(Button)`
  background-color: ${blue};
  color: ${white};

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && blueHoverAccent};
    outline: none;
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: ${darkGrey};
  color: ${white};

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && greyHoverAccent};
    outline: none;
  }
`;

export const HollowButton = styled(Button)`
  background-color: transparent;
  color: ${black};
  border: 1px solid ${black};

  &:hover,
  &:focus {
    color: ${props => !props.disabled && blueHoverAccent};
    border-color: ${props => !props.disabled && blueHoverAccent};
    outline: none;
  }
`;

export const BorderlessHollowButton = styled(HollowButton)`
  border: none;

  &:hover,
  &:focus {
    background-color: ${props => !props.disabled && lightGrey};
  }
`;

export const ButtonGroup = styled.div`
  ${Button} {
    margin-right: ${narrow};

    &:last-of-type {
      margin-right: 0;
    }
  }

  margin-bottom: ${wide};
`;
