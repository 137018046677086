import { Formik } from 'formik';
import React, { createRef, useEffect, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { successAccent, warningAccent } from '../../../styling/colours';
import { CreateUpdateUserForm } from '../CreateUpdateUserForm';
import { UpdateUserDto, User } from '../user';
import {
  toUpdateUserDto,
  UpdateUserFormModel,
  UpdateUserFormValidator,
} from './updateUserFormModel';

type Props = {
  user: User;
  onSubmit: (updateUserDto: UpdateUserDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new UpdateUserFormValidator();

export const UpdateUserModal = ({
  user,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  const chosenNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const chosenNameInput = chosenNameInputRef.current;
    if (chosenNameInput !== null) {
      chosenNameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues = useMemo(
    () => ({
      userId: user.userId,
      chosenName: user.chosenName,
      familyName: user.familyName,
      email: user.email,
      colour: user.colour,
      isActive: user.isActive,
      team: user.team,
    }),
    [user],
  );

  return (
    <Formik<UpdateUserFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={(formModel) => {
        onSubmit(toUpdateUserDto(formModel));
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateUserForm chosenNameInputRef={chosenNameInputRef} formTitle="Update user">
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
          </CreateUpdateUserForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
