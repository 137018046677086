import React from 'react';
import styled from 'styled-components/macro';
import { TooltipProps } from '../../Tooltip';
import { FormField } from '../FormField';
import { inputStyles } from './inputStyles';

type InputFieldProps = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  fontSize?: 'tiny' | 'small' | 'large';
  fontWeight?: 'bold';
  maxLength?: number;
  spellcheck?: boolean;
  tooltip?: TooltipProps;
};

export const InputField = React.forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => (
  <FormField
    name={props.name}
    label={props.label}
    optional={props.optional}
    className={props.className}
    type={props.type}
    tooltip={props.tooltip}
  >
    {({ field, form }) => (
      <Input
        {...field}
        ref={ref}
        value={field.value || ''}
        id={props.name}
        disabled={props.disabled || form.isSubmitting}
        placeholder={props.placeholder}
        fontSize={props.fontSize}
        fontWeight={props.fontWeight}
        maxLength={props.maxLength}
        type={props.type}
        spellCheck={props.spellcheck}
      />
    )}
  </FormField>
));

type InputProps = {
  fontSize?: string;
  fontWeight?: string;
  className?: string;
};

export const Input = styled.input<InputProps>`
  ${({ fontSize, fontWeight }) => inputStyles({ fontSize, fontWeight })};
`;
