import { Validator } from 'fluentvalidation-ts/dist';
import { assertNotNull } from '../../../utils/assertNotNull';
import { DateStamp, WeekdayOption } from '../../../utils/dateStamp';
import { GoalState } from '../../goal-states/goalState';
import { Project } from '../../projects/project';
import { User } from '../../users/user';
import { UpdateRecurringGoalDto } from '../recurringGoal';

export type UpdateRecurringGoalFormModel = {
  recurringGoalId: number;
  description: string;
  notes: string;
  startDate: DateStamp;
  daysFrequency: number;
  monthsFrequency: number;
  dayOfWeek: WeekdayOption;
  nextOccurrence: DateStamp | null;
  goalState: GoalState | null;
  runsOnLastWeekdayOfMonth: boolean;
  runOnWeekends: boolean;
  goalGroupName: string;
  project: Project;
  assignees: Array<User>;
};

export class UpdateRecurringGoalFormValidator extends Validator<UpdateRecurringGoalFormModel> {
  constructor() {
    super();

    this.ruleFor('description')
      .notEmpty()
      .withMessage('Please add a description')
      .maxLength(128);

    this.ruleFor('notes').maxLength(1028);

    this.ruleFor('startDate')
      .notEmpty()
      .withMessage('Please choose a start date');

    this.ruleFor('daysFrequency').greaterThanOrEqualTo(0);
    this.ruleFor('monthsFrequency').greaterThanOrEqualTo(0);
    this.ruleFor('monthsFrequency')
      .greaterThan(0)
      .withMessage('Either Days or Months must have a positive value')
      .unless(
        recurringGoal => recurringGoal.daysFrequency > 0 || recurringGoal.runsOnLastWeekdayOfMonth,
      );

    this.ruleFor('dayOfWeek')
      .notNull()
      .withMessage('Must select a weekday for a last-weekday-of-month goal')
      .when(recurringGoal => recurringGoal.runsOnLastWeekdayOfMonth);

    this.ruleFor('goalState')
      .notNull()
      .withMessage('Please choose a state');

    this.ruleFor('goalGroupName').maxLength(32);

    this.ruleFor('project')
      .notNull()
      .withMessage('Please choose a project');
  }
}

export const toUpdateRecurringGoalDto = (
  formModel: UpdateRecurringGoalFormModel,
): UpdateRecurringGoalDto => ({
  recurringGoalId: formModel.recurringGoalId,
  description: formModel.description,
  notes: formModel.notes,
  startDate: assertNotNull(formModel.startDate),
  daysFrequency: formModel.daysFrequency,
  monthsFrequency: formModel.monthsFrequency,
  dayOfWeek: formModel.dayOfWeek,
  nextOccurrence: formModel.nextOccurrence,
  stateCode: assertNotNull(formModel.goalState).stateCode,
  goalGroupName: formModel.goalGroupName,
  runsOnLastWeekdayOfMonth: formModel.runsOnLastWeekdayOfMonth,
  runOnWeekends: formModel.runOnWeekends,
  projectId: assertNotNull(formModel.project).projectId,
  assigneeIds: formModel.assignees.map(user => user.userId),
});
