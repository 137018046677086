export const white = '#FFFFFF';
export const translucentWhite = 'rgba(255, 255, 255, 0.15)';
export const lessTranslucentWhite = 'rgba(255, 255, 255, 0.5)';

export const black = '#00050F';
export const translucentBlack = 'rgba(0, 0, 0, 0.15)';

export const red = '#FC5A6A';
export const lightRed = '#FEE6E8';
export const darkRed = '#CA4855';

export const blue = '#194492';
export const blueHoverAccent = '#1A6AFF';

export const purple = '#B251DD';
export const lightPurple = '#ECD4f7';

export const green = '#198842';
export const lightGreen = '#E1FAEA';

export const yellow = '#FFD747';

export const lightTurquoise = '#22AAAA';
export const turquoise = '#20A2A2';
export const darkTurquoise = '#1B8282';
export const translucentTurquoise = 'rgba(32, 162, 162, 0.15)';

export const fontGrey = '#464646';
export const greyBorder = '#D0D0D0';
export const darkGrey = '#707070';
export const lightGrey = '#D7D7D7';
export const greyHoverAccent = '#464646';

export const successText = green;
export const successAccent = lightGreen;

export const warningText = '#C78800';
export const warningAccent = '#FFF3D9';

export const alertText = red;
export const alertAccent = lightRed;

export const defaultBackgroundColour = '#DFDFDF';

export const getContrastedColour = (hex: string): string => {
  hex = removeHexColourHash(hex);

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  if (hex.length !== 6) {
    throw new Error(`Invalid HEX colour: ${hex}`);
  }

  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  return r * 0.2126 + g * 0.7152 + b * 0.0722 > 165 // SMPTE C, Rec. 709 weightings
    ? '#000000'
    : '#FFFFFF';
};

export const removeHexColourHash = (hex: string): string =>
  hex.trim().indexOf('#') === 0 ? hex.trim().slice(1) : hex;

export const addHexColourHash = (hex: string): string =>
  hex.trim().indexOf('#') === 0 ? hex : `#${hex.trim()}`;

export const getRandomColour = (includeHash: boolean): string => {
  const hexValues = '0123456789ABCDEF';
  let colour = includeHash ? '#' : '';

  for (let i = 0; i < 6; i++) {
    colour += hexValues[Math.floor(Math.random() * 16)];
  }

  return colour;
};

export const colourHexRegEx = (includeHash: boolean) =>
  new RegExp(`^${includeHash ? '#' : ''}([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$`);

const decimalToHex = (decimal: number) => {
  return decimal.toString(16);
};

const hexToDecimal = (hex: string) => {
  return parseInt(hex, 16);
};

export const mix = (color1: string, color2: string, weight?: number): string => {
  weight = weight !== undefined ? weight : 50;

  let color = '#';

  for (let i = 1; i <= 6; i += 2) {
    const colour1Decimal = hexToDecimal(color1.substr(i, 2));

    const colour2Decimal = hexToDecimal(color2.substr(i, 2));

    let colourMixHex = decimalToHex(
      Math.floor(colour2Decimal + (colour1Decimal - colour2Decimal) * (weight / 100.0)),
    );

    while (colourMixHex.length < 2) {
      colourMixHex = '0' + colourMixHex;
    }

    color += colourMixHex;
  }

  return color;
};

export const shadeColour = (colour: string, amount: number) => mix('#000000', colour, amount);
export const tintColour = (colour: string, amount: number) => mix('#ffffff', colour, amount);
