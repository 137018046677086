import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useFrameworksApi } from '../../hooks/api/useFrameworksApi';
import { FrameworkWithProjects } from './framework';

type FrameworksContextValue = {
  frameworks: Array<FrameworkWithProjects>;
  refreshFrameworks: () => Promise<void>;
};

export const FrameworksContext = React.createContext<FrameworksContextValue>({
  frameworks: [],
  refreshFrameworks: () => {
    throw new Error('Cannot refresh frameworks outside of its context provider');
  },
});

export const FrameworksContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [frameworks, setFrameworks] = useState<Array<FrameworkWithProjects>>([]);
  const { fetchFrameworks } = useFrameworksApi();

  useEffect(() => {
    refreshFrameworks();
  }, []);

  const refreshFrameworks = async () => {
    const frameworkData = await fetchFrameworks();
    setFrameworks(frameworkData.frameworks);
  };

  return (
    <FrameworksContext.Provider value={{ frameworks, refreshFrameworks }}>
      {children}
    </FrameworksContext.Provider>
  );
};
