import { css } from 'styled-components';
import { greyBorder } from '../../../styling/colours';
import {
  bold,
  largeFont,
  regularFont,
  regularWeight,
  smallFont,
  tinyFont,
} from '../../../styling/fonts';
import { narrow, smallBorderRadius } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';

export const inputStyles = ({
  fontSize,
  fontWeight,
}: {
  fontSize?: string;
  fontWeight?: string;
}) => css`
  border: 1px solid transparent;
  border-radius: ${smallBorderRadius};
  padding: ${narrow};

  &:focus {
    outline: none;
    border 1px solid ${greyBorder};
  }

  &:focus::placeholder {
    color: transparent;
  }

  font-size: ${
    fontSize === 'tiny'
      ? tinyFont
      : fontSize === 'small'
      ? smallFont
      : fontSize === 'large'
      ? largeFont
      : regularFont
  };

  font-weight: ${fontWeight === 'bold' ? bold : regularWeight};

  transition: all ${regularTransitionDuration} ease;
`;
