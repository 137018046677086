import React, { Context, createContext, useEffect, useState } from 'react';
import { MsalAuthProvider } from 'react-aad-msal';
import { Config, fetchConfig } from '../config/config';
import { LoadingOverlay } from '../LoadingOverlay';
import { createAuthProvider } from './authConfig';

export type AuthContextValue = {
  authProvider: MsalAuthProvider;
};

export type ConfigContextValue = {
  config: Config;
};

export const AuthContext: Context<AuthContextValue> = createContext<AuthContextValue>({
  authProvider: {} as MsalAuthProvider,
});

export const ConfigContext: Context<ConfigContextValue> = createContext<ConfigContextValue>({
  config: {} as Config,
});

type AuthenticationProviderProps = {};

export const AuthenticationContext = (
  props: React.PropsWithChildren<AuthenticationProviderProps>,
) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [config, setConfig] = useState<Config | null>(null);
  const [authProvider, setAuthProvider] = useState<MsalAuthProvider | null>(null);

  useEffect(() => {
    setIsLoading(true);
    fetchConfig().then((config) => {
      setConfig(config);
      setAuthProvider(createAuthProvider(config));
      setIsLoading(false);
    });
  }, []);

  if (isLoading || config == null || authProvider == null) {
    return <LoadingOverlay loading={true} children={null} />;
  }

  return (
    <ConfigContext.Provider value={{ config }}>
      <AuthContext.Provider value={{ authProvider }}>{props.children}</AuthContext.Provider>
    </ConfigContext.Provider>
  );
};
