import { Form } from 'formik';
import React from 'react';
import styled from 'styled-components/macro';
import { ColourPickerField } from '../../shared/form/ColourPickerField';
import { InputField } from '../../shared/form/input-fields/InputField';
import { ToggleField } from '../../shared/form/ToggleField';
import { ModalActionButtonsSection, ModalTitle } from '../../shared/Modal';

type CreateUpdateTeamFormProps = {
  teamNameInputRef: React.RefObject<HTMLInputElement>;
  formTitle: string;
  children?: React.ReactNode;
};

export const CreateUpdateTeamForm = ({
  teamNameInputRef,
  formTitle,
  children,
}: CreateUpdateTeamFormProps) => (
  <Form>
    <ModalTitle>{formTitle}</ModalTitle>
    <InputSection>
      <InputField
        name="name"
        label="Team Name"
        placeholder="Enter team name..."
        maxLength={32}
        ref={teamNameInputRef}
      />
      <ColourPickerField name="colour" label="Team Colour" />
      <ToggleField name="isActive" label="Active?" />
    </InputSection>
    {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
  </Form>
);

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;
