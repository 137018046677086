// this provider is for general use to store state variables of any purpose

import React, { createContext, useState } from 'react';

type GlobalContextValue = {
  onlyShowCurrentTeamTasks: boolean;
  setOnlyShowCurrentTeamTasks: React.Dispatch<React.SetStateAction<boolean>>;
};

const defaultGlobalContextValue: GlobalContextValue = {
  onlyShowCurrentTeamTasks: true,
  setOnlyShowCurrentTeamTasks: () => {},
};

export const GlobalContext = createContext<GlobalContextValue>(defaultGlobalContextValue);

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [onlyShowCurrentTeamTasks, setOnlyShowCurrentTeamTasks] = useState<boolean>(true);

  return (
    <GlobalContext.Provider value={{ onlyShowCurrentTeamTasks, setOnlyShowCurrentTeamTasks }}>
      {children}
    </GlobalContext.Provider>
  );
};
