import { HubConnection, HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConfettiContext } from '../kanban/confetti/ConfettiContext';
import { KanbanViewUrlParams } from '../kanban/kanban';
import { getUrl } from '../main-page/mainPageUrlParamsHelpers';

export type StandupModeStatus = 'Joined' | 'Joining' | 'Leaving' | 'Not Participating';

export const useStandup = (
  onNewKanbanAvailable: (params: KanbanViewUrlParams) => void,
  onNavigation: (newUrl: string) => void,
) => {
  const { setConfettiEnabled, setLastChangedGoalId } = useContext(ConfettiContext);
  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [standupModeStatus, setStandupModeStatus] = useState<StandupModeStatus>(
    'Not Participating',
  );
  const [teamIds, setTeamIds] = useState<Array<number>>([]);
  const [isPersonalDevelopmentStandup, setPersonalDevelopmentStandup] = useState<boolean>();

  const params = useParams<KanbanViewUrlParams>();

  // Callbacks should only be registered to a connection once. Since the callback requires the urlParams which may
  // change we give the callback a reference to the current value of the urlParams by using the ref from useRef()
  const paramsRef = useRef<KanbanViewUrlParams>(params);
  paramsRef.current = params;

  const joinStandup = (newTeamIds: Array<number>, newIsPersonalDevelopment: boolean) => {
    if (connection != null) {
      return;
    }

    setStandupModeStatus('Joining');
    setTeamIds(newTeamIds);
    setPersonalDevelopmentStandup(newIsPersonalDevelopment);

    // Used to inject the SignalR connection mock during testing with cypress
    const newConnection =
      ((window as any).cypressSignalrMock as HubConnection) ||
      new HubConnectionBuilder().withUrl('/standupHub').build();
    newConnection.on('NewKanbanAvailable', (updatedGoalId: number | undefined) => {
      if (updatedGoalId !== undefined) {
        setLastChangedGoalId(updatedGoalId);
      }
      onNewKanbanAvailable(paramsRef.current);
    });
    newConnection.on('NavigateTo', newUrl => {
      if (newUrl === getUrl(paramsRef.current)) {
        onNewKanbanAvailable(paramsRef.current);
      } else {
        onNavigation(newUrl);
      }
    });
    newConnection.onreconnected(() => {
      newConnection.invoke('AddToGroup', newTeamIds, newIsPersonalDevelopment).then(() => {
        onNewKanbanAvailable(paramsRef.current);
      });
    });
    newConnection.onclose(() => {
      setStandupModeStatus('Not Participating');
      setConnection(null);
    });

    setConnection(newConnection);
    newConnection
      .start()
      .then(() => {
        newConnection.invoke('AddToGroup', newTeamIds, newIsPersonalDevelopment).then(() => {
          setStandupModeStatus('Joined');
        });
      })
      .catch(() => {
        setStandupModeStatus('Not Participating');
        setConnection(null);
      });

    setConfettiEnabled(true);
  };

  const leaveStandup = () => {
    if (connection == null) {
      return;
    }

    setStandupModeStatus('Leaving');

    connection.invoke('RemoveFromGroup', teamIds, isPersonalDevelopmentStandup).then(() => {
      connection.stop().then(() => {
        setStandupModeStatus('Not Participating');
        setConnection(null);
      });
    });

    setConfettiEnabled(false);
  };

  useEffect(
    () => () => {
      if (connection != null) {
        connection.stop();
      }
    },
    [connection],
  );

  const notifyClientsOfGoalUpdate = (goalId: number | undefined) => {
    if (connection == null || connection.state !== HubConnectionState.Connected) {
      return;
    }

    connection.invoke('UpdateKanban', teamIds, isPersonalDevelopmentStandup, goalId);
  };

  const notifyClientsOfNavigation = (newUrl: string) => {
    if (connection == null || connection.state !== HubConnectionState.Connected) {
      return;
    }

    connection.invoke('NavigateTo', newUrl, teamIds, isPersonalDevelopmentStandup);
  };

  return {
    standupModeStatus,
    joinStandup,
    leaveStandup,
    notifyClientsOfGoalUpdate,
    notifyClientsOfNavigation,
  };
};
