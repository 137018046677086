import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Edit } from '../../../images/icons/edit-solid.svg';
import { lessTranslucentWhite, lightGrey, translucentWhite, white } from '../../../styling/colours';
import { largeFont } from '../../../styling/fonts';
import { defaultBorderRadius, massive, mediumNarrow, wide } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';
import { KanbanOptionProps, OptionName, OptionNameContainer, StyledIcon } from './KanbanOptions';

export const NoViewOption = (props: KanbanOptionProps) => {
  const onEditClick = (mouseEvent: React.MouseEvent<HTMLElement>) => {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();

    if (props.onEditClick) {
      props.onEditClick();
    }
  };

  return (
    <Container className={`${props.className} ${props.disabled ? 'disabled' : ''}`}>
      <OptionNameContainer>
        <OptionName>{props.text}</OptionName>
      </OptionNameContainer>
      {!props.removeEdit && (
        <StyledIcon onClick={onEditClick}>
          <Edit />
        </StyledIcon>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${white};
  padding: ${mediumNarrow};
  padding-left: ${massive};
  font-size: ${largeFont};
  border-radius: ${defaultBorderRadius};

  ${StyledIcon} {
    color: transparent;
    cursor: pointer;
  }

  &.disabled {
    cursor: not-allowed;
    color: ${lessTranslucentWhite};
    text-decoration: none;
  }

  &:hover {
    color: ${white};
    background-color: ${translucentWhite};

    ${StyledIcon} {
      color: ${white};

      &:hover {
        color: ${lightGrey};
      }
    }
  }

  transition: all ${regularTransitionDuration} ease;
`;
