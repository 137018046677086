import * as React from 'react';
import { User } from './user';

export type CurrentUserContextValue = {
  currentUser: User | null;
  setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
};

export const CurrentUserContext = React.createContext<CurrentUserContextValue>({
  currentUser: null,
  setCurrentUser: (_) => {
    throw new Error('Cannot set user outside of a <CurrentUserProvider/>');
  },
});

export const CurrentUserProvider = ({ children }: { children: React.ReactNode }) => {
  const [currentUser, setCurrentUser] = React.useState<User | null>(null);

  return (
    <CurrentUserContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </CurrentUserContext.Provider>
  );
};
