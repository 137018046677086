import { cloneDeep, findIndex, keyBy, noop } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components/macro';
import { useGoalAssignmentsApi } from '../hooks/api/useGoalAssignmentsApi';
import { useKanbanApi } from '../hooks/api/useKanbanApi';
import { ErrorBox } from '../shared/ErrorBox';
import { CentredLoadingSpinner } from '../shared/LoadingSpinner';
import { PageContainer } from '../shared/PageContainer';
import { PageHeader } from '../shared/PageHeader';
import { narrow } from '../styling/spacing';
import { assertNotNull } from '../utils/assertNotNull';
import { UpdateGoalAssignmentDto } from './goal-assignments/goalAssignment';
import { GoalStatesContext } from './goal-states/GoalStatesContextProvider';
import { Goal } from './goals/goal';
import { Column, KanbanData, KanbanViewUrlParams } from './kanban/kanban';
import { KanbanBoard } from './kanban/KanbanBoard';
import { ProjectsContext } from './projects/ProjectsContextProvider';
import { getUserFullName } from './users/user';
import { UsersContext } from './users/UsersContextProvider';

const getNewCurrentActiveGoalsAssigneeDoneUpdated = (
  existingGoals: Array<Goal>,
  updateGoalAssignmentDto: UpdateGoalAssignmentDto,
): Array<Goal> => {
  const updatedGoals = cloneDeep(existingGoals);
  const updatedGoal = cloneDeep(
    assertNotNull(existingGoals.find((goal) => goal.goalId === updateGoalAssignmentDto.goalId)),
  );

  const updatedGoalIndex = findIndex(updatedGoals, (goal) => goal.goalId === updatedGoal.goalId);
  const assigneeIndex = findIndex(
    updatedGoal.assignees,
    (assignee) => assignee.userId === updateGoalAssignmentDto.userId,
  );

  updatedGoal.assignees[assigneeIndex].assigneeDone = updateGoalAssignmentDto.assigneeDone;

  updatedGoals[updatedGoalIndex] = updatedGoal;
  return updatedGoals;
};

type TodayGoalsPageProps = RouteComponentProps<KanbanViewUrlParams>;

export const TodayGoalsPage = ({ history, match }: TodayGoalsPageProps) => {
  const [currentActiveGoals, setCurrentActiveGoals] = useState<Array<Goal>>([]);

  const [loading, setLoading] = useState(true);
  const [firstLoad, setFirstLoad] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const { activeUsers, refreshUsers } = useContext(UsersContext);
  const { refreshProjects } = useContext(ProjectsContext);

  const { params } = match;
  const { fetchKanbanData } = useKanbanApi();
  const { updateAssigneeDone } = useGoalAssignmentsApi();

  const getPageData = () => {
    setLoading(true);
    setError(null);

    fetchKanbanData(params)
      .then((response: KanbanData) => {
        Promise.all([refreshUsers(), refreshProjects()]).catch(setError);
        setCurrentActiveGoals(response.goals.filter((goal) => goal.stateCode === 'ACTIVE'));
      })
      .catch((fetchDataError) => {
        if (fetchDataError.message !== 'Aborted') {
          setError(fetchDataError);
        }
      })
      .finally(() => {
        setLoading(false);
        setFirstLoad(false);
      });
  };

  useEffect(getPageData, []);

  const currentActiveGoalsById = keyBy(currentActiveGoals, (goal) => goal.goalId);

  const activeUsersWithCurrentGoals = activeUsers.filter((user) =>
    currentActiveGoals.some((goal) =>
      goal.assignees.some((assignee) => assignee.userId === user.userId),
    ),
  );

  const columnsByUserName: Record<string, Column> = keyBy(
    activeUsersWithCurrentGoals.map((user) => {
      const userFullName = getUserFullName(user);
      const userGoalIds = currentActiveGoals
        .filter((goal) => goal.assignees.some((assignee) => assignee.userId === user.userId))
        .map((goal) => goal.goalId);

      const column: Column = {
        goalStateCode: userFullName,
        isCompleteState: false,
        title: userFullName,
        goalIds: userGoalIds,
      };

      return column;
    }),
    (column) => column.goalStateCode,
  );

  const columnOrder = activeUsersWithCurrentGoals.map((user) => getUserFullName(user)).sort();

  const onUpdateAssigneeDone = (updateGoalAssignmentDto: UpdateGoalAssignmentDto) => {
    setCurrentActiveGoals(
      getNewCurrentActiveGoalsAssigneeDoneUpdated(currentActiveGoals, updateGoalAssignmentDto),
    );

    setError(null);
    updateAssigneeDone(updateGoalAssignmentDto).catch((updateAssigneeDoneError: Error) =>
      setError(updateAssigneeDoneError),
    );
  };

  return (
    <>
      <PageHeader history={history} viewName="Today's Goals" />
      <PageContainer>
        {error && <StyledErrorBox error={error} />}
        {firstLoad ? (
          <CentredLoadingSpinner />
        ) : (
          <KanbanBoard
            urlParams={params}
            goalsById={currentActiveGoalsById}
            columnsById={columnsByUserName}
            columnOrder={columnOrder}
            personalDoneTasksHidden={false}
            getLatestKanbanData={getPageData}
            onKanbanCardDragEnd={noop}
            onUpdateAssigneeDone={onUpdateAssigneeDone}
            loading={loading}
            notifyClientsOfGoalUpdate={noop}
            isViewOnly={true}
          />
        )}
      </PageContainer>
    </>
  );
};

const StyledErrorBox = styled(ErrorBox)`
  margin-bottom: ${narrow};
`;
