import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { useFrameworksApi } from '../../hooks/api/useFrameworksApi';
import { ErrorBox } from '../../shared/ErrorBox';
import { CentredLoadingSpinner } from '../../shared/LoadingSpinner';
import { PageContainer } from '../../shared/PageContainer';
import { PageHeader } from '../../shared/PageHeader';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { CreateFrameworkButton } from './CreateFrameworkModal';
import { CreateFrameworkDto } from './framework';
import { FrameworksContext } from './FrameworksContextProvider';
import { FrameworkTable } from './FrameworkTable';

export type FrameworkPageProps = RouteComponentProps;

const FrameworksPageComponent = ({ history }: FrameworkPageProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  const { frameworks, refreshFrameworks } = useContext(FrameworksContext);
  const { refreshProjects } = useContext(ProjectsContext);
  const { createFramework, deleteFramework, updateFramework } = useFrameworksApi();

  useEffect(() => getLatestData(), []);

  const getLatestData = () => {
    setLoading(true);
    setError(null);
    Promise.all([refreshProjects, refreshFrameworks])
      .catch(setError)
      .finally(() => setLoading(false));
  };

  const onCreateFramework = (createFrameworkDto: CreateFrameworkDto) =>
    createFramework(createFrameworkDto)
      .then(() => getLatestData())
      .catch(setError);

  const onUpdateFramework = (updateFrameworkDto: CreateFrameworkDto) =>
    updateFramework(updateFrameworkDto)
      .then(() => getLatestData())
      .catch(setError);

  const onDeleteFramework = (frameworkId: number) =>
    deleteFramework(frameworkId)
      .then(() => getLatestData())
      .catch(setError);

  return (
    <>
      <PageHeader viewName="Frameworks" history={history} />
      <Container>
        {error && <ErrorBox error={error} />}
        {loading ? (
          <CentredLoadingSpinner />
        ) : (
          <>
            <FrameworkTable
              frameworks={frameworks}
              onUpdateFramework={onUpdateFramework}
              onDeleteFramework={onDeleteFramework}
            />
            <CreateFrameworkButton onCreateFramework={onCreateFramework} />
          </>
        )}
      </Container>
    </>
  );
};

export const FrameworksPage = withRouter(FrameworksPageComponent);

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
