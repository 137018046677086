import React from 'react';
import styled from 'styled-components/macro';

type ShortcutKeyProps = {
  children?: React.ReactNode;
};

export const ShortcutKey = ({ children }: ShortcutKeyProps) => {
  return <StyledKbd>{children}</StyledKbd>;
};

const StyledKbd = styled.kbd`
  background: #fff;
  padding: 3px 5px;
  border: 1px solid rgb(70, 70, 70);
  border-radius: 6px;
  box-shadow: rgb(70, 70, 70) 0px -1px 0px inset;
`;
