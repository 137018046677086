import { clone, cloneDeep, difference, find, findIndex, values } from 'lodash';
import { UpdateGoalAssignmentDto } from '../goal-assignments/goalAssignment';
import { GoalState, GoalStateCode } from '../goal-states/goalState';
import { getIncompleteGoalsIds, Goal } from '../goals/goal';
import { Column } from '../kanban/kanban';
import { StandupModeStatus } from '../standup/useStandup';
import { UpdateUserHideDoneTasksDto, User } from '../users/user';
import { MainPageGoalsState } from './MainPage';

export const getNewMainPageGoalsStateCardMoved = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  goalStates: Array<GoalState>,
  movementOnSameColumn: boolean,
  sourceCardIndex: number,
  destinationCardIndex: number,
  sourceColumn: Column,
  destinationColumn: Column,
  goalId: number,
  user: User | null,
  standupModeStatus: StandupModeStatus,
): MainPageGoalsState =>
  movementOnSameColumn
    ? getNewMainPageGoalsStateCardSwapOnColumn(
        existingState,
        goalsById,
        sourceCardIndex,
        destinationCardIndex,
        sourceColumn,
        goalId,
        user,
        standupModeStatus,
      )
    : getNewMainPageGoalsStateCardSwapAcrossColumns(
        existingState,
        goalsById,
        goalStates,
        sourceCardIndex,
        destinationCardIndex,
        sourceColumn,
        destinationColumn,
        goalId,
        user,
        standupModeStatus,
      );

const getNewMainPageGoalsStateCardSwapOnColumn = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  sourceCardIndex: number,
  destinationCardIndex: number,
  sourceColumn: Column,
  goalId: number,
  user: User | null,
  standupModeStatus: StandupModeStatus,
): MainPageGoalsState => {
  const boardIncludesHiddenGoals: boolean =
    user == null ? false : user.isHidingDoneTasks && standupModeStatus === 'Not Participating';

  const visibleSourceColumnGoalIds = boardIncludesHiddenGoals
    ? getIncompleteGoalsIds(sourceColumn.goalIds, goalsById, user && user.userId)
    : clone(sourceColumn.goalIds);

  visibleSourceColumnGoalIds.splice(sourceCardIndex, 1);
  visibleSourceColumnGoalIds.splice(destinationCardIndex, 0, goalId);

  const updatedSourceColumnGoalIds = boardIncludesHiddenGoals
    ? visibleSourceColumnGoalIds.concat(
        difference(sourceColumn.goalIds, visibleSourceColumnGoalIds),
      )
    : visibleSourceColumnGoalIds;

  const updatedSourceColumn: Column = {
    ...sourceColumn,
    goalIds: updatedSourceColumnGoalIds,
  };

  return {
    ...existingState,
    columnsByGoalStateCode: {
      ...existingState.columnsByGoalStateCode,
      [updatedSourceColumn.goalStateCode]: updatedSourceColumn,
    },
  };
};

const getNewMainPageGoalsStateCardSwapAcrossColumns = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  goalStates: Array<GoalState>,
  sourceCardIndex: number,
  destinationCardIndex: number,
  sourceColumn: Column,
  destinationColumn: Column,
  goalId: number,
  user: User | null,
  standupModeStatus: StandupModeStatus,
): MainPageGoalsState => {
  const updatedGoal: Goal = getUpdatedGoal(
    existingState,
    goalsById,
    goalStates,
    goalId,
    destinationColumn.goalStateCode,
  );

  return {
    goals: getUpdatedGoals(existingState, updatedGoal),
    columnsByGoalStateCode: getUpdatedColumnsByStateCode(
      existingState,
      goalsById,
      sourceCardIndex,
      destinationCardIndex,
      sourceColumn,
      destinationColumn,
      goalId,
      user,
      standupModeStatus,
    ),
  };
};

const getUpdatedGoal = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  goalStates: Array<GoalState>,
  goalId: number,
  newGoalStateCode: string,
): Goal => {
  const updatedGoal: Goal = cloneDeep(goalsById[goalId]);

  const newGoalState: GoalState = find(
    goalStates,
    (goalState) => goalState.stateCode === newGoalStateCode,
  ) as GoalState;

  updatedGoal.stateCode = newGoalState.stateCode;

  return updatedGoal;
};

const getUpdatedGoals = (existingState: MainPageGoalsState, updatedGoal: Goal): Array<Goal> => {
  const updatedGoals = cloneDeep(existingState.goals);
  const goalsArrayIndex = findIndex(
    existingState.goals,
    (goal) => goal.goalId === updatedGoal.goalId,
  );
  updatedGoals[goalsArrayIndex] = updatedGoal;

  return updatedGoals;
};

const getUpdatedColumnsByStateCode = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  sourceCardIndex: number,
  destinationCardIndex: number,
  sourceColumn: Column,
  destinationColumn: Column,
  goalId: number,
  user: User | null,
  standupModeStatus: StandupModeStatus,
): { [stateCode: string]: Column } => {
  const boardIncludesHiddenGoals: boolean =
    user == null ? false : user.isHidingDoneTasks && standupModeStatus === 'Not Participating';

  return {
    ...existingState.columnsByGoalStateCode,
    [sourceColumn.goalStateCode]: getUpdatedSourceColumn(
      existingState,
      goalsById,
      sourceCardIndex,
      sourceColumn,
      goalId,
      user && user.userId,
      boardIncludesHiddenGoals,
    ),
    [destinationColumn.goalStateCode]: getUpdatedDestinationColumn(
      existingState,
      goalsById,
      destinationCardIndex,
      destinationColumn,
      goalId,
      user && user.userId,
      boardIncludesHiddenGoals,
    ),
  };
};

const getUpdatedSourceColumn = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  sourceCardIndex: number,
  sourceColumn: Column,
  goalId: number,
  userId: number | null,
  boardIncludesHiddenGoals: boolean,
): Column => {
  const visibleSourceColumnGoalIds = boardIncludesHiddenGoals
    ? getIncompleteGoalsIds(sourceColumn.goalIds, goalsById, userId)
    : clone(sourceColumn.goalIds);

  visibleSourceColumnGoalIds.splice(sourceCardIndex, 1);

  const updatedSourceColumnGoalIds = boardIncludesHiddenGoals
    ? visibleSourceColumnGoalIds.concat(
        difference(sourceColumn.goalIds, visibleSourceColumnGoalIds.concat(goalId)),
      )
    : visibleSourceColumnGoalIds;

  return {
    ...sourceColumn,
    goalIds: updatedSourceColumnGoalIds,
  };
};

const getUpdatedDestinationColumn = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  destinationCardIndex: number,
  destinationColumn: Column,
  goalId: number,
  userId: number | null,
  boardIncludesHiddenGoals: boolean,
): Column => {
  const visibleDestinationColumnGoalIds = boardIncludesHiddenGoals
    ? getIncompleteGoalsIds(destinationColumn.goalIds, goalsById, userId)
    : clone(destinationColumn.goalIds);

  visibleDestinationColumnGoalIds.splice(destinationCardIndex, 0, goalId);

  const updatedDestinationColumnGoalIds = boardIncludesHiddenGoals
    ? visibleDestinationColumnGoalIds.concat(
        difference(destinationColumn.goalIds, visibleDestinationColumnGoalIds),
      )
    : visibleDestinationColumnGoalIds;

  return {
    ...destinationColumn,
    goalIds: updatedDestinationColumnGoalIds,
  };
};

export const getNewMainPageGoalsStateAssigneeDoneUpdated = (
  existingState: MainPageGoalsState,
  goalsById: { [goalId: number]: Goal },
  updateGoalAssignmentDto: UpdateGoalAssignmentDto,
): MainPageGoalsState => {
  const updatedGoal = cloneDeep(goalsById[updateGoalAssignmentDto.goalId]);
  const assigneeIndex = findIndex(
    updatedGoal.assignees,
    (assignee) => assignee.userId === updateGoalAssignmentDto.userId,
  );

  updatedGoal.assignees[assigneeIndex].assigneeDone = updateGoalAssignmentDto.assigneeDone;

  return {
    ...existingState,
    goals: values({
      ...goalsById,
      [updatedGoal.goalId]: updatedGoal,
    }),
  };
};

export const getNewActiveUsersOnUpdateUserHideDoneTasks = (
  activeUsers: Array<User>,
  updateUserHideDoneTasksDto: UpdateUserHideDoneTasksDto,
): Array<User> => {
  const updatedUsers = cloneDeep(activeUsers);
  const userIndex = findIndex(
    updatedUsers,
    (user) => user.userId === updateUserHideDoneTasksDto.userId,
  );

  updatedUsers[userIndex].isHidingDoneTasks = updateUserHideDoneTasksDto.isHidingDoneTasks;

  return updatedUsers;
};
