import { Form } from 'formik';
import React, { useContext, useMemo } from 'react';
import styled from 'styled-components/macro';
import { ColourPickerField } from '../../shared/form/ColourPickerField';
import { InputField } from '../../shared/form/input-fields/InputField';
import { SelectField } from '../../shared/form/select-fields/SelectField';
import { ToggleField } from '../../shared/form/ToggleField';
import { ModalActionButtonsSection, ModalTitle } from '../../shared/Modal';
import { Team } from '../teams/team';
import { TeamsContext } from '../teams/TeamsContextProvider';
import {
  mapTeamsToSelectOptions,
  mapTeamToSelectOption,
  teamSelectStyles,
} from '../teams/teamSelectUtils';

type CreateUpdateUserFormProps = {
  chosenNameInputRef: React.RefObject<HTMLInputElement>;
  formTitle: string;
  children?: React.ReactNode;
};

export const CreateUpdateUserForm = ({
  chosenNameInputRef,
  formTitle,
  children,
}: CreateUpdateUserFormProps) => {
  const { activeTeams } = useContext(TeamsContext);

  const teamOptions = useMemo(() => mapTeamsToSelectOptions(activeTeams), [activeTeams]);

  return (
    <Form>
      <ModalTitle>{formTitle}</ModalTitle>
      <InputSection>
        <InputField
          name="chosenName"
          label="Chosen Name"
          placeholder="Enter chosen name..."
          maxLength={32}
          ref={chosenNameInputRef}
        />
        <InputField
          name="familyName"
          label="Family Name"
          optional={true}
          placeholder="Enter family name..."
          maxLength={32}
        />
        <InputField
          name="email"
          label="Email"
          placeholder="Enter email address..."
          maxLength={128}
          spellcheck={false}
        />
        <SelectField<Team>
          name="team"
          label="Team"
          placeholder="Select a team..."
          options={teamOptions}
          mapValueToSelectOption={mapTeamToSelectOption}
          mapOptionToKey={(option) => option.value.toString()}
          styles={teamSelectStyles}
        />
        <ColourPickerField name="colour" label="User Colour" />
        <ToggleField name="isActive" label="Active?" />
      </InputSection>
      {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
    </Form>
  );
};

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;
