import { FieldProps } from 'formik';
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';
import { lightFontFamily } from '../../../styling/fonts';
import { toDate, toDateStamp } from '../../../utils/dateStamp';
import { FormField } from '../FormField';
import { inputStyles } from './inputStyles';

type DateFieldProps = {
  name: string;
  label?: string;
  optional?: boolean;
  className?: string;
  disabled?: boolean;
};

export const DateField = (props: DateFieldProps) => {
  const onChange = ({ form, field }: FieldProps) => (newValue: Date | null) => {
    form.setFieldValue(field.name, newValue ? toDateStamp(newValue) : newValue);
  };

  return (
    <FormField
      name={props.name}
      label={props.label}
      optional={props.optional}
      className={props.className}
    >
      {({ field, form }) => (
        <DateInput
          selected={field.value ? toDate(field.value) : null}
          onChange={onChange({ form, field })}
          dateFormat="d-MMM-yy"
          locale="en-GB"
          placeholderText="Select a date..."
          disabled={props.disabled}
        />
      )}
    </FormField>
  );
};

export const DateInput = styled(DatePicker)`
  ${() => inputStyles({})};
  font-family: ${lightFontFamily};
`;
