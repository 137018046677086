import { keyBy, map } from 'lodash';
import { ApiRequest, fetchJson, from, withJsonBody } from '../../utils/api';
import { assertNotNull } from '../../utils/assertNotNull';
import { getUserFullName, User } from '../users/user';

export type Assignee = User & {
  goalId: number | null;
  assigneeDone: boolean;
};

export type UpdateGoalAssignmentDto = {
  userId: number;
  goalId: number;
  assigneeDone: boolean;
};

export type CreateGoalAssignmentDto = {
  userId: number;
  goalId: number | null;
  assigneeDone: boolean;
};

export const toUpdateGoalAssignmentDto = (
  assignees: Array<Assignee>,
): Array<UpdateGoalAssignmentDto> =>
  assignees.map((assignee) => ({
    userId: assignee.userId,
    goalId: assertNotNull(assignee.goalId),
    assigneeDone: assignee.assigneeDone,
  }));

export const toCreateGoalAssignmentDto = (
  assignees: Array<Assignee>,
): Array<CreateGoalAssignmentDto> =>
  assignees.map((assignee) => ({
    userId: assignee.userId,
    goalId: assignee.goalId,
    assigneeDone: assignee.assigneeDone,
  }));

export const toAssignee = (user: User, goalId: number | null): Assignee => ({
  ...user,
  goalId,
  assigneeDone: false,
});

export const resetAssigneesDone = (assignees: Array<Assignee>): Array<Assignee> =>
  assignees.map((assignee) => ({
    ...assignee,
    assigneeDone: false,
  }));

export type AssigneesDoneById = { [userId: number]: { userId: number; assigneeDone: boolean } };

export const getAssigneesDoneById = (assignees: Array<Assignee>): AssigneesDoneById =>
  keyBy(
    map(assignees, (assignee) => ({
      userId: assignee.userId,
      assigneeDone: assignee.assigneeDone,
    })),
    'userId',
  );

export const getAssigneeFullName = (assignee: Assignee): string => {
  const { goalId, assigneeDone, ...userAttributes } = assignee;

  return getUserFullName(userAttributes);
};
