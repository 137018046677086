import { AuthenticationParameters, Configuration } from 'msal';
import { IMsalAuthProviderConfig, LoginType, MsalAuthProvider } from 'react-aad-msal';
import { Config } from '../config/config';
export const createAuthProvider = (config: Config) => {
  const msalConfig: Configuration = {
    auth: {
      clientId: config.clientId,
      authority: 'https://login.microsoftonline.com/ghyston.com/',
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: false,
    },
  };

  const authenticationParameters: AuthenticationParameters = {
    scopes: [`api://${config.clientId}/default`, 'openid'],
  };

  const options: IMsalAuthProviderConfig = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/token-refresh.html',
  };

  return new MsalAuthProvider(msalConfig, authenticationParameters, options);
};
