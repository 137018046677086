import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { User } from '../users/user';
import { Assignee, getAssigneeFullName, toAssignee } from './goalAssignment';

export const mapUsersToAssigneeSelectOptions = (
  users: Array<User>,
  goalId: number | null,
): Array<SelectOption<Assignee>> =>
  users.map(user => ({
    value: toAssignee(user, goalId),
    label: `${user.chosenName} ${user.familyName}`,
  }));

export const mapAssigneesToSelectOptions = (
  users: Array<Assignee>,
): Array<SelectOption<Assignee>> =>
  users.map(assignee => ({
    value: assignee,
    label: getAssigneeFullName(assignee),
  }));
