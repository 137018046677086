import React from 'react';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import styled from 'styled-components/macro';
import { darkGrey, darkRed, red } from '../styling/colours';

export type LinkProps = RouterLinkProps & {
  disabled?: boolean;
};

// eslint-disable-next-line no-unexpected-multiline
export const Link = styled(({ disabled, ...props }: LinkProps) =>
  disabled ? <div className={props.className}>{props.children}</div> : <RouterLink {...props} />,
)<{
  disabled?: boolean;
}>`
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  color: ${props => (props.disabled ? darkGrey : red)};
  text-decoration: none;

  &:hover {
    color: ${props => (props.disabled ? darkGrey : darkRed)};
  }
`;
