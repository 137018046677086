import React from 'react';
import styled from 'styled-components/macro';
import LogoUrl from '../images/tapas-logo.svg';
import { Link } from '../shared/Link';
import { pageVerticalPadding } from '../styling/spacing';
import { homeUrl } from '../urls';

type fourOhFourProps = {
  errorMessage: string;
};

export class FourOhFour extends React.Component<fourOhFourProps, {}> {
  render() {
    return (
      <FourOhFourPage>
        <HomeLink to={homeUrl()}>
          <Logo src={LogoUrl} alt="Tapas Logo" />
          (Click logo to return to Home)
        </HomeLink>
        <h1>404 Error: {this.props.errorMessage}</h1>
      </FourOhFourPage>
    );
  }
}

const FourOhFourPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  padding: ${`80px`} 0 ${pageVerticalPadding} 0;
  width: 100%;
  height: 45%;
`;

const HomeLink = styled(Link)`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  color: inherit;
`;

const Logo = styled.img`
  height: 250px;
  padding: 10px 0 10px 0;
`;
