import { useCallback } from 'react';
import { CreateGoalDto, Goal, UpdateGoalDto } from '../../features/goals/goal';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useGoalsApi = () => {
  const createGoalAuthorisedFetch = useAuthorisedFetch<Goal, CreateGoalDto>('post');
  const createGoal = useCallback(
    (createGoalDto) => createGoalAuthorisedFetch('goals', createGoalDto),
    [createGoalAuthorisedFetch],
  );

  const updateGoalAuthorisedFetch = useAuthorisedFetch<Goal, UpdateGoalDto>('put');
  const updateGoal = useCallback(
    (updateGoalDto) => updateGoalAuthorisedFetch(`goals/${updateGoalDto.goalId}`, updateGoalDto),
    [updateGoalAuthorisedFetch],
  );

  const deleteGoalAuthorisedFetch = useAuthorisedFetch<null>('delete');
  const deleteGoal = useCallback(
    (goalId) => deleteGoalAuthorisedFetch(`goals/${goalId}`),
    [deleteGoalAuthorisedFetch],
  );

  return { createGoal, updateGoal, deleteGoal };
};
