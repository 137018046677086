import React from 'react';
import { registerLocale } from 'react-datepicker';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App';
import { unregister } from './registerServiceWorker';
import { AuthenticationContext } from './shared/auth/AuthenticationContext';
import { assertNotNull } from './utils/assertNotNull';

const enGB = require('react-datepicker/node_modules/date-fns/locale/en-GB'); // tslint:disable-line:no-var-requires
registerLocale('en-GB', enGB.default);

const baseUrl = assertNotNull(document.getElementsByTagName('base')[0].getAttribute('href'));
const rootElement = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter basename={baseUrl}>
    <AuthenticationContext>
      <App />
    </AuthenticationContext>
  </BrowserRouter>,
  rootElement,
);

// This must be left here for anyone that accessed the site when this used to register
// a service worker. The service worker cached the js, meaning you had to empty cache
// and hard reload in order to see changes on the live site.
unregister();
