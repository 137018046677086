import { assertNotNull } from '../../utils/assertNotNull';
import { User } from '../users/user';

export type StatusEmailReceiver = User & {
  projectId: number | null;
};

export type CreateProjectStatusEmailReceiverDto = {
  projectId: number | null;
  userId: number;
};

export type UpdateProjectStatusEmailReceiverDto = {
  projectId: number;
  userId: number;
};

export const toCreateProjectStatusEmailReceiverDto = (
  emailReceivers: Array<StatusEmailReceiver>,
): Array<CreateProjectStatusEmailReceiverDto> =>
  emailReceivers.map(receiver => ({
    projectId: receiver.projectId,
    userId: receiver.userId,
  }));

export const toUpdateProjectStatusEmailReceiverDto = (
  emailReceivers: Array<StatusEmailReceiver>,
): Array<UpdateProjectStatusEmailReceiverDto> =>
  emailReceivers.map(receiver => ({
    projectId: assertNotNull(receiver.projectId),
    userId: receiver.userId,
  }));

export const toStatusEmailReceiver = (
  user: User,
  projectId: number | null,
): StatusEmailReceiver => ({
  ...user,
  projectId,
});
