import { Validator } from 'fluentvalidation-ts';
import { colourHexRegEx, getContrastedColour } from '../../../styling/colours';
import { Team } from '../../teams/team';
import { UpdateUserDto } from '../user';

export type UpdateUserFormModel = {
  userId: number;
  chosenName: string;
  familyName: string;
  email: string;
  colour: string;
  isActive: boolean;
  team: Team;
};

export class UpdateUserFormValidator extends Validator<UpdateUserFormModel> {
  constructor() {
    super();

    this.ruleFor('chosenName')
      .notEmpty()
      .maxLength(32);

    this.ruleFor('familyName').maxLength(32);

    this.ruleFor('email')
      .notEmpty()
      .emailAddress()
      .maxLength(128);

    this.ruleFor('colour')
      .notEmpty()
      .matches(colourHexRegEx(true))
      .withMessage('Must be a 3 or 6 digit hex value');

    this.ruleFor('team').notNull();
  }
}

export const toUpdateUserDto = (formModel: UpdateUserFormModel): UpdateUserDto => ({
  userId: formModel.userId,
  chosenName: formModel.chosenName,
  familyName: formModel.familyName,
  email: formModel.email,
  colour: formModel.colour.trim().toUpperCase(),
  contrastColour: getContrastedColour(formModel.colour.trim()),
  isActive: formModel.isActive,
  teamId: formModel.team.teamId,
});
