import { last } from 'lodash';
import React, { useContext, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components/macro';
import { GlobalContext } from '../../GlobalProvider';
import { useGoalsApi } from '../../hooks/api/useGoalsApi';
import { ErrorBox } from '../../shared/ErrorBox';
import { narrow } from '../../styling/spacing';
import { doCaging } from '../../utils/cage';
import { UpdateGoalAssignmentDto } from '../goal-assignments/goalAssignment';
import {
  CreateGoalDto,
  getCurrentTeamOnlyGoals,
  getIncompleteGoals,
  Goal,
  UpdateGoalDto,
} from '../goals/goal';
import { CurrentUserContext } from '../users/CurrentUserProvider';
import { ConfettiContext } from './confetti/ConfettiContext';
import { Column, KanbanViewUrlParams } from './kanban';
import { KanbanColumn } from './KanbanColumn';

type KanbanBoardProps = {
  urlParams: KanbanViewUrlParams;
  goalsById: { [goalId: number]: Goal };
  columnsById: { [columnName: string]: Column };
  columnOrder: Array<string>;
  personalDoneTasksHidden: boolean;
  getLatestKanbanData: () => void;
  onKanbanCardDragEnd: (result: DropResult) => void;
  onUpdateAssigneeDone: (updateGoalAssignmentDto: UpdateGoalAssignmentDto) => void;
  loading: boolean;
  notifyClientsOfGoalUpdate: (goalId: number | undefined) => void;
  isViewOnly?: boolean;
};

export const KanbanBoard = (props: KanbanBoardProps) => {
  const [error, setError] = useState<Error | null>(null);
  const { setLastChangedGoalId } = useContext(ConfettiContext);
  const { currentUser } = useContext(CurrentUserContext);
  const { onlyShowCurrentTeamTasks } = useContext(GlobalContext);

  const { createGoal, updateGoal, deleteGoal } = useGoalsApi();

  const refreshClients = () => {
    props.getLatestKanbanData();
    props.notifyClientsOfGoalUpdate(undefined);
  };

  const onCreateGoal = (createGoalDto: CreateGoalDto) => {
    setError(null);

    createGoal(createGoalDto).then(refreshClients).catch(setError);
    doCaging(createGoalDto, createGoal, refreshClients, setError);
  };

  const onUpdateGoal = (updateGoalDto: UpdateGoalDto) => {
    setError(null);

    updateGoal(updateGoalDto)
      .then(() => {
        setLastChangedGoalId(updateGoalDto.goalId);
        refreshClients();
      })
      .catch(setError);
  };

  const onCompleteGoalAndDuplicate = (
    updateGoalDto: UpdateGoalDto,
    createGoalDto: CreateGoalDto,
  ) => {
    setError(null);

    updateGoal(updateGoalDto)
      .then(() => {
        setLastChangedGoalId(updateGoalDto.goalId);
        createGoal(createGoalDto).then(refreshClients).catch(setError);
      })
      .catch(setError);
  };

  const onDeleteGoal = (goalId: number) => {
    setError(null);

    deleteGoal(goalId).then(refreshClients).catch(setError);
  };

  const getGoalsForColumn = (column: Column) => {
    let goals: Array<Goal> = column.goalIds.map((goalId) => props.goalsById[goalId]);

    if (!!props.urlParams.userId && props.personalDoneTasksHidden) {
      goals = getIncompleteGoals(goals, Number(props.urlParams.userId));
    }

    if (currentUser != null && onlyShowCurrentTeamTasks) {
      goals = getCurrentTeamOnlyGoals(goals, currentUser.team.teamId);
    }

    return goals;
  };

  return (
    <>
      {error && <StyledErrorBox error={error} />}
      <DragDropContext onDragEnd={props.onKanbanCardDragEnd}>
        <ContentsContainer>
          {props.columnOrder.map((columnId) => {
            const column: Column = props.columnsById[columnId];
            const goals = getGoalsForColumn(column);

            return (
              <KanbanColumn
                key={column.goalStateCode}
                urlParams={props.urlParams}
                column={column}
                goals={goals}
                onUpdateAssigneeDone={props.onUpdateAssigneeDone}
                onCreateGoal={onCreateGoal}
                onUpdateGoal={onUpdateGoal}
                onCompleteGoalAndDuplicate={onCompleteGoalAndDuplicate}
                onDeleteGoal={onDeleteGoal}
                loading={props.loading}
                isLastColumn={columnId === last(props.columnOrder)}
                isViewOnly={!!props.isViewOnly}
              />
            );
          })}
        </ContentsContainer>
      </DragDropContext>
    </>
  );
};

const StyledErrorBox = styled(ErrorBox)`
  margin-bottom: ${narrow};
`;

const ContentsContainer = styled.div`
  display: flex;
  width: 100%;
`;
