import React, { createContext, PropsWithChildren, useState } from 'react';
import { GlobalStyles } from './styling/GlobalStyles';

type GlobalStylesContextValue = {
  isKanbanSettingsVisible: boolean;
  setIsKanbanSettingsVisible: (isVisible: boolean) => void;
  isModalVisible: boolean;
  setIsModalVisible: (isVisible: boolean) => void;
};

const GlobalStylesContextDefaultValue: GlobalStylesContextValue = {
  isKanbanSettingsVisible: false,
  setIsKanbanSettingsVisible: (_) => {
    throw new Error(`Cannot set isKanbanSettingsVisible from outside GlobalStylesContextProvider`);
  },
  isModalVisible: false,
  setIsModalVisible: (_) => {
    throw new Error(`Cannot set isModalVisible from outside GlobalStylesContextProvider`);
  },
};

export const GlobalStylesContext = createContext<GlobalStylesContextValue>(
  GlobalStylesContextDefaultValue,
);

export const GlobalStylesProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isKanbanSettingsVisible, setIsKanbanSettingsVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  return (
    <GlobalStylesContext.Provider
      value={{
        isKanbanSettingsVisible,
        setIsKanbanSettingsVisible,
        isModalVisible,
        setIsModalVisible,
      }}
    >
      <GlobalStyles
        isKanbanSettingsVisible={isKanbanSettingsVisible}
        isModalVisible={isModalVisible}
      />
      {children}
    </GlobalStylesContext.Provider>
  );
};
