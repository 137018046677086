import { includes } from 'lodash';
import { Omit } from 'react-router';
import * as request from 'superagent';
import { SuperAgentRequest } from 'superagent';

export type HttpMethod = 'delete' | 'get' | 'post' | 'put';

export type ApiResponse<T> = Omit<request.Response, 'body'> & { body: T };
export type ApiRequest<T> = SuperAgentRequest & Promise<ApiResponse<T>>;

export type ErrorResponse = {
  errorMessage: string;
  statusCode: number;
};

export type ErrorResponseBody = {
  type: string;
  message: string;
  userVisibleMessage: string;
  stackTrace: string;
};

const requestTimeout = 60000;

export const fetchJson = <T>(req: SuperAgentRequest): ApiRequest<T> =>
  req
    .set('Accept', 'application/json')
    .set('Cache-Control', 'no-cache')
    .set('Pragma', 'no-cache')
    .timeout(requestTimeout);

export const withJsonBody =
  <T extends string | object>(body: T) =>
  (req: SuperAgentRequest): ApiRequest<T> =>
    req.set('Content-Type', 'application/json').send(body);

export const from = (url: string, method: HttpMethod): SuperAgentRequest =>
  request[method](`/api/${url}`);

export type HttpError<T> = Error & {
  status: number;
  response: ApiResponse<T>;
};

export type ApiErrorResponse = {
  message: string;
  userVisibleMessage: string | null;
};

export type ApiError = HttpError<ApiErrorResponse>;

export const isHttpError = (error: Error): error is HttpError<unknown> =>
  (error as HttpError<unknown>).status != null && (error as HttpError<unknown>).response != null;

export const isApiError = (error: Error): error is ApiError =>
  isHttpError(error) &&
  error.response.body != null &&
  (error.response.body as ApiErrorResponse).message != null;

export const getUserVisibleMessage = (error: Error): string | null =>
  isApiError(error) ? error.response.body.userVisibleMessage || null : null;

export const assertResponseIsSuccessful = async (response: Response) => {
  if (!response.ok) {
    const error = await parseJson<ErrorResponseBody>(response);

    throw {
      errorMessage: error.userVisibleMessage,
      statusCode: response.status,
    } as ErrorResponse;
  }
};

export const createRequestInit = <TRequest>(
  httpMethod: HttpMethod,
  accessToken?: string,
  body?: TRequest,
): RequestInit =>
  ({
    headers: {
      Accept: 'application/json',
      Authorization: accessToken ? `Bearer ${accessToken}` : undefined,
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
      Pragma: 'no-cache',
    },
    method: httpMethod,
    body: body ? JSON.stringify(body) : undefined,
  }) as RequestInit;

export const parseJson = <T>(response: Response): Promise<T> => {
  return includes(response.headers.get('content-type') as string, 'application/json')
    ? response.json()
    : response.text();
};
