import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { ProjectFramework, toProjectFramework } from '../project-frameworks/projectFrameworks';
import { Framework, FrameworkPageProject } from './framework';

export const mapFrameworksToProjectFrameworksSelectOptions = (
  frameworks: Array<Framework>,
  projectId: number | null,
): Array<SelectOption<ProjectFramework>> =>
  frameworks.map((framework) => ({
    value: toProjectFramework(framework, projectId),
    label: framework.name,
  }));

export const mapFrameworkPageProjectsToSelectOptions = (
  projects: Array<FrameworkPageProject>,
): Array<SelectOption<FrameworkPageProject>> =>
  projects?.map(mapFrameworkPageProjectToSelectOption) || [];

export const mapFrameworkPageProjectToSelectOption = (
  project: FrameworkPageProject,
): SelectOption<FrameworkPageProject> => ({
  value: project,
  label: project.name,
});
