import { useCallback } from 'react';
import {
  CreateUserDto,
  UpdateUserDto,
  UpdateUserHideDoneTasksDto,
  User,
} from '../../features/users/user';
import { useAuthorisedFetch } from './useAuthorisedFetch';

export const useUsersApi = () => {
  const fetchCurrentUserAuthorisedFetch = useAuthorisedFetch<User>('get');
  const fetchCurrentUser = useCallback(
    () => fetchCurrentUserAuthorisedFetch('users/me'),
    [fetchCurrentUserAuthorisedFetch],
  );

  const fetchUsersAuthorisedFetch = useAuthorisedFetch<Array<User>>('get');
  const fetchUsers = useCallback(
    () => fetchUsersAuthorisedFetch('users'),
    [fetchUsersAuthorisedFetch],
  );

  const createUserAuthorisedFetch = useAuthorisedFetch<User, CreateUserDto>('post');
  const createUser = useCallback(
    (createUserDto) => createUserAuthorisedFetch('users', createUserDto),
    [createUserAuthorisedFetch],
  );

  const updateUserAuthorisedFetch = useAuthorisedFetch<User, UpdateUserDto>('put');
  const updateUser = useCallback(
    (updateUserDto) => updateUserAuthorisedFetch('users', updateUserDto),
    [updateUserAuthorisedFetch],
  );

  const updateUserHideDoneTasksAuthorisedFetch = useAuthorisedFetch<
    User,
    UpdateUserHideDoneTasksDto
  >('put');
  const updateUserHideDoneTasks = useCallback(
    (update) =>
      updateUserHideDoneTasksAuthorisedFetch(
        `users/${update.userId}/update-hide-done-tasks`,
        update,
      ),
    [updateUserHideDoneTasksAuthorisedFetch],
  );

  return {
    fetchCurrentUser,
    fetchUsers,
    createUser,
    updateUser,
    updateUserHideDoneTasks,
  };
};
