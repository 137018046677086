import styled, { css, keyframes } from 'styled-components';
import { ReactComponent as Moose } from '../images/icons/moose-spinner.svg';
import { darkGrey } from '../styling/colours';

const animation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(720deg); }
`;

export const loadingSpinnerAnimation = css`
  ${animation} 1.5s ease infinite
`;

export const LoadingSpinner = styled(Moose)<{ colour?: string }>`
  animation: ${loadingSpinnerAnimation};
  color: ${props => props.colour || darkGrey};

  width: 70px;
  height: 70px;
`;

export const CentredLoadingSpinner = styled(LoadingSpinner)`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 10;
`;
