import { keyBy, noop } from 'lodash';
import React, { useEffect, useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import logoUrl from '../images/tapas-logo.svg';
import MarkdownTextBox from '../shared/MarkdownTextBox';
import { PageContainer } from '../shared/PageContainer';
import { PageHeader } from '../shared/PageHeader';
import { ShortcutKey } from '../shared/ShortcutKey';
import { ShortcutList } from '../shared/ShortcutList';
import { Table, TBody, Td, Th, THead, Tr } from '../shared/Table';
import { medium } from '../styling/spacing';
import { toDateStamp } from '../utils/dateStamp';
import { GoalState } from './goal-states/goalState';
import { GoalStatesContext } from './goal-states/GoalStatesContextProvider';
import { Goal } from './goals/goal';
import { HelpModal } from './HelpModal';
import { Column } from './kanban/kanban';
import { KanbanBoard } from './kanban/KanbanBoard';
import { Project } from './projects/project';
import { ProjectsContext } from './projects/ProjectsContextProvider';
import { Team } from './teams/team';
import { User } from './users/user';
import { UsersContext } from './users/UsersContextProvider';

type HelpProps = RouteComponentProps;

const fakeKanbanTeam: Team = {
  teamId: 1,
  name: 'Fake Team',
  colour: '#ffaa12',
  contrastColour: '#000000',
  isActive: true,
};

const fakeKanbanUser: User = {
  userId: 1,
  chosenName: 'Tapas',
  familyName: 'user 👤',
  email: 'john@example.com',
  colour: '#bcc826',
  contrastColour: '#000000',
  isActive: true,
  isHidingDoneTasks: false,
  team: fakeKanbanTeam,
};

const fakeKanbanProject: Project = {
  projectId: 1,
  name: 'Markdown',
  colour: '#f05a24',
  contrastColour: '#FFF',
  externalTicketUrlTemplate: '',
  jiraKeys: '',
  isActive: true,
  teamMailingListEmail: '',
  additionalStatusEmailReceivers: [],
  spreadsheetRetainerGuid: '',
  retainerDaysRemaining: null,
  teams: [{ ...fakeKanbanTeam, projectId: 1 }],
  frameworks: [],
};

const fakeKanbanGoalState: GoalState = {
  stateCode: 'ACTIVE',
  name: 'Active',
  ordinal: 1,
  isComplete: false,
};

const generateFakeGoal = (goalId: number, description: string): Goal => ({
  goalId,
  description,
  stateCode: fakeKanbanGoalState.stateCode,
  goalState: fakeKanbanGoalState,
  notes: '',
  goalGroupId: 1,
  goalGroupName: '',
  project: fakeKanbanProject,
  creationDate: toDateStamp(new Date()),
  lastUpdatedDate: toDateStamp(new Date()),
  completionDate: '',
  dueDate: '',
  assignees: [{ ...fakeKanbanUser, goalId: 1, assigneeDone: false }],
  ordinal: 1,
});

const markdownReferenceExamples: Array<string> = [
  '*Italic text*',
  '**Bold text**',
  '***Bold, Italic text***',
  '`Code block`',
  '__Underlined text__',
  '~~Strikethrough text~~',
  '==Highlighted text==',
  '||Spoiler text||',
  '# Primary title',
  '## Secondary title',
  '### Tertiary title',
  'Escaping special characters: \\# \\* \\`',
  'Here is a [Youtube link](https://www.youtube.com/watch?v=iik25wqIuFo)',
  '![A beautiful galaxy](https://i.imgur.com/8ka70Wx.jpeg)',
];

type MarkdownReferenceShortcut = {
  key: string;
  example: string;
};

const markdownReferenceShortcuts: Array<MarkdownReferenceShortcut> = [
  { key: 'B', example: '**Selected text**' },
  { key: 'I', example: '*Selected text*' },
  { key: 'U', example: '__Selected text__' },
  { key: 'S', example: '~~Selected text~~' },
  { key: '`', example: '`Selected text`' },
  { key: 'K', example: '[Selected text]()' },
];

const goalDescriptionExamples: Array<string> = [
  'Fix invoices for Autograph *(not Adamo)* (~~baclup~~ backup on **K drive**)',
  "Rename that `GROUP` field there 👆 to something like *Ticket ID* (maybe ask Jake ***why*** it's called a group)",
  'Add tests for UCAS service ==(and others!?)==\n',
  '# 😎 \n Do some PD today 🎵',
  "Team holidays shouldn't include holidays with status `cancellation request` - how do other services handle these?",
  '||Finish all the JIRA tickets![amnamnam](https://i.pinimg.com/originals/02/2d/14/022d147d25fa83ebd451b5f3700f70ac.gif)||',
  'This is a link to [Google](https://www.google.com)',
  '*Please leave your computer unlocked* \n ![](https://upload.wikimedia.org/wikipedia/commons/2/2c/Nicolas_Cage_cropped_2009.jpg)',
  '***MANUAL PRICE CHANGES NOT UPDATING GIBE***',
  '## Strava API integration\n*(or just any API really)*',
  '==Get a live client portal user for debugging and testing purposes!!! On internal like there internal user==',
  'Look into restoring a single job from backup db - *how do I log into the __backup__ database?*',
  '### "Warburtons availability broke"\n ![](https://media.tenor.com/kVpRSTPxVhQAAAAd/niculau-nicolas-cage-trip.gif)',
];

const HelpPageComponent = ({ history }: HelpProps) => {
  const [helpVisible, setHelpVisible] = useState<boolean>(false);
  const [markdownExampleGoals, setMarkdownExampleGoals] = useState<Array<Goal>>(
    goalDescriptionExamples.map((description, index) => generateFakeGoal(index, description)),
  );

  const handleKeydown = (event: Event) => {
    const keyboardEvent = event as unknown as React.KeyboardEvent;

    if (String(keyboardEvent.target) === '[object HTMLBodyElement]') {
      // Use 'keyCode' instead of 'key' because keyCode is not affected by ctrl and alt keys.
      // List of keyCodes can be found at https://css-tricks.com/snippets/javascript/javascript-keycodes/
      if (keyboardEvent.keyCode === 191) {
        keyboardEvent.preventDefault();
        toggleHelpVisible();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, /* useCapture: */ true);
    return () => {
      document.removeEventListener('keydown', handleKeydown, /* useCapture: */ true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const exampleGoalsById = keyBy(markdownExampleGoals, (goal) => goal.goalId);

  const exampleColumn: Column = {
    goalStateCode: 'EXAMPLE',
    isCompleteState: false,
    title: 'Markdown examples',
    goalIds: markdownExampleGoals.map((goal) => goal.goalId),
  };
  const onGoalDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newMarkdownExampleGoals = markdownExampleGoals.slice();
    const draggedGoal = newMarkdownExampleGoals[result.source.index];
    newMarkdownExampleGoals.splice(result.source.index, 1);
    newMarkdownExampleGoals.splice(result.destination.index, 0, draggedGoal);
    setMarkdownExampleGoals(newMarkdownExampleGoals);
  };

  const toggleHelpVisible = () => setHelpVisible(!helpVisible);

  return (
    <>
      <PageHeader viewName="Help" history={history} />
      {helpVisible && <HelpModal hideHelp={toggleHelpVisible} />}
      <ColumnContainer>
        <ReferenceContainer>
          <CenteredContainer>
            <Logo src={logoUrl} />
            <Title>Keyboard shortcuts</Title>
            <ShortcutList />
            <Title>Markdown reference</Title>
          </CenteredContainer>
          <Table>
            <THead>
              <Tr>
                <Th>Goal description</Th>
                <Th>Appearance</Th>
              </Tr>
            </THead>
            <TBody>
              {markdownReferenceExamples.map((example) => (
                <Tr key={example}>
                  <Td>{example}</Td>
                  <Td>
                    <MarkdownTextBox text={example} />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>
          <p>
            <i>As well as some secret ones not shown here</i> 🤫...
          </p>

          <CenteredContainer>
            <Title>Markdown shortcuts</Title>
          </CenteredContainer>

          <Table>
            <THead>
              <Tr>
                <Th>Shortcut</Th>
                <Th>Goal Description</Th>
                <Th>Appearance</Th>
              </Tr>
            </THead>
            <TBody>
              {markdownReferenceShortcuts.map((shortcut) => (
                <Tr key={shortcut.example}>
                  <Td>
                    <ShortcutKey>Ctrl</ShortcutKey> + <ShortcutKey>{shortcut.key}</ShortcutKey>
                  </Td>
                  <Td>{shortcut.example}</Td>
                  <Td>
                    <MarkdownTextBox text={shortcut.example} />
                  </Td>
                </Tr>
              ))}
            </TBody>
          </Table>

          <CenteredContainer>
            <Title>Confetamine</Title>
            <Quote>
              "Everyone needs something to help them get through the day, some people use caffeine,
              some people use exercise to get dopamine, on support, you need something a little
              stronger. That's why we implemented confetamine. Please use responsibly"
              <br />- Izzie SD, 2023
            </Quote>
            <p>
              During standup, completing goals will normally spawn confetti. To adjust the amount of
              confetti per dragged goal, go to a user goals page and use the settings menu near
              their name. The official term for this is <i>Confetamine</i>.
            </p>
          </CenteredContainer>
        </ReferenceContainer>
        <UsersContext.Provider
          value={{
            users: [fakeKanbanUser],
            activeUsers: [fakeKanbanUser],
            refreshUsers: async () => {},
          }}
        >
          <ProjectsContext.Provider
            value={{
              projects: [fakeKanbanProject],
              activeProjects: [fakeKanbanProject],
              refreshProjects: async () => {},
            }}
          >
            <GoalStatesContext.Provider
              value={{ isGoalStatesLoading: false, goalStates: [fakeKanbanGoalState] }}
            >
              <GoalsContainer>
                <KanbanBoard
                  urlParams={{
                    projectId: fakeKanbanProject.projectId.toString(),
                    userId: fakeKanbanUser.userId.toString(),
                  }}
                  goalsById={exampleGoalsById}
                  columnsById={{
                    [exampleColumn.goalStateCode]: exampleColumn,
                  }}
                  columnOrder={[exampleColumn.goalStateCode]}
                  personalDoneTasksHidden={false}
                  getLatestKanbanData={noop}
                  onKanbanCardDragEnd={onGoalDragEnd}
                  onUpdateAssigneeDone={noop}
                  loading={false}
                  notifyClientsOfGoalUpdate={noop}
                  isViewOnly={true}
                />
              </GoalsContainer>
            </GoalStatesContext.Provider>
          </ProjectsContext.Provider>
        </UsersContext.Provider>
      </ColumnContainer>
    </>
  );
};

export const HelpPage = withRouter(HelpPageComponent);

const Title = styled.h2`
  margin-top: ${medium};
`;

const Quote = styled.blockquote`
  font-style: italic;
  border-left: 2px solid #555;
  padding-left: 10px;
  margin: ${medium} 0;
`;

const ReferenceContainer = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
`;

const GoalsContainer = styled.div`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

const CenteredContainer = styled.div`
  max-width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`;

const ColumnContainer = styled(PageContainer)`
  width: 1000px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  margin: 0 auto;
  gap: ${medium};
`;

const Logo = styled.img`
  height: 200px;
  padding: 50px;
`;
