import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Edit } from '../../../images/icons/edit-solid.svg';
import { Link, LinkProps } from '../../../shared/Link';
import { lessTranslucentWhite, lightGrey, translucentWhite, white } from '../../../styling/colours';
import { largeFont } from '../../../styling/fonts';
import { defaultBorderRadius, mediumNarrow } from '../../../styling/spacing';
import { regularTransitionDuration } from '../../../styling/transitions';
import { KanbanOptionProps, OptionName, OptionNameContainer, StyledIcon } from './KanbanOptions';

type ViewOptionsLinkProps = KanbanOptionProps & {
  to: string;
  isCurrentView?: boolean;
  onClick?: () => void;
};

export const ViewOptionsLink = (props: ViewOptionsLinkProps) => {
  const onEditClick = (mouseEvent: React.MouseEvent<HTMLElement>) => {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();

    if (props.onEditClick) {
      props.onEditClick();
    }
  };

  return (
    <ViewOptionsLinkContainer
      isCurrentView={props.isCurrentView}
      to={props.to}
      disabled={props.disabled}
      onClick={props.onClick}
      className={props.className}
    >
      <OptionNameContainer>
        <StyledIcon
          colour={props.isCurrentView ? white : 'transparent'}
          data-cy={props.isCurrentView ? 'current-page-tick' : undefined}
          onClick={onEditClick}
        >
          <Tick />
        </StyledIcon>
        <OptionName>{props.text}</OptionName>
      </OptionNameContainer>
      {!props.removeEdit && (
        <StyledIcon onClick={onEditClick}>
          <Edit />
        </StyledIcon>
      )}
    </ViewOptionsLinkContainer>
  );
};

type ViewOptionsLinkContainerProps = {
  isCurrentView?: boolean;
} & LinkProps;

const ViewOptionsLinkContainer = styled(
  ({ isCurrentView, ...props }: ViewOptionsLinkContainerProps) => <Link {...props} />,
)<{ isCurrentView?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  color: ${props => (props.disabled ? lessTranslucentWhite : white)};
  padding: ${mediumNarrow};
  font-size: ${largeFont};
  border-radius: ${defaultBorderRadius};

  ${StyledIcon} {
    color: transparent;
    cursor: pointer;
  }

  &:hover {
    color: ${props => (props.disabled ? lessTranslucentWhite : white)};
    background-color: ${translucentWhite};

    ${StyledIcon} {
      color: ${white};

      &:hover {
        color: ${lightGrey};
      }
    }
  }

  transition: all ${regularTransitionDuration} ease;
`;
