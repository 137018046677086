import { SortDirection } from '../../shared/Table';
import { DateStamp } from '../../utils/dateStamp';
import { GoalState } from '../goal-states/goalState';
import { Goal } from '../goals/goal';
import { Project } from '../projects/project';
import { User } from '../users/user';
import { PageSize } from './PageSizeControl';

export type SearchGoalsPageSortByColumn =
  | 'Description'
  | 'Project'
  | 'GoalGroupName'
  | 'StateName'
  | 'Assignees'
  | 'CreationDate'
  | 'CompletionDate';

export const goalAttributeToSearchGoalsPageSortByColumn = (
  attribute: keyof Goal,
): SearchGoalsPageSortByColumn => {
  switch (attribute) {
    case 'description':
      return 'Description';
    case 'project':
      return 'Project';
    case 'goalGroupName':
      return 'GoalGroupName';
    case 'stateCode':
      return 'StateName';
    case 'assignees':
      return 'Assignees';
    case 'creationDate':
      return 'CreationDate';
    case 'completionDate':
      return 'CompletionDate';
    default:
      throw new Error(`Invalid Goal attribute to sort by: ${attribute}`);
  }
};

export const searchGoalsPageSortByColumnToGoalAttribute = (
  sortByColumn: SearchGoalsPageSortByColumn,
): keyof Goal => {
  switch (sortByColumn) {
    case 'Description':
      return 'description';
    case 'Project':
      return 'project';
    case 'GoalGroupName':
      return 'goalGroupName';
    case 'StateName':
      return 'stateCode';
    case 'Assignees':
      return 'assignees';
    case 'CreationDate':
      return 'creationDate';
    case 'CompletionDate':
      return 'completionDate';
    default:
      throw new Error(`Invalid SearchGoalsPageSortByColumn to sort by: ${sortByColumn}`);
  }
};

export type SearchGoalsPagination = {
  pageNumber: number;
  pageSize: PageSize;
  sortByColumn: SearchGoalsPageSortByColumn;
  sortDirection: SortDirection;
};

export type SearchGoalsFilterValuesWithoutShowInactiveProjects = {
  projectId: number | null;
  goalStateCode: string | null;
  assigneeId: number | null;
  showInactiveAssignees: boolean;
  creationFromDate: DateStamp | null;
  creationToDate: DateStamp | null;
  completionFromDate: DateStamp | null;
  completionToDate: DateStamp | null;
  showCompleted: boolean;
};

export type SearchGoalsFilterValues = SearchGoalsFilterValuesWithoutShowInactiveProjects & {
  showInactiveProjects: boolean;
};

export type SearchGoalsPageRequest = SearchGoalsPagination &
  SearchGoalsFilterValuesWithoutShowInactiveProjects;

export const getSearchGoalsFilterValuesFromPageRequest = (
  pageRequest: SearchGoalsPageRequest,
  showInactiveProjects: boolean,
): SearchGoalsFilterValues => {
  const {
    projectId,
    goalStateCode,
    assigneeId,
    showInactiveAssignees,
    creationFromDate,
    creationToDate,
    completionFromDate,
    completionToDate,
    showCompleted,
  } = pageRequest;

  return {
    projectId,
    showInactiveProjects,
    goalStateCode,
    assigneeId,
    showInactiveAssignees,
    creationFromDate,
    creationToDate,
    completionFromDate,
    completionToDate,
    showCompleted,
  };
};

export type SearchGoalsPage = {
  pageRequest: SearchGoalsPageRequest;
  totalPages: number;
  goals: Array<Goal>;
};
