import React, { useContext } from 'react';
import ReactTable, { CellInfo, RowInfo } from 'react-table';
import 'react-table/react-table.css';
import { withModal, WithModalProps } from '../../shared/higher-order-components/withModal';
import { yellow } from '../../styling/colours';
import { formatDateStamp } from '../../utils/dateStamp';
import { Project } from '../projects/project';
import { ProjectsContext } from '../projects/ProjectsContextProvider';
import { CreateFrameworkDto, FrameworkPageProject, FrameworkWithProjects } from './framework';
import { UpdateFrameworkModal } from './UpdateFrameworkModal';

type OwnProps = {
  frameworks: Array<FrameworkWithProjects>;
  onUpdateFramework: (framework: CreateFrameworkDto) => Promise<void>;
  onDeleteFramework: (frameworkId: number) => Promise<void>;
};

type FrameworkTableProps = OwnProps &
  WithModalProps<UpdateFrameworkModalParams, 'updateFrameworkModal'>;

const FrameworkTableComponent = ({
  frameworks,
  onUpdateFramework,
  onDeleteFramework,
  updateFrameworkModal,
}: FrameworkTableProps) => {
  const { activeProjects } = useContext(ProjectsContext);

  const launchEditModal = (framework: FrameworkWithProjects) => {
    updateFrameworkModal.open({
      framework,
      projects: activeProjects,
      onSubmit: onUpdateFramework,
      onDelete: (frameworkId: number | null) => {
        updateFrameworkModal.close();
        if (frameworkId != null) {
          onDeleteFramework(frameworkId);
        }
      },
    });
  };

  const headerProps = () => ({
    style: { background: yellow, fontWeight: 'bold', boxShadow: '0px 0px 0px' },
  });

  const rowProps = (state: {}, rowInfo: RowInfo | undefined) =>
    typeof rowInfo === 'undefined'
      ? {}
      : {
          style: {
            borderBottom: 'solid 1px rgba(0,0,0,0.15)',
            cursor: 'pointer',
          },
          onClick: () => launchEditModal(rowInfo.original),
        };

  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      width: 250,
    },
    {
      Header: 'End of Life Date',
      accessor: 'endOfLifeDate',
      width: 250,
      Cell: (cell: CellInfo) => formatDateStamp(cell.value),
    },
    {
      Header: 'Projects',
      accessor: 'projects',
      Cell: (cell: CellInfo) =>
        (cell.value as Array<FrameworkPageProject>)?.map((p) => p.name).join(', ') || '',
      width: 500,
    },
  ];

  return (
    <ReactTable
      className="-striped -highlight"
      style={{ backgroundColor: 'white' }}
      data={frameworks}
      columns={columns}
      showPagination={false}
      defaultPageSize={200}
      minRows={0}
      getTheadProps={headerProps}
      getTrProps={rowProps}
    />
  );
};

const withUpdateFrameworkModal = withModal<
  OwnProps,
  UpdateFrameworkModalParams,
  'updateFrameworkModal'
>(
  'updateFrameworkModal',
  ({ params, closeModal, closeRequested, cancelCloseRequest }) => (
    <UpdateFrameworkModal
      framework={params.framework}
      projects={params.projects}
      onSubmit={params.onSubmit}
      onDelete={params.onDelete}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

export type UpdateFrameworkModalParams = {
  framework: FrameworkWithProjects;
  projects: Array<Project>;
  onSubmit: (updateFramework: CreateFrameworkDto) => void;
  onDelete: (frameworkId: number | null) => void;
};

export const FrameworkTable = withUpdateFrameworkModal(FrameworkTableComponent);
