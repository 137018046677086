import { Formik } from 'formik';
import React, { createRef, useEffect } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Tick } from '../../../images/icons/check-solid.svg';
import { ReactComponent as Undo } from '../../../images/icons/undo-solid.svg';
import { BorderedIconButton } from '../../../shared/buttons/IconButton';
import { successAccent, warningAccent } from '../../../styling/colours';
import { CreateUpdateTeamForm } from '../CreateUpdateTeamForm';
import { Team, UpdateTeamDto } from '../team';
import {
  toUpdateTeamDto,
  UpdateTeamFormModel,
  UpdateTeamFormValidator,
} from './updateTeamFormModel';

type Props = {
  team: Team;
  onSubmit: (updateTeamDto: UpdateTeamDto) => void;
  closeRequested: boolean;
  cancelCloseRequest: () => void;
  close: () => void;
};

const formValidator = new UpdateTeamFormValidator();

export const UpdateTeamModal = ({
  team,
  onSubmit,
  closeRequested,
  cancelCloseRequest,
  close,
}: Props) => {
  const teamNameInputRef = createRef<HTMLInputElement>();

  useEffect(() => {
    const teamNameInput = teamNameInputRef.current;
    if (teamNameInput !== null) {
      teamNameInput.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let triggerFormSubmission: (() => void) | null = null;

  useEffect(() => {
    if (closeRequested && triggerFormSubmission) {
      triggerFormSubmission();
    }
  }, [closeRequested, triggerFormSubmission]);

  const initialValues = {
    teamId: team.teamId,
    name: team.name,
    colour: team.colour,
    isActive: team.isActive,
  };

  return (
    <Formik<UpdateTeamFormModel>
      initialValues={initialValues}
      validate={formValidator.validate}
      onSubmit={formModel => {
        onSubmit(toUpdateTeamDto(formModel));
        close();
      }}
    >
      {({ isSubmitting, submitForm, errors, handleReset }) => {
        triggerFormSubmission = submitForm;

        if (errors && closeRequested) {
          cancelCloseRequest();
        }

        return (
          <CreateUpdateTeamForm teamNameInputRef={teamNameInputRef} formTitle="Update team">
            {/* Hidden submit button required so form acts like a form on Enter */}
            <HiddenSubmitButton
              type="submit"
              disabled={isSubmitting}
              title="Submit Changes"
              hoverColour={successAccent}
            >
              <Tick />
            </HiddenSubmitButton>
            <BorderedIconButton
              onClick={handleReset}
              disabled={isSubmitting}
              title="Undo Changes"
              hoverColour={warningAccent}
            >
              <Undo />
            </BorderedIconButton>
          </CreateUpdateTeamForm>
        );
      }}
    </Formik>
  );
};

const HiddenSubmitButton = styled(BorderedIconButton)`
  display: none;
`;
