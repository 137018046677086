import React, { useContext } from 'react';
import { useEffect } from 'react';
import * as ReactDOM from 'react-dom';
import styled, { keyframes } from 'styled-components';
import { GlobalStylesContext } from '../GlobalStylesProvider';
import { ReactComponent as CloseIcon } from '../images/icons/times-solid.svg';
import { lightGrey, red, white } from '../styling/colours';
import { largeFont } from '../styling/fonts';
import { getDocumentHtmlNode } from '../styling/GlobalStyles';
import { defaultBorderRadius, medium, narrow, narrower, wider } from '../styling/spacing';
import { regularTransitionDuration } from '../styling/transitions';
import { usePrevious } from '../utils/usePrevious';
import { ButtonGroup } from './buttons/Button';
import { IconButton } from './buttons/IconButton';
import { ErrorBox } from './ErrorBox';

type Props = {
  children?: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  hideCloseButton: boolean;
};

export const modalPortalRootElementId = 'modal-portal-root';

export const Modal = ({ children, isOpen, onClose, hideCloseButton }: Props) => {
  const { setIsModalVisible } = useContext(GlobalStylesContext);

  const handleBackdropClick = (mouseEvent: React.MouseEvent<HTMLElement>) => {
    mouseEvent.preventDefault();
    mouseEvent.stopPropagation();
    onClose();
  };

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown, /* useCapture: */ true);
    return () => {
      document.removeEventListener('keydown', handleKeydown, /* useCapture: */ true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (!prevIsOpen && isOpen) {
      setIsModalVisible(true);
    } else if (prevIsOpen && !isOpen) {
      setIsModalVisible(false);
    }

    return () => setIsModalVisible(false);
  }, [isOpen, setIsModalVisible]);

  if (!isOpen) {
    return null;
  }

  const modalPortalRoot = document.getElementById(modalPortalRootElementId);
  if (modalPortalRoot == null) {
    throw new Error('Could not find modal portal root');
  }

  return ReactDOM.createPortal(
    <ModalBackdrop onMouseDown={handleBackdropClick}>
      <ModalContentContainer
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >
        <ModalContent>
          <FocusAnchor tabIndex={-1} />
          {children}
          {!hideCloseButton && (
            <CloseButton colour={red} onClick={onClose}>
              <CloseIcon />
            </CloseButton>
          )}
        </ModalContent>
      </ModalContentContainer>
    </ModalBackdrop>,
    modalPortalRoot,
  );
};

const backdropFadeIn = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 1;
  }
`;

export const ModalBackdrop = styled.div`
  overflow-y: auto;
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  animation: ${backdropFadeIn} ${regularTransitionDuration} ease forwards;
`;

const ModalContentContainer = styled.div`
  margin: auto;
`;

export const ModalContent = styled.div`
  background-color: ${white};
  padding: ${wider};
  margin: ${narrower};
  position: relative;
  border-radius: ${defaultBorderRadius};
`;

const FocusAnchor = styled.div`
  height: 0;
  width: 0;
`;

const CloseButton = styled(IconButton)`
  position: absolute;
  top: ${narrower};
  right: ${narrower};
  width: 35px;
  height: 35px;
`;

export const ModalTitle = styled.div`
  font-size: ${largeFont};
  margin-bottom: ${medium};
`;

export const ModalErrorBox = styled(ErrorBox)`
  margin-bottom: ${narrow}
  padding: ${medium};
`;

type ModalActionButtonsSectionProps = {
  children: React.ReactNode;
};

export const ModalActionButtonsSection = ({ children }: ModalActionButtonsSectionProps) => (
  <ModalActionButtonsContainer>
    <ModalActionButtonGroup>{children}</ModalActionButtonGroup>
  </ModalActionButtonsContainer>
);

const ModalActionButtonsContainer = styled.div`
  background-color: ${lightGrey};
  padding: ${medium} ${wider};
  margin: 0 -${wider} -${wider} -${wider};
  border-radius: 0 0 ${defaultBorderRadius} ${defaultBorderRadius};
  display: flex;
  justify-content: flex-end;
`;

const ModalActionButtonGroup = styled(ButtonGroup)`
  margin-bottom: 0;
`;
