import { flowRight } from 'lodash';
import React from 'react';
import { ReactComponent as Plus } from '../../../images/icons/plus-solid.svg';
import { withModal, WithModalProps } from '../../../shared/higher-order-components/withModal';
import { lightPurple, translucentWhite } from '../../../styling/colours';
import { homeUrl, viewByProjectUrl } from '../../../urls';
import { EVERYTHING_VIEW_NAME } from '../../main-page/mainPageUrlParamsHelpers';
import { CreateProjectModal } from '../../projects/create-project/CreateProjectModal';
import { CreateProjectDto, Project, UpdateProjectDto } from '../../projects/project';
import { UpdateProjectModal } from '../../projects/update-project/UpdateProjectModal';
import { Team } from '../../teams/team';
import { KanbanViewUrlParams } from '../kanban';
import { TeamGroupContainer, TeamGroupTitle } from './KanbanOptions';
import { OptionsColumn, OptionsColumnHeader, OptionsColumnHeaderButton } from './KanbanSettings';
import { ViewOptionsLink } from './ViewOptionsLink';

type CreateProjectModalParams = {
  onSubmit: (createProjectDto: CreateProjectDto) => void;
};

type CreateProjectModalProps = WithModalProps<CreateProjectModalParams, 'createProjectModal'>;

type UpdateProjectModalParams = {
  project: Project;
  onSubmit: (updateProjectDto: UpdateProjectDto) => void;
};

type UpdateProjectModalProps = WithModalProps<UpdateProjectModalParams, 'updateProjectModal'>;

type OwnProps = {
  urlParams?: KanbanViewUrlParams;
  projects: Array<Project>;
  teams: Array<Team>;
  hideSettings: () => void;
  onCreateProject: (createProjectDto: CreateProjectDto) => void;
  onUpdateProject: (updateProjectDto: UpdateProjectDto) => void;
};

type ProjectViewOptionsProps = CreateProjectModalProps & UpdateProjectModalProps & OwnProps;

const ProjectViewOptionsComponent = ({
  createProjectModal,
  updateProjectModal,
  urlParams,
  projects,
  teams,
  hideSettings,
  onCreateProject,
  onUpdateProject,
}: ProjectViewOptionsProps) => (
  <OptionsColumn>
    <OptionsColumnHeader>
      Projects
      <OptionsColumnHeaderButton
        onClick={() =>
          createProjectModal.open({
            onSubmit: onCreateProject,
          })
        }
        colour={lightPurple}
        hoverColour={translucentWhite}
      >
        <Plus />
      </OptionsColumnHeaderButton>
    </OptionsColumnHeader>
    <ViewOptionsLink
      text={EVERYTHING_VIEW_NAME}
      to={homeUrl()}
      isCurrentView={urlParams && !urlParams.projectId && !urlParams.userId}
      onClick={hideSettings}
      removeEdit={true}
    />
    {teams.map(team => (
      <TeamGroupContainer key={team.teamId}>
        <TeamGroupTitle>{team.name}</TeamGroupTitle>
        {projects
          .filter(project => project.teams.some(projectTeam => projectTeam.teamId === team.teamId))
          .map(project => (
            <ViewOptionsLink
              key={project.projectId}
              text={project.name}
              to={viewByProjectUrl(project.projectId)}
              isCurrentView={urlParams && project.projectId.toString() === urlParams.projectId}
              disabled={!project.isActive}
              onClick={hideSettings}
              onEditClick={() =>
                updateProjectModal.open({
                  project,
                  onSubmit: onUpdateProject,
                })
              }
            />
          ))}
      </TeamGroupContainer>
    ))}
  </OptionsColumn>
);

const withCreateProjectModal = withModal<OwnProps, CreateProjectModalParams, 'createProjectModal'>(
  'createProjectModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <CreateProjectModal
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const withUpdateProjectModal = withModal<
  OwnProps & CreateProjectModalProps,
  UpdateProjectModalParams,
  'updateProjectModal'
>(
  'updateProjectModal',
  ({ params, closeRequested, cancelCloseRequest, closeModal }) => (
    <UpdateProjectModal
      project={params.project}
      onSubmit={params.onSubmit}
      closeRequested={closeRequested}
      cancelCloseRequest={cancelCloseRequest}
      close={closeModal}
    />
  ),
  true,
  true,
);

const enhance = flowRight(
  withCreateProjectModal,
  withUpdateProjectModal,
);

export const ProjectViewOptions = enhance(ProjectViewOptionsComponent);
