import React from 'react';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import styled from 'styled-components/macro';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { defaultSelectStyles } from '../../shared/form/select-fields/selectStyles';

const pageSizes = [5, 10, 25, 50, 100] as const;
export type PageSize = typeof pageSizes[number];

export const defaultPageSize: PageSize = 10;

const mapPageSizeToSelectOption = (pageSize: PageSize): SelectOption<PageSize> => ({
  value: pageSize,
  label: `${pageSize.toString()} items per page`,
});

type PageSizeControlProps = {
  selectedPageSize: PageSize;
  onPageSizeChanged: (pageSize: PageSize) => void;
  disabled: boolean;
  className?: string;
};

export const PageSizeControl = ({
  selectedPageSize,
  onPageSizeChanged,
  disabled,
  className,
}: PageSizeControlProps) => {
  const onChange = (newValue: ValueType<SelectOption<PageSize>>) => {
    const option = newValue as SelectOption<PageSize>;
    onPageSizeChanged(option.value);
  };

  const pageSizeOptions: Array<SelectOption<PageSize>> = pageSizes.map(mapPageSizeToSelectOption);

  return (
    <SelectContainer className={className}>
      <Select<SelectOption<PageSize>>
        name="pageSize"
        onChange={onChange}
        value={mapPageSizeToSelectOption(selectedPageSize)}
        isDisabled={disabled}
        options={pageSizeOptions}
        styles={defaultSelectStyles}
        menuPlacement="auto"
      />
    </SelectContainer>
  );
};

const SelectContainer = styled.div`
  width: 220px;
`;
