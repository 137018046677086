import React, { createRef, useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as Ellipsis } from '../../images/icons/ellipsis-v-solid.svg';
import { greyBorder, white } from '../../styling/colours';
import { narrow, smallBorderRadius } from '../../styling/spacing';
import { BorderedIconButton, IconButton } from './IconButton';

type Props = {
  className?: string;
  menuDirection: 'row' | 'column';
  iconColour: string;
  iconContrastColour: string;
  backgroundHoverColour: string;
  children: (closeContextMenu: () => void) => React.ReactNode;
  openToTheLeft?: boolean;
};

export const ContextMenuButton = (props: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const containerRef = createRef<HTMLDivElement>();

  const handleClick = (event: MouseEvent) => {
    if (containerRef.current && containerRef.current.contains(event.target as Node)) {
      return;
    }
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <Container ref={containerRef} className={props.className} onClick={e => e.stopPropagation()}>
      <ToggleButton
        onClick={() => setIsOpen(!isOpen)}
        colour={isOpen ? props.iconContrastColour : props.iconColour}
        hoverColour={props.backgroundHoverColour}
        menuIsOpen={isOpen}
        openBackgroundColour={props.iconColour}
      >
        <Ellipsis />
      </ToggleButton>
      {isOpen && (
        <ContextMenuItemsContainer
          menuDirection={props.menuDirection}
          openToTheLeft={props.openToTheLeft}
        >
          {props.children(() => setIsOpen(false))}
        </ContextMenuItemsContainer>
      )}
    </Container>
  );
};

export const defaultContextMenuButtonItemHeight = '30px';

export const ContextMenuItemButton = styled(BorderedIconButton)`
  width: ${defaultContextMenuButtonItemHeight};
  height: ${defaultContextMenuButtonItemHeight};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const toggleButtonHeight = '25px';

// eslint-disable-next-line no-unexpected-multiline
const ToggleButton = styled(({ menuIsOpen, openBackgroundColour, ...props }) => (
  <IconButton {...props} />
))<{
  menuIsOpen: boolean;
  openBackgroundColour: string;
}>`
  height: ${toggleButtonHeight};
  width: ${toggleButtonHeight};

  background-color: ${props => (props.menuIsOpen ? props.openBackgroundColour : 'transparent')};

  &:hover {
    background-color: ${props =>
      props.menuIsOpen ? props.openBackgroundColour : props.hoverColour};
  }

  border-radius: ${props =>
    props.menuIsOpen ? `${smallBorderRadius} ${smallBorderRadius} 0 0` : `${smallBorderRadius}`};
`;

const ContextMenuItemsContainer = styled.div<{
  menuDirection: 'row' | 'column';
  openToTheLeft?: boolean;
}>`
  display: flex;
  flex-direction: ${props => props.menuDirection};

  > * {
    margin-right: ${props => (props.menuDirection === 'row' ? narrow : 0)};
    margin-bottom: ${props => (props.menuDirection === 'column' ? narrow : 0)};

    &:last-of-type {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  z-index: 5;
  position: absolute;
  top: ${toggleButtonHeight};
  ${props => (props.openToTheLeft ? { right: 0 } : { left: 0 })}
  padding: ${narrow};
  margin-bottom: 0;

  background-color: ${white};
  border: 1px solid ${greyBorder};
  border-top-left-radius: ${props => (props.openToTheLeft ? smallBorderRadius : 0)};
  border-top-right-radius: ${props => (props.openToTheLeft ? 0 : smallBorderRadius)};
  border-bottom-left-radius: ${smallBorderRadius};
  border-bottom-right-radius: ${smallBorderRadius};
`;
