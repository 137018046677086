import React from 'react';
import styled from 'styled-components/macro';
import { white } from '../../../styling/colours';
import { regularFont } from '../../../styling/fonts';
import { narrow, narrower, navMenuHeightPixels, standupMenuWidth } from '../../../styling/spacing';

type CheckboxProps = {
  name: string;
  value?: number;
  label: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const StandupOption = ({
  name,
  value,
  checked,
  disabled,
  onChange,
  label,
}: CheckboxProps) => (
  <StandupOptionContainer>
    <StandupOptionLabel>{label}</StandupOptionLabel>
    <StandupOptionCheckbox
      name={name}
      type="checkbox"
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={onChange}
    />
  </StandupOptionContainer>
);

const StandupOptionContainer = styled.div`
  background-color: ${white};
  font-size: ${regularFont};
  width: ${standupMenuWidth};
  min-height: ${navMenuHeightPixels / 2}px;
  padding: ${narrower};
  outline: none;
  display: flex;
  align-items: center;
`;

const StandupOptionLabel = styled.label`
  width: 90%;
  padding-right: ${narrow};
`;

const StandupOptionCheckbox = styled.input`
  width: 10%;
  float: right;
`;
