import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { formatDateStamp } from '../../utils/dateStamp';
import { Framework } from '../frameworks/framework';
import { ProjectFramework, toProjectFramework } from './projectFrameworks';

export const mapProjectFrameworksToSelectOptions = (
  projectFrameworks: Array<ProjectFramework>,
): Array<SelectOption<ProjectFramework>> =>
  projectFrameworks.map((projectFramework) => ({
    value: projectFramework,
    label: projectFramework.name,
  }));
