import { Validator } from 'fluentvalidation-ts';
import { DateStamp } from '../../utils/dateStamp';
import { CreateFrameworkDto, FrameworkPageProject } from './framework';

export type CreateFrameworkFormModel = {
  name: string;
  endOfLifeDate: DateStamp;
  projects: Array<FrameworkPageProject> | null;
};

export class CreateFrameworkFormValidator extends Validator<CreateFrameworkFormModel> {
  constructor() {
    super();

    this.ruleFor('name').notEmpty().withMessage('Please add a name').maxLength(128);

    this.ruleFor('endOfLifeDate').notEmpty().withMessage('Please choose an end of life date');
  }
}

export const toCreateFrameworkDto = (formModel: CreateFrameworkFormModel): CreateFrameworkDto => {
  return {
    name: formModel.name,
    endOfLifeDate: formModel.endOfLifeDate,
    projectIds: formModel.projects?.map((project) => project.projectId) || [],
  };
};
