import { Framework } from '../frameworks/framework';

export type ProjectFramework = Framework & {
  projectId: number | null;
};

export type UpdateProjectFrameworkDto = {
  projectId: number | null;
  frameworkId: number;
};

export type CreateProjectFrameworkDto = {
  projectId: number | null;
  frameworkId: number;
};

export const toCreateProjectFrameworkDto = (
  frameworks: Array<ProjectFramework>,
): Array<CreateProjectFrameworkDto> =>
  frameworks.map((framework) => ({
    projectId: framework.projectId,
    frameworkId: framework.frameworkId,
  }));

export const toUpdateProjectFrameworkDto = (
  frameworks: Array<ProjectFramework>,
): Array<UpdateProjectFrameworkDto> =>
  frameworks.map((framework) => ({
    projectId: framework.projectId,
    frameworkId: framework.frameworkId,
  }));

export const toProjectFramework = (
  framework: Framework,
  projectId: number | null,
): ProjectFramework => ({
  ...framework,
  projectId,
});
