import { Validator } from 'fluentvalidation-ts';
import { colourHexRegEx, getContrastedColour } from '../../../styling/colours';
import {
  ProjectFramework,
  toUpdateProjectFrameworkDto,
} from '../../project-frameworks/projectFrameworks';
import {
  StatusEmailReceiver,
  toUpdateProjectStatusEmailReceiverDto,
} from '../../project-status-email-receivers/projectStatusEmailReceiver';
import { ProjectTeam, toUpdateProjectTeamDto } from '../../project-teams/projectTeam';
import { UpdateProjectDto } from '../project';

export type UpdateProjectFormModel = {
  projectId: number;
  name: string;
  colour: string;
  externalTicketUrlTemplate: string;
  jiraKeys: string;
  isActive: boolean;
  teamMailingListEmail: string;
  additionalStatusEmailReceivers: Array<StatusEmailReceiver>;
  spreadsheetRetainerGuid: string;
  teams: Array<ProjectTeam>;
  frameworks: Array<ProjectFramework>;
};

export class UpdateProjectFormValidator extends Validator<UpdateProjectFormModel> {
  constructor() {
    super();

    this.ruleFor('name').notEmpty().maxLength(32);

    this.ruleFor('colour')
      .notEmpty()
      .matches(colourHexRegEx(true))
      .withMessage('Must be a 3 or 6 digit hex value');

    this.ruleFor('externalTicketUrlTemplate').maxLength(256);

    this.ruleFor('jiraKeys').maxLength(32);

    this.ruleFor('teamMailingListEmail').maxLength(128);

    this.ruleFor('spreadsheetRetainerGuid').maxLength(128);

    this.ruleFor('teams')
      .must((projectTeams) => projectTeams.length > 0)
      .withMessage('Project must be assigned to at least one team');
  }
}

export const toUpdateProjectDto = (formModel: UpdateProjectFormModel): UpdateProjectDto => ({
  projectId: formModel.projectId,
  name: formModel.name,
  colour: formModel.colour.toUpperCase(),
  contrastColour: getContrastedColour(formModel.colour),
  externalTicketUrlTemplate: formModel.externalTicketUrlTemplate,
  jiraKeys: formModel.jiraKeys,
  isActive: formModel.isActive,
  teamMailingListEmail: formModel.teamMailingListEmail || null,
  additionalStatusEmailReceivers: toUpdateProjectStatusEmailReceiverDto(
    formModel.additionalStatusEmailReceivers,
  ),
  spreadsheetRetainerGuid: formModel.spreadsheetRetainerGuid,
  teams: toUpdateProjectTeamDto(formModel.teams),
  frameworks: toUpdateProjectFrameworkDto(formModel.frameworks),
});
