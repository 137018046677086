import React, { ChangeEvent, useContext, useState } from 'react';
import styled from 'styled-components/macro';
import InlineSlider from '../../../shared/form/InlineSlider';
import { narrow } from '../../../styling/spacing';
import { ConfettiContext, MAXIMUM_CONFETTI_FACTOR } from './ConfettiContext';

export const ConfettiSlider = styled(InlineSlider)`
  min-width: 400px;
`;

const MAXIMUM_CONFETTI_PER_GOAL = 1000;
const SLIDER_STEPS = 200;
const ORANGE_COLOUR_THRESHOLD = 250;
const RED_COLOUR_THRESHOLD = 625;

const ConfettiSettings = () => {
  const {
    getCurrentNumberOfConfetti,
    getConfettiPerGoal,
    setConfettiPerGoalAndRemoveExcess,
  } = useContext(ConfettiContext);
  const [sliderValue, setSliderValue] = useState(() => confettiToSliderValue(getConfettiPerGoal()));

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSliderValue(event.target.valueAsNumber);
    setConfettiPerGoalAndRemoveExcess(sliderValueToConfetti(event.target.valueAsNumber));
  };

  return (
    <>
      <Label>
        Confetti per goal / current confetti:{' '}
        <b>
          x{getConfettiPerGoal()} /{' '}
          {Math.min(getCurrentNumberOfConfetti(), getConfettiPerGoal() * MAXIMUM_CONFETTI_FACTOR)}
        </b>
      </Label>
      <ConfettiSlider value={sliderValue} onChange={onChange} max={SLIDER_STEPS} />
      <ConfettiPhraseContainer>
        {getColouredConfettiPhrase(getConfettiPerGoal())}
      </ConfettiPhraseContainer>
    </>
  );
};

const getConfettiPhrase = (confetti: number) => {
  if (confetti <= 0) {
    return 'No confetti 🙁';
  } else if (confetti <= 15) {
    return 'Runs on potato 🥔';
  } else if (confetti <= 40) {
    return 'Lightning fast ⚡';
  } else if (confetti <= 75) {
    return 'Performance-conscious 🧠';
  } else if (confetti <= 125) {
    return 'The gold standard 🥇';
  } else if (confetti <= 250) {
    return 'A little more on top, please 🎩';
  } else if (confetti <= 350) {
    return 'Woah, easy on the confetamine 💊';
  } else if (confetti <= 550) {
    return 'You might want to turn it down a notch 🤯';
  } else if (confetti <= 725) {
    return 'Who even needs frames? 🎞️🚫';
  } else if (confetti <= 925) {
    return 'Benchmark your computer 🖥️🔥';
  } else {
    return 'CAN IT RUN TAPAS? 😲';
  }
};

const getColouredConfettiPhrase = (confetti: number) => {
  if (confetti <= ORANGE_COLOUR_THRESHOLD) {
    return getConfettiPhrase(confetti);
  } else if (confetti <= RED_COLOUR_THRESHOLD) {
    return (
      <MixedColourText
        colorStart="#464646"
        colorEnd="#ff6500"
        percentage={Math.max(
          Math.min(
            Math.round(
              ((confetti - ORANGE_COLOUR_THRESHOLD) /
                (RED_COLOUR_THRESHOLD - ORANGE_COLOUR_THRESHOLD)) *
                100,
            ),
            100,
          ),
          0,
        )}
      >
        {getConfettiPhrase(confetti)}
      </MixedColourText>
    );
  } else {
    return (
      <MixedColourText
        colorStart="#ff6500"
        colorEnd="#ff0000"
        percentage={Math.max(
          Math.min(
            Math.round(((confetti - RED_COLOUR_THRESHOLD) / (1000 - RED_COLOUR_THRESHOLD)) * 100),
            100,
          ),
          0,
        )}
      >
        {getConfettiPhrase(confetti)}
      </MixedColourText>
    );
  }
};

const sliderValueToConfetti = (value: number) =>
  Math.round(
    (Math.min(value * 1.01, SLIDER_STEPS) ** 2 * MAXIMUM_CONFETTI_PER_GOAL) / SLIDER_STEPS ** 2 / 5,
  ) * 5;

const confettiToSliderValue = (confetti: number) =>
  Math.sqrt((confetti / MAXIMUM_CONFETTI_PER_GOAL) * SLIDER_STEPS ** 2);

const MixedColourText = styled.span<{ colorStart: string; colorEnd: string; percentage: number }>`
  color: color-mix(
    in srgb,
    ${({ colorEnd }) => colorEnd} ${({ percentage }) => percentage}%,
    ${({ colorStart }) => colorStart}
  );
`;

const Label = styled.label`
  font-size: 1.2rem;
  margin: 0 ${narrow};
`;

const ConfettiPhraseContainer = styled(Label)`
  margin-bottom: ${narrow} !important;
`;

export default ConfettiSettings;
