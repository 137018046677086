import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useGoalStateApi } from '../../hooks/api/useGoalStateApi';
import { GoalState } from './goalState';

type GoalStatesContextValue = {
  isGoalStatesLoading: boolean;
  goalStates: Array<GoalState>;
};

export const GoalStatesContext = React.createContext<GoalStatesContextValue>({
  isGoalStatesLoading: true,
  goalStates: [],
});

export const GoalStatesContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isGoalStatesLoading, setIsGoalStatesLoading] = useState<boolean>(true);
  const [goalStates, setGoalStates] = useState<Array<GoalState>>([]);
  const { getGoalStates } = useGoalStateApi();

  useEffect(() => {
    getGoalStates().then((response) => {
      setGoalStates(response);
      setIsGoalStatesLoading(false);
    });
  }, []);

  return (
    <GoalStatesContext.Provider value={{ isGoalStatesLoading, goalStates }}>
      {children}
    </GoalStatesContext.Provider>
  );
};
