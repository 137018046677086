import { Form } from 'formik';
import React, { useMemo } from 'react';
import styled from 'styled-components/macro';
import { ColourPickerField } from '../../shared/form/ColourPickerField';
import { InputField } from '../../shared/form/input-fields/InputField';
import { MultiSelectField } from '../../shared/form/select-fields/MultiSelectField';
import { ToggleField } from '../../shared/form/ToggleField';
import { ModalActionButtonsSection, ModalTitle } from '../../shared/Modal';
import { Framework } from '../frameworks/framework';
import { mapFrameworksToProjectFrameworksSelectOptions } from '../frameworks/frameworkSelectUtils';
import { ProjectFramework } from '../project-frameworks/projectFrameworks';
import { mapProjectFrameworksToSelectOptions } from '../project-frameworks/projectFrameworkSelectUtils';
import { StatusEmailReceiver } from '../project-status-email-receivers/projectStatusEmailReceiver';
import {
  mapStatusEmailReceiversToSelectOptions,
  mapUsersToStatusEmailReceiverSelectOptions,
} from '../project-status-email-receivers/statusEmailReceiverUtils';
import { ProjectTeam } from '../project-teams/projectTeam';
import {
  mapProjectTeamsToSelectOptions,
  mapTeamsToProjectTeamSelectOptions,
} from '../project-teams/projectTeamSelectUtils';
import { Team } from '../teams/team';
import { teamMultiSelectStyles } from '../teams/teamSelectUtils';
import { User } from '../users/user';
import { userSelectStyles } from '../users/userSelectUtils';

type CreateUpdateProjectFormProps = {
  projectId?: number;
  activeUsers: Array<User>;
  activeTeams: Array<Team>;
  frameworks: Array<Framework>;
  nameInputRef: React.RefObject<HTMLInputElement>;
  formTitle: string;
  children?: React.ReactNode;
};

export const CreateUpdateProjectForm = ({
  projectId,
  activeUsers,
  activeTeams,
  frameworks,
  nameInputRef,
  formTitle,
  children,
}: CreateUpdateProjectFormProps) => {
  const projectTeamOptions = useMemo(
    () => mapTeamsToProjectTeamSelectOptions(activeTeams, projectId || null),
    [activeTeams, projectId],
  );

  const statusEmailReceiversOptions = useMemo(
    () => mapUsersToStatusEmailReceiverSelectOptions(activeUsers, projectId || null),
    [activeUsers, projectId],
  );

  const projectFrameworkOptions = useMemo(
    () => mapFrameworksToProjectFrameworksSelectOptions(frameworks, projectId || null),
    [activeTeams, projectId],
  );

  return (
    <Form>
      <ModalTitle>{formTitle}</ModalTitle>
      <InputSection>
        <InputField
          name="name"
          label="Project Name"
          placeholder="Choose a project name..."
          maxLength={32}
          ref={nameInputRef}
        />
        <InputField
          name="externalTicketUrlTemplate"
          label="JIRA/DevOps URL"
          optional={true}
          placeholder="Add a JIRA/DevOps URL..."
          maxLength={256}
          spellcheck={false}
          tooltip={{ content: 'Please enter a URL with {} where the ticket number should be.' }}
        />
        <InputField
          name="jiraKeys"
          label="JIRA Key"
          optional={true}
          placeholder="Add JIRA key(s)..."
          maxLength={32}
          spellcheck={false}
          tooltip={{ content: "Enter a comma seperated list of Jira Keys e.g 'WSUP, WSCA'" }}
        />
        <InputField
          name="teamMailingListEmail"
          label="Team Mailing List"
          optional={true}
          placeholder="Add a team mailing list email..."
          maxLength={128}
          spellcheck={false}
        />
        <MultiSelectField<ProjectTeam>
          name="teams"
          label="Teams"
          placeholder="Select teams..."
          options={projectTeamOptions}
          mapValueToSelectOptions={mapProjectTeamsToSelectOptions}
          mapOptionToKey={(option) => option.value.name}
          styles={teamMultiSelectStyles}
        />
        <MultiSelectField<StatusEmailReceiver>
          name="additionalStatusEmailReceivers"
          label="Additional Status Email Receivers"
          optional={true}
          placeholder="Select status email receivers..."
          options={statusEmailReceiversOptions}
          mapValueToSelectOptions={mapStatusEmailReceiversToSelectOptions}
          mapOptionToKey={(option) => option.value.userId.toString()}
          styles={userSelectStyles}
        />
        <MultiSelectField<ProjectFramework>
          name="frameworks"
          label={'Frameworks'}
          optional={true}
          placeholder={'Select frameworks...'}
          options={projectFrameworkOptions}
          mapValueToSelectOptions={mapProjectFrameworksToSelectOptions}
          mapOptionToKey={(option) => option.value.frameworkId.toString()}
          styles={userSelectStyles}
        />
        <InputField
          name="spreadsheetRetainerGuid"
          label="Spreadsheet Retainer GUID"
          optional={true}
          placeholder="Add the GUID from the resourcing spreadsheet..."
        />
        <ColourPickerField name="colour" label="Project Colour" />
        <ToggleField name="isActive" label="Active?" />
      </InputSection>
      {children && <ModalActionButtonsSection>{children}</ModalActionButtonsSection>}
    </Form>
  );
};

const InputSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;
