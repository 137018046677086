import React from 'react';
import styled from 'styled-components/macro';
import { darkRed, red } from '../styling/colours';

export type ExternalLinkProps = {
  to: string;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
};

export const ExternalLink = ({ to, className, onClick, children }: ExternalLinkProps) => {
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.stopPropagation();

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <ExternalLinkContainer className={className} href={to} target="_blank" onClick={handleClick}>
      {children}
    </ExternalLinkContainer>
  );
};

const ExternalLinkContainer = styled.a`
  color: ${red};
  text-decoration: none;

  &:hover {
    color: ${darkRed};
  }
`;
