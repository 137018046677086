import { cloneDeep, findIndex } from 'lodash';
import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useUsersApi } from '../../hooks/api/useUsersApi';
import { UpdateUserHideDoneTasksDto, User } from './user';

type UsersContextValue = {
  users: Array<User>;
  activeUsers: Array<User>;
  refreshUsers: () => Promise<void>;
};

export const UsersContext = React.createContext<UsersContextValue>({
  users: [],
  activeUsers: [],
  refreshUsers: () => {
    throw new Error('Cannot refresh users outside of its context provider');
  },
});

export const UsersContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [users, setUsers] = useState<Array<User>>([]);
  const [activeUsers, setActiveUsers] = useState<Array<User>>([]);

  const { fetchUsers } = useUsersApi();

  useEffect(() => {
    refreshUsers();
  }, []);

  useEffect(() => {
    setActiveUsers(users.filter((user) => user.isActive));
  }, [users]);

  const refreshUsers = async () => {
    setUsers(await fetchUsers());
  };

  return (
    <UsersContext.Provider value={{ users, activeUsers, refreshUsers }}>
      {children}
    </UsersContext.Provider>
  );
};
