import { Bodies, Body, Engine, Render, Runner, World } from 'matter-js';
import { createContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import styled from 'styled-components/macro';
import { shadeColour, tintColour } from '../../../styling/colours';

const DEFAULT_CONFETTI_PER_GOAL = 100;
export const MAXIMUM_CONFETTI_FACTOR = 20; // 20x the number of confetti per goal

export type ConfettiContextValue = {
  getLastChangedGoalId: () => number;
  setLastChangedGoalId: (id: number) => void;
  setConfettiEnabled: (enabled: boolean) => void;
  createConfetti: (x: number, y: number, colours: Array<string>) => void;
  getCurrentNumberOfConfetti: () => number;
  getConfettiPerGoal: () => number;
  setConfettiPerGoalAndRemoveExcess: (newConfettiPerGoal: number) => void;
};

const throwNotImplemented = () => {
  throw new Error('Not implemented');
};

const loadConfettiPerGoal = () => {
  const savedConfettiPerGoal = localStorage.getItem('confettiPerGoal');

  return savedConfettiPerGoal ? parseInt(savedConfettiPerGoal, 10) : DEFAULT_CONFETTI_PER_GOAL;
};

export const ConfettiContext = createContext<ConfettiContextValue>({
  getLastChangedGoalId: () => 0,
  setLastChangedGoalId: throwNotImplemented,
  setConfettiEnabled: throwNotImplemented,
  createConfetti: throwNotImplemented,
  getCurrentNumberOfConfetti: throwNotImplemented,
  getConfettiPerGoal: throwNotImplemented,
  setConfettiPerGoalAndRemoveExcess: throwNotImplemented,
});

type Props = {
  children?: React.ReactNode;
};

export const ConfettiProvider = (props: Props) => {
  const scene = useRef<HTMLInputElement>(null);
  const engine = useRef(Engine.create());
  const [currentConfetti, setCurrentConfetti] = useState<Array<Body>>([]);
  const [lastChangedGoalId, setLastChangedGoalId] = useState(0);
  const [confettiEnabled, setConfettiEnabled] = useState(false);
  const [confettiPerGoal, setConfettiPerGoal] = useState(loadConfettiPerGoal());

  useEffect(() => {
    if (!scene.current) {
      return;
    }

    const cw = document.body.clientWidth;
    const ch = document.body.clientHeight;

    const render = Render.create({
      element: scene.current,
      engine: engine.current,
      options: {
        width: cw,
        height: ch,
        wireframes: false,
        background: 'transparent',
      },
    });

    World.add(engine.current.world, [
      Bodies.rectangle(cw / 2, -10, cw, 20, { isStatic: true, render: { fillStyle: '#000000' } }),
      Bodies.rectangle(-10, ch / 2, 20, ch, { isStatic: true, render: { fillStyle: '#000000' } }),
      Bodies.rectangle(cw / 2, ch + 10, cw, 20, {
        isStatic: true,
        render: { fillStyle: '#000000' },
      }),
      Bodies.rectangle(cw + 10, ch / 2, 20, ch, {
        isStatic: true,
        render: { fillStyle: '#000000' },
      }),
    ]);

    Runner.run(engine.current);
    Render.run(render);
  }, []);

  useEffect(() => {
    if (!confettiEnabled) {
      for (const item of currentConfetti) {
        World.remove(engine.current.world, item);
      }
      setCurrentConfetti([]);
    }
  }, [confettiEnabled]);

  const getLastChangedGoalId = () => lastChangedGoalId;
  const getCurrentNumberOfConfetti = () => currentConfetti.length;
  const getConfettiPerGoal = () => confettiPerGoal;

  const generateConfettiColour = (colour: string) => {
    const x = Math.floor(Math.random() * 50) - 25;
    if (x > 0) {
      return shadeColour(colour, Math.abs(x));
    } else if (x < 0) {
      return tintColour(colour, Math.abs(x));
    } else {
      return colour;
    }
  };

  const removeExcessConfetti = (allConfetti: Array<Body>) => {
    while (allConfetti.length > confettiPerGoal * MAXIMUM_CONFETTI_FACTOR) {
      World.remove(engine.current.world, allConfetti[0]);
      allConfetti.shift();
    }

    setCurrentConfetti(allConfetti);
  };

  const createConfetti = (x: number, y: number, colours: Array<string>) => {
    if (confettiEnabled) {
      const newConfetti: Array<Body> = [];
      for (let i = 0; i < confettiPerGoal; i++) {
        newConfetti.push(
          Bodies.rectangle(x + Math.random() * 60, y + Math.random() * 30, 10, 10, {
            mass: 0.0001,
            density: 0.0001,
            friction: 0.01,
            frictionAir: 0.01,
            angle: Math.random() * Math.PI * 2,
            angularVelocity: Math.random() * 0.5,
            render: {
              fillStyle: generateConfettiColour(
                colours.length === 0 ? '#aaaaaa' : colours[i % colours.length],
              ),
            },
          }),
        );
        const direction = Math.random() * Math.PI + Math.PI / 2;
        Body.setVelocity(newConfetti[i], {
          x: Math.sin(direction) * 12,
          y: Math.abs(Math.cos(direction) * 24) * -1,
        });
      }

      // Spawn confetti in batches of 10 with a 1ms offset to reduce lag spikes
      for (let i = 0; i < newConfetti.length / 10; i++) {
        setTimeout(() => {
          for (let j = 0; j < Math.min(10, newConfetti.length - i * 10); j++) {
            World.add(engine.current.world, newConfetti[i * 10 + j]);
          }
        }, i * 1);
      }

      const allConfetti = [...currentConfetti, ...newConfetti];

      removeExcessConfetti(allConfetti);
    }
  };

  const setConfettiPerGoalAndRemoveExcess = (newConfettiPerGoal: number) => {
    setConfettiPerGoal(newConfettiPerGoal);
    localStorage.setItem('confettiPerGoal', newConfettiPerGoal.toString());
    removeExcessConfetti(currentConfetti);
  };

  return (
    <ConfettiContext.Provider
      value={{
        getLastChangedGoalId,
        setLastChangedGoalId,
        setConfettiEnabled,
        createConfetti,
        getCurrentNumberOfConfetti,
        getConfettiPerGoal,
        setConfettiPerGoalAndRemoveExcess,
      }}
    >
      <Container>
        <div
          ref={scene}
          style={{
            width: '100%',
            height: '100vh',
            zIndex: 1,
            position: 'fixed',
            left: 0,
            top: 0,
            pointerEvents: 'none',
          }}
        />
        {props.children}
      </Container>
    </ConfettiContext.Provider>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
