import { ApiRequest, fetchJson, from, withJsonBody } from '../../utils/api';
import { DateStamp, getWeekdayNameFromValue, WeekdayOption } from '../../utils/dateStamp';
import { GoalState } from '../goal-states/goalState';
import { Project } from '../projects/project';
import { User } from '../users/user';

export type RecurringGoal = {
  recurringGoalId: number;
  description: string;
  notes: string;
  startDate: DateStamp;
  daysFrequency: number;
  monthsFrequency: number;
  dayOfWeek: WeekdayOption;
  nextOccurrence: DateStamp | null;
  stateCode: string;
  goalGroupName: string;
  runsOnLastWeekdayOfMonth: boolean;
  runOnWeekends: boolean;
  project: Project;
  assignees: Array<User>;
};

export type RecurringGoalData = {
  recurringGoals: Array<RecurringGoal>;
};

export type UpdateRecurringGoalDto = {
  recurringGoalId: number;
  description: string;
  notes: string;
  startDate: DateStamp;
  daysFrequency: number;
  monthsFrequency: number;
  dayOfWeek: number | null;
  nextOccurrence: DateStamp | null;
  stateCode: string;
  goalGroupName: string;
  runsOnLastWeekdayOfMonth: boolean;
  runOnWeekends: boolean;
  projectId: number;
  assigneeIds: Array<number>;
};

export type CreateRecurringGoalDto = {
  description: string;
  notes: string;
  startDate: DateStamp;
  daysFrequency: number;
  monthsFrequency: number;
  dayOfWeek: number | null;
  nextOccurrence: DateStamp | null;
  stateCode: string;
  goalGroupName: string;
  runsOnLastWeekdayOfMonth: boolean;
  runOnWeekends: boolean;
  projectId: number;
  assigneeIds: Array<number>;
};

export const frequencyString = (recurringGoal: RecurringGoal) => {
  if (recurringGoal.runsOnLastWeekdayOfMonth) {
    return `Last ${getWeekdayNameFromValue(recurringGoal.dayOfWeek)} of every month`;
  }

  if (recurringGoal.daysFrequency === 0) {
    return recurringGoal.monthsFrequency === 1
      ? `Every month`
      : `Every ${recurringGoal.monthsFrequency} months`;
  }

  if (recurringGoal.monthsFrequency === 0) {
    if (recurringGoal.daysFrequency % 7 === 0) {
      return recurringGoal.daysFrequency === 7
        ? `Every week`
        : `Every ${recurringGoal.daysFrequency / 7} weeks`;
    }
    return recurringGoal.daysFrequency === 1
      ? `Every day`
      : `Every ${recurringGoal.daysFrequency} days`;
  }
  return `Every ${recurringGoal.monthsFrequency} months and ${recurringGoal.daysFrequency} days`;
};
