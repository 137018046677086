import React from 'react';
import { Styles } from 'react-select/lib/styles';
import { SelectOption } from '../../shared/form/select-fields/SelectField';
import { selectControlStyles } from '../../shared/form/select-fields/selectStyles';
import { black, fontGrey, lightGrey, white } from '../../styling/colours';
import { Project } from './project';

export const mapProjectsToSelectOptionsWithNull = (
  projects: Array<Project>,
  nullLabel: string = 'All',
): Array<SelectOption<Project | null>> => {
  const nullOption: SelectOption<Project | null> = { value: null, label: nullLabel };
  const projectOptions = mapProjectsToSelectOptions(projects);

  return [nullOption, ...projectOptions];
};

export const mapProjectsToSelectOptions = (
  projects: Array<Project>,
): Array<SelectOption<Project>> => projects?.map(mapProjectToSelectOption) || [];

export const mapProjectToSelectOption = (project: Project): SelectOption<Project> => ({
  value: project,
  label: project.name,
});

type ProjectSelectStylesOptionsState = {
  data: SelectOption<Project>;
  isFocused: boolean;
};

export const projectSelectStyles: Styles = {
  control: (styles: React.CSSProperties, { isFocused }) => ({
    ...selectControlStyles(styles, isFocused),
  }),
  option: (
    styles: React.CSSProperties,
    { data, isFocused }: ProjectSelectStylesOptionsState,
  ): React.CSSProperties => ({
    ...styles,
    backgroundColor: isFocused ? (!!data.value ? data.value.colour : lightGrey) : white,
    color: isFocused ? (!!data.value ? data.value.contrastColour : black) : fontGrey,
  }),
};
