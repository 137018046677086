import * as React from 'react';
import { PropsWithChildren, useEffect, useState } from 'react';
import { ApiRequest, fetchJson, from, withJsonBody } from '../../utils/api';
import { Team } from '../teams/team';

export type User = {
  userId: number;
  chosenName: string;
  familyName: string;
  email: string;
  colour: string;
  contrastColour: string;
  isActive: boolean;
  isHidingDoneTasks: boolean;
  team: Team;
};

export type CreateUserDto = {
  chosenName: string;
  familyName: string;
  email: string;
  colour: string;
  contrastColour: string;
  isActive: boolean;
  teamId: number;
};

export type UpdateUserDto = {
  userId: number;
  chosenName: string;
  familyName: string;
  email: string;
  colour: string;
  contrastColour: string;
  isActive: boolean;
  teamId: number;
};

export type UpdateUserHideDoneTasksDto = {
  userId: number;
  isHidingDoneTasks: boolean;
};

export const getUserFullName = (user: User): string =>
  `${user.chosenName}${user.familyName && user.familyName.length ? ` ${user.familyName}` : ''}`;
